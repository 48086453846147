@import url("https://fonts.googleapis.com/css2?family=Advent+Pro&display=swap");
@font-face {
  font-family: Roboto-BlackItalic;
  src: url(../content/fonts/Roboto/Roboto-BlackItalic.ttf); }

@font-face {
  font-family: Roboto-Black;
  src: url(../content/fonts/Roboto/Roboto-Black.ttf); }

@font-face {
  font-family: Roboto-Bold;
  src: url(../content/fonts/Roboto/Roboto-Bold.ttf); }

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(../content/fonts/Roboto/Roboto-BoldItalic.ttf); }

@font-face {
  font-family: Roboto-Italic;
  src: url(../content/fonts/Roboto/Roboto-Italic.ttf); }

@font-face {
  font-family: Roboto-Light;
  src: url(../content/fonts/Roboto/Roboto-Light.ttf); }

@font-face {
  font-family: Roboto-LightItalic;
  src: url(../content/fonts/Roboto/Roboto-LightItalic.ttf); }

@font-face {
  font-family: Roboto-Medium;
  src: url(../content/fonts/Roboto/Roboto-Medium.ttf); }

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(../content/fonts/Roboto/Roboto-MediumItalic.ttf); }

@font-face {
  font-family: Roboto-Regular;
  src: url(../content/fonts/Roboto/Roboto-Regular.ttf); }

@font-face {
  font-family: Roboto-Thin;
  src: url(../content/fonts/Roboto/Roboto-Thin.ttf); }

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(../content/fonts/Roboto/Roboto-ThinItalic.ttf); }

body {
  background: #fafafa;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333; }

*:focus {
  outline: none; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 45px;
    display: inline-block; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../content/images/login-perfectstore.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../content/images/login-perfectstore.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-margin-left-right {
  margin-left: 0 !important;
  margin-right: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.modal-content {
  background-color: #4c6fb5; }

.cadena {
  height: auto;
  padding: 1.5rem 2.5rem;
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */ }
  .cadena .titulo-cadena {
    color: black;
    margin-bottom: 2rem;
    font-weight: 400;
    text-transform: uppercase; }
  .cadena [type="checkbox"]:not(:checked),
  .cadena [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .cadena [type="checkbox"]:not(:checked) + label,
  .cadena [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer; }
  .cadena [type="checkbox"]:not(:checked) + label:before,
  .cadena [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
  .cadena [type="checkbox"]:not(:checked) + label:after,
  .cadena [type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: 0.1em;
    left: 0.15em;
    font-size: 1.2em;
    line-height: 0.8;
    font-weight: bold;
    color: #0a43ab;
    transition: all .2s; }
  .cadena [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .cadena [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  .cadena [type="checkbox"]:disabled:not(:checked) + label:before,
  .cadena [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd; }
  .cadena [type="checkbox"]:disabled:checked + label:after {
    color: #999; }
  .cadena [type="checkbox"]:disabled + label {
    color: #aaa; }
  .cadena [type="checkbox"]:checked:focus + label:before,
  .cadena [type="checkbox"]:not(:checked):focus + label:before {
    border: 2px dotted blue; }
  .cadena .inline-margin {
    margin-top: 1rem; }
  .cadena .container-menu .well {
    padding-bottom: 35px; }
    .cadena .container-menu .well.container-cadena {
      background: white; }
      .cadena .container-menu .well.container-cadena.disabled-div {
        pointer-events: none;
        opacity: 0.4; }
    .cadena .container-menu .well .titulo-submenu-cadena {
      font-weight: 400; }
      .cadena .container-menu .well .titulo-submenu-cadena .form-inline {
        margin-top: -0.5rem;
        text-align: right; }
      .cadena .container-menu .well .titulo-submenu-cadena .opcion-seleccionada {
        float: right; }
      .cadena .container-menu .well .titulo-submenu-cadena .col-xs-5:focus {
        outline: none; }
    .cadena .container-menu .well .cadena-lista {
      max-height: 200px !important;
      margin: 4rem 0 0 3rem !important;
      font-weight: normal !important;
      width: 92%;
      height: auto !important; }
      .cadena .container-menu .well .cadena-lista.container-scroll {
        overflow: auto; }
      .cadena .container-menu .well .cadena-lista hr {
        height: 4px; }
      .cadena .container-menu .well .cadena-lista .custom-control {
        width: 400px !important; }
        .cadena .container-menu .well .cadena-lista .custom-control label {
          font-weight: 100 !important; }
      .cadena .container-menu .well .cadena-lista .container-button {
        margin-top: 1rem;
        text-align: right; }
        .cadena .container-menu .well .cadena-lista .container-button button.btn.disabled-button {
          pointer-events: none;
          opacity: 0.4; }
  .cadena .container-menu .container-button {
    margin-top: 1rem;
    text-align: right; }
    .cadena .container-menu .container-button button.btn.disabled-button {
      pointer-events: none;
      opacity: 0.4; }

.dashboard.dashboard-showdashboard {
  height: auto !important; }

.dashboard .header {
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important; }

.dashboard .content {
  padding: 16px; }

.dashboard .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2; }

.dashboard .sticky + .content {
  padding-top: 102px; }

.dashboard .logo {
  margin: 1.5rem 3rem !important; }

.dashboard .opc-perfil {
  position: absolute;
  background-color: #0d152a !important;
  padding-left: 10px;
  margin-left: 20px;
  width: 140px;
  line-height: 2rem; }

.dashboard .container-navbar {
  float: right; }

.dashboard .container-sidebar {
  padding-left: 0 !important; }
  .dashboard .container-sidebar .circle-close {
    position: fixed;
    width: 29px;
    height: 29px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #2a5788;
    top: 74px;
    left: 275px;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 0.3s;
    z-index: 5; }
  .dashboard .container-sidebar .circle-open {
    position: fixed;
    width: 29px;
    height: 29px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #2a5788;
    top: 252px;
    left: 31px;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 0.3s;
    z-index: 5; }
  .dashboard .container-sidebar .arrow-close-sidebar {
    color: white;
    font-size: 34px;
    position: fixed;
    top: 73px;
    left: 273px;
    border-radius: 50%;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 0.3s;
    z-index: 7;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.65); }
  .dashboard .container-sidebar .arrow-close-sidebar:hover {
    color: #f1f1f1;
    border-right: none; }
  .dashboard .container-sidebar .arrow-close-sidebar:focus {
    outline: 0; }
  .dashboard .container-sidebar .arrow-open-sidebar {
    color: white;
    font-size: 34px;
    position: fixed;
    top: 250px;
    left: 27px;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 0.3s;
    z-index: 7; }
  .dashboard .container-sidebar .arrow-open-sidebar:focus {
    outline: 0; }
  .dashboard .container-sidebar .arrow-open-sidebar:hover {
    color: #f1f1f1; }
  .dashboard .container-sidebar .opacity-zero {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 0s; }

.dashboard .pantalla-carga-ocultada {
  display: none; }

.dashboard .pantalla-carga {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }
  .dashboard .pantalla-carga .pantalla-carga-content {
    background-color: transparent;
    margin: 17rem auto;
    /* 15% from the top and centered */
    padding: 20px 20px 20px 270px;
    width: 80%;
    /* Could be more or less, depending on screen size */ }
    .dashboard .pantalla-carga .pantalla-carga-content .loader:before,
    .dashboard .pantalla-carga .pantalla-carga-content .loader:after,
    .dashboard .pantalla-carga .pantalla-carga-content .loader {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: load7 1.8s infinite ease-in-out;
      animation: load7 1.8s infinite ease-in-out; }
    .dashboard .pantalla-carga .pantalla-carga-content .loader {
      margin: 8em auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      -webkit-animation-delay: 0.16s;
      animation-delay: 0.16s; }
    .dashboard .pantalla-carga .pantalla-carga-content .loader:before {
      left: -3.5em; }
    .dashboard .pantalla-carga .pantalla-carga-content .loader:after {
      left: 3.5em;
      -webkit-animation-delay: 0.32s;
      animation-delay: 0.32s; }
    .dashboard .pantalla-carga .pantalla-carga-content .loader:before,
    .dashboard .pantalla-carga .pantalla-carga-content .loader:after {
      content: '';
      position: absolute;
      top: 0; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff; }
  40% {
    box-shadow: 0 2.5em 0 0 #fff; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff; }
  40% {
    box-shadow: 0 2.5em 0 0 #fff; } }

.dashboard .fixResultado {
  margin-top: 2rem; }

.dashboard .dashc {
  background: #f5f5f5; }
  .dashboard .dashc.no-overflow {
    overflow: hidden; }
  .dashboard .dashc .slider {
    overflow-y: hidden;
    top: 0;
    right: 0;
    transition-property: all;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }
  .dashboard .dashc .sliderClose {
    max-height: 0; }
  .dashboard .dashc .espaciomenu {
    margin-left: 290px;
    transition: 0.5s;
    box-shadow: inset 0 2px 22px 0 rgba(0, 0, 0, 0.6); }
    .dashboard .dashc .espaciomenu .margin-top-1-rem {
      margin-top: 1rem; }
    .dashboard .dashc .espaciomenu .padding-25px {
      padding-left: 25px !important;
      padding-right: 25px !important; }
    .dashboard .dashc .espaciomenu .header {
      padding: 0px !important;
      background-color: white;
      color: #f1f1f1;
      z-index: 2; }
      .dashboard .dashc .espaciomenu .header .logo .icon {
        margin-right: 14rem; }
        .dashboard .dashc .espaciomenu .header .logo .icon .image-logo {
          height: 4rem; }
    .dashboard .dashc .espaciomenu .spc {
      height: 84px; }
    .dashboard .dashc .espaciomenu .container-filters {
      background: white;
      border: 0px solid #e3e3e3 !important;
      height: auto;
      overflow: auto;
      width: auto; }
      .dashboard .dashc .espaciomenu .container-filters.filter {
        border-radius: 0 !important;
        padding-bottom: 1.5rem;
        -webkit-box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
        box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important; }
      .dashboard .dashc .espaciomenu .container-filters .container-chip {
        margin-bottom: 1rem;
        float: left;
        width: auto;
        margin-right: 20px; }
        .dashboard .dashc .espaciomenu .container-filters .container-chip span {
          margin-right: 4px; }
          .dashboard .dashc .espaciomenu .container-filters .container-chip span .titulo {
            font-weight: bold; }
        .dashboard .dashc .espaciomenu .container-filters .container-chip .chip {
          background-color: #f1f1f1;
          border-radius: 25px;
          display: inline-block;
          font-size: 13px;
          font-weight: 200;
          height: 33px;
          line-height: 34px;
          padding: 0 16px;
          text-transform: uppercase; }
          .dashboard .dashc .espaciomenu .container-filters .container-chip .chip span {
            margin-right: 4px; }
          .dashboard .dashc .espaciomenu .container-filters .container-chip .chip i:focus {
            outline: none; }
        .dashboard .dashc .espaciomenu .container-filters .container-chip .closebtn {
          padding-left: 10px;
          color: #888;
          font-weight: bold;
          float: right;
          font-size: 20px;
          cursor: pointer; }
        .dashboard .dashc .espaciomenu .container-filters .container-chip .closebtn:hover {
          color: #000; }
      .dashboard .dashc .espaciomenu .container-filters .container-button-borrar {
        text-align: center; }
        .dashboard .dashc .espaciomenu .container-filters .container-button-borrar .button-aling {
          margin-left: 29px; }
        .dashboard .dashc .espaciomenu .container-filters .container-button-borrar .button-green {
          background: #00796b;
          color: white; }
          .dashboard .dashc .espaciomenu .container-filters .container-button-borrar .button-green .refresh {
            color: white; }
    .dashboard .dashc .espaciomenu .well {
      border: 0px !important;
      -webkit-box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
      box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important; }
    .dashboard .dashc .espaciomenu .bloque-tarjetas {
      background: transparent;
      -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.05) !important;
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.05) !important;
      padding-top: 6rem; }
  .dashboard .dashc .sinsidebar2 {
    margin-left: 70px !important; }
  .dashboard .dashc .bloque {
    height: auto;
    background: white;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.05) !important;
    border-radius: 0 !important; }
  .dashboard .dashc .bloque-dropdown {
    background: white;
    border: 0px;
    height: auto;
    margin-bottom: 0px; }
  .dashboard .dashc .bloque-tarjetas {
    height: auto;
    background: #fafafa;
    border: 0px;
    margin-bottom: 0px !important; }
  .dashboard .dashc .filter {
    height: auto;
    background: #fefdff;
    margin-bottom: 0px; }

.dashboard .container-filtros-vision .container-filtros {
  margin: 20px 0; }
  .dashboard .container-filtros-vision .container-filtros.border {
    border-right: 1px solid #e4e3e3; }
  .dashboard .container-filtros-vision .container-filtros .titulo {
    display: block;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    font-weight: 100;
    font-size: 14px;
    text-transform: uppercase; }

.dashboard .container-filtros-vision .container-vision {
  margin: 20px 0; }
  .dashboard .container-filtros-vision .container-vision .titulo {
    display: block;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    font-weight: 100;
    font-size: 14px;
    text-transform: uppercase; }

@media (max-height: 811px) {
  .dashboard .dashc .slider {
    height: 100%; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1; }

.dropdown:hover .dropdown-content {
  display: block; }

.modal-content {
  background-color: #4c6fb5; }

.fecha {
  height: auto;
  padding: 1.5rem 2.5rem; }
  .fecha .titulo-fecha {
    color: black;
    font-weight: 400;
    margin-bottom: 2rem; }
  .fecha .container-menu {
    background: white; }
    .fecha .container-menu.well .full button span {
      background-color: limegreen;
      border-radius: 32px;
      color: black; }
    .fecha .container-menu.well .partially button span {
      background-color: orange;
      border-radius: 32px;
      color: black; }
    .fecha .container-menu .row select {
      height: 4rem;
      margin-bottom: 2rem; }
    .fecha .container-menu .row .container-button {
      text-align: right; }
      .fecha .container-menu .row .container-button button.btn {
        margin-top: 2rem;
        margin-right: 15px; }
        .fecha .container-menu .row .container-button button.btn.disabled-button {
          pointer-events: none;
          opacity: 0.4; }
    .fecha .container-menu .row .fecha-lista h4 {
      font-weight: 400 !important; }
    .fecha .container-menu .row .fecha-lista .container-options {
      max-width: 51rem; }
      .fecha .container-menu .row .fecha-lista .container-options .custom-radio {
        display: flex;
        justify-content: center;
        align-items: center; }
      .fecha .container-menu .row .fecha-lista .container-options .radiobutton {
        margin-left: 2rem;
        margin-right: 1rem;
        width: 20px;
        height: 20px;
        margin-top: 0; }
      .fecha .container-menu .row .fecha-lista .container-options .custom-control label {
        margin-bottom: 0;
        font-weight: 100 !important;
        font-size: large; }
    .fecha .container-menu .row .container-slider {
      margin-left: 3rem;
      margin-right: 3rem; }
    .fecha .container-menu .row .container-datepicker {
      margin: 1rem 1rem 0 1rem; }
      .fecha .container-menu .row .container-datepicker .container-buttons {
        margin-right: 15px;
        margin-left: 15px; }
      .fecha .container-menu .row .container-datepicker .opt-dia {
        padding-right: 25%;
        padding-left: 25%;
        width: 100%; }

.modal-content {
  background-color: #f5f5f5 !important; }

.geografia {
  height: auto;
  padding: 1.5rem 2.5rem;
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */ }
  .geografia .titulo-geografia {
    color: black;
    margin-bottom: 2rem;
    font-weight: 400;
    text-transform: uppercase; }
  .geografia [type="checkbox"]:not(:checked),
  .geografia [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .geografia [type="checkbox"]:not(:checked) + label,
  .geografia [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer; }
  .geografia [type="checkbox"]:not(:checked) + label:before,
  .geografia [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
  .geografia [type="checkbox"]:not(:checked) + label:after,
  .geografia [type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: absolute;
    top: 0.1em;
    left: 0.15em;
    font-size: 1.2em;
    line-height: 0.8;
    font-weight: bold;
    color: #0a43ab;
    transition: all .2s; }
  .geografia [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .geografia [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  .geografia [type="checkbox"]:disabled:not(:checked) + label:before,
  .geografia [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd; }
  .geografia [type="checkbox"]:disabled:checked + label:after {
    color: #999; }
  .geografia [type="checkbox"]:disabled + label {
    color: #aaa; }
  .geografia [type="checkbox"]:checked:focus + label:before,
  .geografia [type="checkbox"]:not(:checked):focus + label:before {
    border: 2px dotted blue; }
  .geografia .container-menu .well {
    padding-bottom: 35px; }
    .geografia .container-menu .well.container-option {
      background: white !important; }
    .geografia .container-menu .well.disabled-div {
      pointer-events: none;
      opacity: 0.4; }
    .geografia .container-menu .well .titulo-submenu-geografia {
      font-weight: 400; }
      .geografia .container-menu .well .titulo-submenu-geografia .form-inline {
        margin-top: -0.5rem;
        text-align: right; }
      .geografia .container-menu .well .titulo-submenu-geografia .opcion-seleccionada {
        float: right; }
      .geografia .container-menu .well .titulo-submenu-geografia .col-xs-5:focus {
        outline: none; }
    .geografia .container-menu .well .geografia-lista {
      margin: 4rem 0 0 3rem !important;
      width: 92%;
      max-height: 200px !important; }
      .geografia .container-menu .well .geografia-lista.container-scroll {
        overflow: auto; }
      .geografia .container-menu .well .geografia-lista hr {
        height: 4px; }
      .geografia .container-menu .well .geografia-lista .custom-control {
        width: 400px !important; }
        .geografia .container-menu .well .geografia-lista .custom-control label {
          font-weight: 100 !important; }
      .geografia .container-menu .well .geografia-lista .container-button {
        margin-top: 1rem;
        text-align: right; }
        .geografia .container-menu .well .geografia-lista .container-button button.btn.disabled-button {
          pointer-events: none;
          opacity: 0.4; }
  .geografia .container-menu .container-button {
    margin-top: 1rem;
    text-align: right; }
    .geografia .container-menu .container-button button.btn.disabled-button {
      pointer-events: none;
      opacity: 0.4; }

.graficaPrincipal {
  position: relative; }
  .graficaPrincipal .image-logo {
    position: absolute;
    top: 10px;
    right: 0;
    margin-right: 1rem;
    height: 4rem; }
  .graficaPrincipal .graficoPrincipal {
    height: 400px;
    margin-bottom: -11rem !important;
    color: #B2DFDB;
    border-color: #B2DFDB;
    background: linear-gradient(0deg, #0039a5 0, #1777c3 33%, #169ae1 66%, #b3d8ff);
    box-shadow: inset 0 2px 22px 0 rgba(0, 0, 0, 0.6); }
  @media (max-height: 811px) {
    .graficaPrincipal .graficoPrincipal {
      height: 350px; } }

input, button, span, a {
  font-family: 'Advent Pro', Helvetica, Arial, Lucida, sans-serif; }

.home {
  background-image: url("/content/images/PORTADA_PERFECT_STORE.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-color: #7B868C;
  height: 53vw;
  display: flex;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 1s;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  font-family: Montserrat,sans-serif; }

.boxLog {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 25em;
  padding: 10px 2.5em;
  box-sizing: border-box;
  border-radius: 15px; }

.boxLog h2 {
  margin: 0 0 1.875em;
  padding: 0;
  color: #fff;
  text-align: center;
  font-weight: unset; }

.boxLog .inputBox {
  position: relative; }

.boxLog .inputBox input {
  width: 100%;
  padding: 0.625em 10px;
  font-size: 1em;
  color: #fff;
  letter-spacing: 0.062em;
  margin-bottom: 1.875em;
  border: none;
  outline: none;
  background: rgba(17, 79, 108, 0.6);
  border-radius: 0.625em;
  font-weight: unset; }

.boxLog .inputBox label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0.625em 0;
  font-size: 1em;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
  font-weight: unset; }

.boxLog .inputBox input:focus ~ label,
.boxLog .inputBox input:valid ~ label,
.boxLog .inputBox input:not([value='']) ~ label {
  top: -2em;
  left: 10px;
  color: white;
  font-size: 0.75em;
  font-weight: unset; }

.boxLog input[type='submit'] {
  border: none;
  outline: none;
  color: #fff;
  background-color: #03a9f4;
  padding: 0.625em 1.25em;
  cursor: pointer;
  border-radius: 0.312em;
  font-size: 1em;
  font-weight: unset; }

.boxLog input[type='submit']:hover {
  background-color: #1cb1f5; }

.btnLogin.btnLogin-primary {
  background-color: #3a8dde !important;
  border-color: #3a8dde !important;
  color: #fff !important;
  height: 2.28571429em;
  outline: 0; }

.btnLogin {
  border: 1px solid transparent;
  border-radius: 15px !important;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  padding: .375em .75em;
  text-align: center;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

.site-footer {
  background-color: #7B868C;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  transition: all 1s;
  overflow: hidden;
  min-height: 30vh; }
  .site-footer.hidden {
    top: -100%;
    visibility: hidden; }
  .site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5; }
    .site-footer hr .small {
      margin: 20px 0; }
  .site-footer h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px; }
  .site-footer a {
    color: #fff; }
    .site-footer a :hover {
      color: #fff;
      text-decoration: none; }

.footer-links {
  padding-left: 0;
  list-style: none; }
  .footer-links li {
    display: block; }
  .footer-links a {
    color: #fff; }
    .footer-links a :active,
    .footer-links a :focus,
    .footer-links a :hover {
      color: #fff;
      text-decoration: none; }
  .footer-links p {
    color: #fff; }
    .footer-links p :active,
    .footer-links p :focus,
    .footer-links p :hover {
      color: #fff;
      text-decoration: none; }
  .footer-links .inline li {
    display: inline-block; }

.mapa.fondo-pic {
  display: block;
  /* Hidden by default */
  z-index: 9;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.mapa .fondo-pic {
  display: block;
  /* Hidden by default */
  z-index: 9;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.mapa .container-fluid {
  margin-top: 0px;
  margin-right: 0px; }
  .mapa .container-fluid .container-card {
    padding-top: 19px;
    padding-bottom: 19px; }
    .mapa .container-fluid .container-card .card-header {
      background: white; }
    .mapa .container-fluid .container-card .card-body {
      background: white; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .button-container {
        text-align: center; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .profile-pic {
        height: 500px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-blend-mode: multiply;
        vertical-align: middle;
        text-align: center;
        color: transparent;
        transition: all .3s ease;
        text-decoration: none;
        margin-top: 20px;
        margin-bottom: 1rem; }
        .mapa .container-fluid .container-card .card-body form .container-fluid .profile-pic span {
          display: inline-block;
          display: inline-block;
          padding-top: 240px; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .profile-pic:hover {
        background-color: rgba(0, 0, 0, 0.5) !important;
        z-index: 10000 !important;
        color: #fff !important;
        transition: all .3s ease !important;
        text-decoration: none !important; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .expand-pic {
        z-index: 10;
        margin-left: auto;
        margin-right: auto;
        height: 520px;
        width: 520px;
        background-size: cover;
        background-position: center;
        background-blend-mode: multiply;
        vertical-align: middle;
        text-align: center;
        color: transparent;
        transition: all .3s ease;
        text-decoration: none;
        margin-top: 20px;
        margin-bottom: 1rem; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .data {
        font-weight: bold; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .tienda-name {
        font-size: 2rem;
        color: #2a5788; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .container-info {
        margin-left: 4rem; }
      .mapa .container-fluid .container-card .card-body form .container-fluid .container-bottom {
        margin-left: 3rem; }
        .mapa .container-fluid .container-card .card-body form .container-fluid .container-bottom .description {
          margin-top: 1rem; }

.mapa .textoMapa {
  font-size: 11px;
  font-weight: 100; }

.mapa .cargandoIcon {
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: -2rem !important; }

.mapa .row .col-xs-12 .col-xs-11 .col-xs-12.title {
  font-weight: 400; }

.mapa .row .col-xs-12 .col-xs-1.container-btn {
  text-align: right; }
  .mapa .row .col-xs-12 .col-xs-1.container-btn .btn {
    color: black;
    background: transparent;
    border-color: transparent;
    margin-top: 16px; }
  .mapa .row .col-xs-12 .col-xs-1.container-btn .btn:hover {
    color: black;
    background: transparent;
    border-color: transparent; }
  .mapa .row .col-xs-12 .col-xs-1.container-btn .btn:active {
    color: black;
    background: transparent;
    border-color: transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important; }
  .mapa .row .col-xs-12 .col-xs-1.container-btn .btn:focus {
    outline: none; }

.mapa .row .col-xs-12 .tooltip-custom {
  position: relative;
  display: inline-block; }

.mapa .row .col-xs-12 .tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s; }

.mapa .row .col-xs-12 .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

.mapa .row .col-xs-12 .tooltip-custom:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.mapa .row .col-xs-12 .map-overlay {
  display: block;
  height: 41px;
  width: 34px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }
  .mapa .row .col-xs-12 .map-overlay.green {
    background-image: url(../../../content/images/pinMapaVerde.png); }
  .mapa .row .col-xs-12 .map-overlay.orange {
    background-image: url(../../../content/images/pinMapaNaranja.png); }
  .mapa .row .col-xs-12 .map-overlay.blue {
    background-image: url(../../../content/images/pinMapaAzul.png); }

.mapa .row .col-xs-12 .container-buttons .button-mapa {
  float: left;
  margin: 2rem 0 2rem 2rem;
  opacity: .6;
  pointer-events: initial;
  outline: none; }
  .mapa .row .col-xs-12 .container-buttons .button-mapa strong {
    font-size: 16px; }
  .mapa .row .col-xs-12 .container-buttons .button-mapa.button-mapa-active {
    opacity: 1;
    pointer-events: none; }

.mapa .row .col-xs-12 .container-fluid {
  margin-left: 1rem;
  margin-right: 1rem; }
  .mapa .row .col-xs-12 .container-fluid .map {
    width: 100% !important;
    height: 500px !important; }

.perfil {
  padding-right: 0 !important;
  /* The Modal (background) */
  /* Modal Content/Box */
  /* The Close Button */ }
  .perfil.content {
    font-size: 16px;
    padding: 0 !important; }
    .perfil.content .header {
      box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
      background-color: white;
      color: #f1f1f1;
      z-index: 2;
      display: block;
      padding: 0 !important;
      height: 84px;
      overflow: hidden;
      box-sizing: border-box;
      text-align: center; }
      .perfil.content .header .collapse-button {
        width: 7.5%;
        height: 84px;
        background: white;
        color: black;
        line-height: 84px;
        text-align: center;
        float: left;
        cursor: pointer; }
        .perfil.content .header .collapse-button i {
          vertical-align: middle; }
      .perfil.content .header .collapse-button:focus {
        border: transparent !important;
        outline: none; }
      .perfil.content .header .logo {
        display: inline-block;
        text-align: center;
        float: right; }
        .perfil.content .header .logo .icon .image-logo {
          height: 4rem !important;
          margin: 2rem; }
    .perfil.content .container-fluid {
      margin-top: 0px;
      margin-right: 0px; }
      .perfil.content .container-fluid .container-card {
        padding-top: 19px; }
        .perfil.content .container-fluid .container-card .card-header {
          background: white; }
        .perfil.content .container-fluid .container-card .card-body {
          background: white; }
          .perfil.content .container-fluid .container-card .card-body form .profile-pic {
            border-radius: 50%;
            height: 135px;
            width: 135px;
            background-size: cover;
            background-position: center;
            background-blend-mode: multiply;
            vertical-align: middle;
            text-align: center;
            color: transparent;
            transition: all .3s ease;
            text-decoration: none;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            margin-left: 1rem; }
            .perfil.content .container-fluid .container-card .card-body form .profile-pic span {
              padding-top: 4.5em;
              padding-bottom: 4.5em; }
          .perfil.content .container-fluid .container-card .card-body form .profile-pic:hover {
            background-color: rgba(0, 0, 0, 0.5) !important;
            z-index: 10000 !important;
            color: #fff !important;
            transition: all .3s ease !important;
            text-decoration: none !important; }
          .perfil.content .container-fluid .container-card .card-body form .container-info .col-contrasena {
            margin: 0 20px 0 38px; }
          .perfil.content .container-fluid .container-card .card-body form .container-bottom .description {
            margin-top: 1rem; }
    .perfil.content .circular {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden; }
    .perfil.content .animated {
      animation-duration: 1s;
      animation-fill-mode: both; }
      .perfil.content .animated.infinite {
        animation-iteration-count: infinite; }
      .perfil.content .animated.hinge {
        animation-duration: 2s; }
      .perfil.content .animated.bounceIn, .perfil.content .animated.bounceOut, .perfil.content .animated.flipOutX, .perfil.content .animated.flipOutY {
        animation-duration: .75s; }

@keyframes a {
  0%, to {
    transform: translateZ(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0); } }
    .perfil.content .shake {
      animation-name: a; }

@keyframes b {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }
    .perfil.content .fadeInDown {
      animation-name: b; }

@keyframes c {
  0% {
    opacity: 1; }
  to {
    opacity: 0; } }
    .perfil.content .fadeOut {
      animation-name: c; }

@keyframes d {
  0% {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }
    .perfil.content .fadeOutDown {
      animation-name: d; }

@keyframes e {
  0% {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }
    .perfil.content .fadeOutUp {
      animation-name: e; }
    .perfil.content .ps-container {
      -ms-touch-action: auto;
      touch-action: auto;
      overflow: hidden !important;
      -ms-overflow-style: none; }
      .perfil.content .ps-container.ps-active-x > .ps-scrollbar-x-rail, .perfil.content .ps-container.ps-active-y > .ps-scrollbar-y-rail {
        display: block;
        background-color: transparent; }
      .perfil.content .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
          background-color: #999;
          height: 11px; }
      .perfil.content .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
          background-color: #999;
          width: 11px; }
      .perfil.content .ps-container > .ps-scrollbar-x-rail {
        display: none;
        position: absolute;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        bottom: 0;
        height: 15px; }
        .perfil.content .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
          position: absolute;
          background-color: #aaa;
          border-radius: 6px;
          transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
          bottom: 2px;
          height: 6px; }
        .perfil.content .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x, .perfil.content .ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
          height: 11px; }
      .perfil.content .ps-container > .ps-scrollbar-y-rail {
        display: none;
        position: absolute;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        right: 0;
        width: 15px; }
        .perfil.content .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
          position: absolute;
          background-color: #aaa;
          border-radius: 6px;
          transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
          right: 2px;
          width: 6px; }
        .perfil.content .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y, .perfil.content .ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
          width: 11px; }
      .perfil.content .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
          background-color: #999;
          height: 11px; }
      .perfil.content .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
          background-color: #999;
          width: 11px; }
      .perfil.content .ps-container:hover > .ps-scrollbar-x-rail, .perfil.content .ps-container:hover > .ps-scrollbar-y-rail {
        opacity: .6; }
      .perfil.content .ps-container:hover > .ps-scrollbar-x-rail:hover {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
          background-color: #999; }
      .perfil.content .ps-container:hover > .ps-scrollbar-y-rail:hover {
        background-color: #eee;
        opacity: .9; }
        .perfil.content .ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
          background-color: #999; }
    @supports (-ms-overflow-style: none) {
      .perfil.content .ps-container {
        overflow: auto !important; } }
    @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
      .perfil.content .ps-container {
        overflow: auto !important; } }
    .perfil.content .btn, .perfil.content .navbar .navbar-nav > a.btn {
      border-width: 2px;
      font-weight: 400;
      font-size: .8571em;
      line-height: 1.35em;
      border: none;
      margin: 10px 1px;
      border-radius: .1875rem;
      padding: 11px 22px;
      cursor: pointer;
      background-color: #888;
      color: #fff; }
    .perfil.content .btn.active:hover, .perfil.content .btn:active:hover, .perfil.content .btn:focus, .perfil.content .btn:hover {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
    .perfil.content .btn:not(:disabled):not(.disabled).active, .perfil.content .btn:not(:disabled):not(.disabled):active {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
      .perfil.content .btn:not(:disabled):not(.disabled).active:focus, .perfil.content .btn:not(:disabled):not(.disabled):active:focus {
        background-color: #979797;
        color: #fff;
        box-shadow: none; }
    .perfil.content .navbar .navbar-nav > a.btn.active:hover, .perfil.content .navbar .navbar-nav > a.btn:active:hover, .perfil.content .navbar .navbar-nav > a.btn:focus, .perfil.content .navbar .navbar-nav > a.btn:hover {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
    .perfil.content .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active, .perfil.content .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
      .perfil.content .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active:focus, .perfil.content .navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active:focus {
        background-color: #979797;
        color: #fff;
        box-shadow: none; }
    .perfil.content .show > .btn.dropdown-toggle {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn.dropdown-toggle:focus, .perfil.content .show > .btn.dropdown-toggle:hover {
        background-color: #979797;
        color: #fff;
        box-shadow: none; }
    .perfil.content .show > .navbar .navbar-nav > a.btn.dropdown-toggle {
      background-color: #979797;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .navbar .navbar-nav > a.btn.dropdown-toggle:focus, .perfil.content .show > .navbar .navbar-nav > a.btn.dropdown-toggle:hover {
        background-color: #979797;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn:not([data-action]):hover, .perfil.content .navbar .navbar-nav > a.btn:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn.disabled {
      background-color: #888;
      border-color: #888; }
      .perfil.content .btn.disabled.active, .perfil.content .btn.disabled.focus, .perfil.content .btn.disabled:active, .perfil.content .btn.disabled:focus, .perfil.content .btn.disabled:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .btn:disabled {
      background-color: #888;
      border-color: #888; }
      .perfil.content .btn:disabled.active, .perfil.content .btn:disabled.focus, .perfil.content .btn:disabled:active, .perfil.content .btn:disabled:focus, .perfil.content .btn:disabled:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .btn[disabled] {
      background-color: #888;
      border-color: #888; }
      .perfil.content .btn[disabled].active, .perfil.content .btn[disabled].focus, .perfil.content .btn[disabled]:active, .perfil.content .btn[disabled]:focus, .perfil.content .btn[disabled]:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .navbar .navbar-nav > a.btn.disabled {
      background-color: #888;
      border-color: #888; }
      .perfil.content .navbar .navbar-nav > a.btn.disabled.active, .perfil.content .navbar .navbar-nav > a.btn.disabled.focus, .perfil.content .navbar .navbar-nav > a.btn.disabled:active, .perfil.content .navbar .navbar-nav > a.btn.disabled:focus, .perfil.content .navbar .navbar-nav > a.btn.disabled:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .navbar .navbar-nav > a.btn:disabled {
      background-color: #888;
      border-color: #888; }
      .perfil.content .navbar .navbar-nav > a.btn:disabled.active, .perfil.content .navbar .navbar-nav > a.btn:disabled.focus, .perfil.content .navbar .navbar-nav > a.btn:disabled:active, .perfil.content .navbar .navbar-nav > a.btn:disabled:focus, .perfil.content .navbar .navbar-nav > a.btn:disabled:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .navbar .navbar-nav > a.btn[disabled] {
      background-color: #888;
      border-color: #888; }
      .perfil.content .navbar .navbar-nav > a.btn[disabled].active, .perfil.content .navbar .navbar-nav > a.btn[disabled].focus, .perfil.content .navbar .navbar-nav > a.btn[disabled]:active, .perfil.content .navbar .navbar-nav > a.btn[disabled]:focus, .perfil.content .navbar .navbar-nav > a.btn[disabled]:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content fieldset[disabled] .btn {
      background-color: #888;
      border-color: #888; }
      .perfil.content fieldset[disabled] .btn.active, .perfil.content fieldset[disabled] .btn.focus, .perfil.content fieldset[disabled] .btn:active, .perfil.content fieldset[disabled] .btn:focus, .perfil.content fieldset[disabled] .btn:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn {
      background-color: #888;
      border-color: #888; }
      .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn.active, .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn.focus, .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn:active, .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn:focus, .perfil.content fieldset[disabled] .navbar .navbar-nav > a.btn:hover {
        background-color: #888;
        border-color: #888; }
    .perfil.content .btn.btn-simple, .perfil.content .navbar .navbar-nav > a.btn.btn-simple {
      color: #888;
      border-color: #888; }
    .perfil.content .btn.btn-simple:active, .perfil.content .btn.btn-simple:focus, .perfil.content .btn.btn-simple:hover {
      background-color: transparent;
      color: #979797;
      border-color: #979797;
      box-shadow: none; }
    .perfil.content .navbar .navbar-nav > a.btn.btn-simple:active, .perfil.content .navbar .navbar-nav > a.btn.btn-simple:focus, .perfil.content .navbar .navbar-nav > a.btn.btn-simple:hover {
      background-color: transparent;
      color: #979797;
      border-color: #979797;
      box-shadow: none; }
    .perfil.content .btn.btn-link, .perfil.content .navbar .navbar-nav > a.btn.btn-link {
      color: #888; }
    .perfil.content .btn.btn-link:active, .perfil.content .btn.btn-link:focus, .perfil.content .btn.btn-link:hover {
      background-color: transparent;
      color: #979797;
      text-decoration: none;
      box-shadow: none; }
    .perfil.content .navbar .navbar-nav > a.btn.btn-link:active, .perfil.content .navbar .navbar-nav > a.btn.btn-link:focus, .perfil.content .navbar .navbar-nav > a.btn.btn-link:hover {
      background-color: transparent;
      color: #979797;
      text-decoration: none;
      box-shadow: none; }
    .perfil.content .btn:focus, .perfil.content .btn:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      outline: 0 !important; }
    .perfil.content .navbar .navbar-nav > a.btn:focus, .perfil.content .navbar .navbar-nav > a.btn:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      outline: 0 !important; }
    .perfil.content .btn.active, .perfil.content .btn:active {
      box-shadow: none;
      outline: 0 !important; }
    .perfil.content .navbar .navbar-nav > a.btn.active, .perfil.content .navbar .navbar-nav > a.btn:active {
      box-shadow: none;
      outline: 0 !important; }
    .perfil.content .open > .btn.dropdown-toggle, .perfil.content .open > .navbar .navbar-nav > a.btn.dropdown-toggle {
      box-shadow: none;
      outline: 0 !important; }
    .perfil.content .btn .badge, .perfil.content .navbar .navbar-nav > a.btn .badge {
      margin: 0; }
    .perfil.content .btn.btn-icon, .perfil.content .navbar .navbar-nav > a.btn.btn-icon {
      height: 2.375rem;
      min-width: 2.375rem;
      width: 2.375rem;
      padding: 0;
      font-size: .9375rem;
      overflow: hidden;
      position: relative;
      line-height: normal; }
    .perfil.content .btn.btn-icon.btn-simple, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-simple {
      padding: 0; }
    .perfil.content .btn.btn-icon.btn-sm, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-sm {
      height: 1.875rem;
      min-width: 1.875rem;
      width: 1.875rem; }
    .perfil.content .btn.btn-icon.btn-sm .fa, .perfil.content .btn.btn-icon.btn-sm .far, .perfil.content .btn.btn-icon.btn-sm .fas, .perfil.content .btn.btn-icon.btn-sm .now-ui-icons {
      font-size: .6875rem; }
    .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fa, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-sm .far, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fas, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-sm .now-ui-icons {
      font-size: .6875rem; }
    .perfil.content .btn.btn-icon.btn-lg, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-lg {
      height: 3.6rem;
      min-width: 3.6rem;
      width: 3.6rem; }
    .perfil.content .btn.btn-icon.btn-lg .fa, .perfil.content .btn.btn-icon.btn-lg .far, .perfil.content .btn.btn-icon.btn-lg .fas, .perfil.content .btn.btn-icon.btn-lg .now-ui-icons {
      font-size: 1.325rem; }
    .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fa, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-lg .far, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fas, .perfil.content .navbar .navbar-nav > a.btn.btn-icon.btn-lg .now-ui-icons {
      font-size: 1.325rem; }
    .perfil.content .btn.btn-icon:not(.btn-footer) .fa, .perfil.content .btn.btn-icon:not(.btn-footer) .far, .perfil.content .btn.btn-icon:not(.btn-footer) .fas, .perfil.content .btn.btn-icon:not(.btn-footer) .now-ui-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12px, -12px);
      line-height: 1.5626rem;
      width: 24px; }
    .perfil.content .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fa, .perfil.content .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .far, .perfil.content .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fas, .perfil.content .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .now-ui-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12px, -12px);
      line-height: 1.5626rem;
      width: 24px; }
    .perfil.content .btn:not(.btn-icon) .now-ui-icons, .perfil.content .navbar .navbar-nav > a.btn:not(.btn-icon) .now-ui-icons {
      position: relative;
      top: 1px; }
    .perfil.content .btn-primary {
      background-color: #337ab7;
      color: #fff; }
      .perfil.content .btn-primary.active:hover, .perfil.content .btn-primary:active:hover, .perfil.content .btn-primary:focus, .perfil.content .btn-primary:hover {
        background-color: #3e8fd6;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-primary:not(:disabled):not(.disabled).active, .perfil.content .btn-primary:not(:disabled):not(.disabled):active {
        background-color: #3e8fd6;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-primary:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-primary:not(:disabled):not(.disabled):active:focus {
          background-color: #3e8fd6;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-primary.dropdown-toggle {
      background-color: #3e8fd6;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-primary.dropdown-toggle:focus, .perfil.content .show > .btn-primary.dropdown-toggle:hover {
        background-color: #3e8fd6;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-primary:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-primary.disabled {
      background-color: #337ab7;
      border-color: #337ab7; }
      .perfil.content .btn-primary.disabled.active, .perfil.content .btn-primary.disabled.focus, .perfil.content .btn-primary.disabled:active, .perfil.content .btn-primary.disabled:focus, .perfil.content .btn-primary.disabled:hover {
        background-color: #337ab7;
        border-color: #337ab7; }
    .perfil.content .btn-primary:disabled {
      background-color: #337ab7;
      border-color: #337ab7; }
      .perfil.content .btn-primary:disabled.active, .perfil.content .btn-primary:disabled.focus, .perfil.content .btn-primary:disabled:active, .perfil.content .btn-primary:disabled:focus, .perfil.content .btn-primary:disabled:hover {
        background-color: #337ab7;
        border-color: #337ab7; }
    .perfil.content .btn-primary[disabled] {
      background-color: #337ab7;
      border-color: #337ab7; }
      .perfil.content .btn-primary[disabled].active, .perfil.content .btn-primary[disabled].focus, .perfil.content .btn-primary[disabled]:active, .perfil.content .btn-primary[disabled]:focus, .perfil.content .btn-primary[disabled]:hover {
        background-color: #337ab7;
        border-color: #337ab7; }
    .perfil.content fieldset[disabled] .btn-primary {
      background-color: #337ab7;
      border-color: #337ab7; }
      .perfil.content fieldset[disabled] .btn-primary.active, .perfil.content fieldset[disabled] .btn-primary.focus, .perfil.content fieldset[disabled] .btn-primary:active, .perfil.content fieldset[disabled] .btn-primary:focus, .perfil.content fieldset[disabled] .btn-primary:hover {
        background-color: #337ab7;
        border-color: #337ab7; }
    .perfil.content .btn-primary.btn-simple {
      color: #337ab7;
      border-color: #337ab7; }
      .perfil.content .btn-primary.btn-simple:active, .perfil.content .btn-primary.btn-simple:focus, .perfil.content .btn-primary.btn-simple:hover {
        background-color: transparent;
        color: #3e8fd6;
        border-color: #3e8fd6;
        box-shadow: none; }
    .perfil.content .btn-primary.btn-link {
      color: #337ab7; }
      .perfil.content .btn-primary.btn-link:active, .perfil.content .btn-primary.btn-link:focus, .perfil.content .btn-primary.btn-link:hover {
        background-color: transparent;
        color: #3e8fd6;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn-success {
      background-color: #52a352;
      color: #fff; }
      .perfil.content .btn-success.active:hover, .perfil.content .btn-success:active:hover, .perfil.content .btn-success:focus, .perfil.content .btn-success:hover {
        background-color: #52a352;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-success:not(:disabled):not(.disabled).active, .perfil.content .btn-success:not(:disabled):not(.disabled):active {
        background-color: #52a352;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-success:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-success:not(:disabled):not(.disabled):active:focus {
          background-color: #52a352;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-success.dropdown-toggle {
      background-color: #52a352;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-success.dropdown-toggle:focus, .perfil.content .show > .btn-success.dropdown-toggle:hover {
        background-color: #52a352;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-success:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-success.disabled {
      background-color: #52a352;
      border-color: #52a352; }
      .perfil.content .btn-success.disabled.active, .perfil.content .btn-success.disabled.focus, .perfil.content .btn-success.disabled:active, .perfil.content .btn-success.disabled:focus, .perfil.content .btn-success.disabled:hover {
        background-color: #52a352;
        border-color: #52a352; }
    .perfil.content .btn-success:disabled {
      background-color: #52a352;
      border-color: #52a352; }
      .perfil.content .btn-success:disabled.active, .perfil.content .btn-success:disabled.focus, .perfil.content .btn-success:disabled:active, .perfil.content .btn-success:disabled:focus, .perfil.content .btn-success:disabled:hover {
        background-color: #52a352;
        border-color: #52a352; }
    .perfil.content .btn-success[disabled] {
      background-color: #52a352;
      border-color: #52a352; }
      .perfil.content .btn-success[disabled].active, .perfil.content .btn-success[disabled].focus, .perfil.content .btn-success[disabled]:active, .perfil.content .btn-success[disabled]:focus, .perfil.content .btn-success[disabled]:hover {
        background-color: #52a352;
        border-color: #52a352; }
    .perfil.content fieldset[disabled] .btn-success {
      background-color: #52a352;
      border-color: #52a352; }
      .perfil.content fieldset[disabled] .btn-success.active, .perfil.content fieldset[disabled] .btn-success.focus, .perfil.content fieldset[disabled] .btn-success:active, .perfil.content fieldset[disabled] .btn-success:focus, .perfil.content fieldset[disabled] .btn-success:hover {
        background-color: #52a352;
        border-color: #52a352; }
    .perfil.content .btn-success.btn-simple {
      color: #52a352;
      border-color: #52a352; }
      .perfil.content .btn-success.btn-simple:active, .perfil.content .btn-success.btn-simple:focus, .perfil.content .btn-success.btn-simple:hover {
        background-color: transparent;
        color: #52a352;
        border-color: #52a352;
        box-shadow: none; }
    .perfil.content .btn-success.btn-link {
      color: #52a352; }
      .perfil.content .btn-success.btn-link:active, .perfil.content .btn-success.btn-link:focus, .perfil.content .btn-success.btn-link:hover {
        background-color: transparent;
        color: #52a352;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn-info {
      background-color: #2ca8ff;
      color: #fff; }
      .perfil.content .btn-info.active:hover, .perfil.content .btn-info:active:hover, .perfil.content .btn-info:focus, .perfil.content .btn-info:hover {
        background-color: #4bb5ff;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-info:not(:disabled):not(.disabled).active, .perfil.content .btn-info:not(:disabled):not(.disabled):active {
        background-color: #4bb5ff;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-info:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-info:not(:disabled):not(.disabled):active:focus {
          background-color: #4bb5ff;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-info.dropdown-toggle {
      background-color: #4bb5ff;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-info.dropdown-toggle:focus, .perfil.content .show > .btn-info.dropdown-toggle:hover {
        background-color: #4bb5ff;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-info:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-info.disabled {
      background-color: #2ca8ff;
      border-color: #2ca8ff; }
      .perfil.content .btn-info.disabled.active, .perfil.content .btn-info.disabled.focus, .perfil.content .btn-info.disabled:active, .perfil.content .btn-info.disabled:focus, .perfil.content .btn-info.disabled:hover {
        background-color: #2ca8ff;
        border-color: #2ca8ff; }
    .perfil.content .btn-info:disabled {
      background-color: #2ca8ff;
      border-color: #2ca8ff; }
      .perfil.content .btn-info:disabled.active, .perfil.content .btn-info:disabled.focus, .perfil.content .btn-info:disabled:active, .perfil.content .btn-info:disabled:focus, .perfil.content .btn-info:disabled:hover {
        background-color: #2ca8ff;
        border-color: #2ca8ff; }
    .perfil.content .btn-info[disabled] {
      background-color: #2ca8ff;
      border-color: #2ca8ff; }
      .perfil.content .btn-info[disabled].active, .perfil.content .btn-info[disabled].focus, .perfil.content .btn-info[disabled]:active, .perfil.content .btn-info[disabled]:focus, .perfil.content .btn-info[disabled]:hover {
        background-color: #2ca8ff;
        border-color: #2ca8ff; }
    .perfil.content fieldset[disabled] .btn-info {
      background-color: #2ca8ff;
      border-color: #2ca8ff; }
      .perfil.content fieldset[disabled] .btn-info.active, .perfil.content fieldset[disabled] .btn-info.focus, .perfil.content fieldset[disabled] .btn-info:active, .perfil.content fieldset[disabled] .btn-info:focus, .perfil.content fieldset[disabled] .btn-info:hover {
        background-color: #2ca8ff;
        border-color: #2ca8ff; }
    .perfil.content .btn-info.btn-simple {
      color: #2ca8ff;
      border-color: #2ca8ff; }
      .perfil.content .btn-info.btn-simple:active, .perfil.content .btn-info.btn-simple:focus, .perfil.content .btn-info.btn-simple:hover {
        background-color: transparent;
        color: #4bb5ff;
        border-color: #4bb5ff;
        box-shadow: none; }
    .perfil.content .btn-info.btn-link {
      color: #2ca8ff; }
      .perfil.content .btn-info.btn-link:active, .perfil.content .btn-info.btn-link:focus, .perfil.content .btn-info.btn-link:hover {
        background-color: transparent;
        color: #4bb5ff;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn-warning {
      background-color: #ffb236;
      color: #fff; }
      .perfil.content .btn-warning.active:hover, .perfil.content .btn-warning:active:hover, .perfil.content .btn-warning:focus, .perfil.content .btn-warning:hover {
        background-color: #ffbe55;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-warning:not(:disabled):not(.disabled).active, .perfil.content .btn-warning:not(:disabled):not(.disabled):active {
        background-color: #ffbe55;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-warning:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-warning:not(:disabled):not(.disabled):active:focus {
          background-color: #ffbe55;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-warning.dropdown-toggle {
      background-color: #ffbe55;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-warning.dropdown-toggle:focus, .perfil.content .show > .btn-warning.dropdown-toggle:hover {
        background-color: #ffbe55;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-warning:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-warning.disabled {
      background-color: #ffb236;
      border-color: #ffb236; }
      .perfil.content .btn-warning.disabled.active, .perfil.content .btn-warning.disabled.focus, .perfil.content .btn-warning.disabled:active, .perfil.content .btn-warning.disabled:focus, .perfil.content .btn-warning.disabled:hover {
        background-color: #ffb236;
        border-color: #ffb236; }
    .perfil.content .btn-warning:disabled {
      background-color: #ffb236;
      border-color: #ffb236; }
      .perfil.content .btn-warning:disabled.active, .perfil.content .btn-warning:disabled.focus, .perfil.content .btn-warning:disabled:active, .perfil.content .btn-warning:disabled:focus, .perfil.content .btn-warning:disabled:hover {
        background-color: #ffb236;
        border-color: #ffb236; }
    .perfil.content .btn-warning[disabled] {
      background-color: #ffb236;
      border-color: #ffb236; }
      .perfil.content .btn-warning[disabled].active, .perfil.content .btn-warning[disabled].focus, .perfil.content .btn-warning[disabled]:active, .perfil.content .btn-warning[disabled]:focus, .perfil.content .btn-warning[disabled]:hover {
        background-color: #ffb236;
        border-color: #ffb236; }
    .perfil.content fieldset[disabled] .btn-warning {
      background-color: #ffb236;
      border-color: #ffb236; }
      .perfil.content fieldset[disabled] .btn-warning.active, .perfil.content fieldset[disabled] .btn-warning.focus, .perfil.content fieldset[disabled] .btn-warning:active, .perfil.content fieldset[disabled] .btn-warning:focus, .perfil.content fieldset[disabled] .btn-warning:hover {
        background-color: #ffb236;
        border-color: #ffb236; }
    .perfil.content .btn-warning.btn-simple {
      color: #ffb236;
      border-color: #ffb236; }
      .perfil.content .btn-warning.btn-simple:active, .perfil.content .btn-warning.btn-simple:focus, .perfil.content .btn-warning.btn-simple:hover {
        background-color: transparent;
        color: #ffbe55;
        border-color: #ffbe55;
        box-shadow: none; }
    .perfil.content .btn-warning.btn-link {
      color: #ffb236; }
      .perfil.content .btn-warning.btn-link:active, .perfil.content .btn-warning.btn-link:focus, .perfil.content .btn-warning.btn-link:hover {
        background-color: transparent;
        color: #ffbe55;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn-danger {
      background-color: #ff3636;
      color: #fff; }
      .perfil.content .btn-danger.active:hover, .perfil.content .btn-danger:active:hover, .perfil.content .btn-danger:focus, .perfil.content .btn-danger:hover {
        background-color: #f55;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-danger:not(:disabled):not(.disabled).active, .perfil.content .btn-danger:not(:disabled):not(.disabled):active {
        background-color: #f55;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-danger:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-danger:not(:disabled):not(.disabled):active:focus {
          background-color: #f55;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-danger.dropdown-toggle {
      background-color: #f55;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-danger.dropdown-toggle:focus, .perfil.content .show > .btn-danger.dropdown-toggle:hover {
        background-color: #f55;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-danger:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-danger.disabled {
      background-color: #ff3636;
      border-color: #ff3636; }
      .perfil.content .btn-danger.disabled.active, .perfil.content .btn-danger.disabled.focus, .perfil.content .btn-danger.disabled:active, .perfil.content .btn-danger.disabled:focus, .perfil.content .btn-danger.disabled:hover {
        background-color: #ff3636;
        border-color: #ff3636; }
    .perfil.content .btn-danger:disabled {
      background-color: #ff3636;
      border-color: #ff3636; }
      .perfil.content .btn-danger:disabled.active, .perfil.content .btn-danger:disabled.focus, .perfil.content .btn-danger:disabled:active, .perfil.content .btn-danger:disabled:focus, .perfil.content .btn-danger:disabled:hover {
        background-color: #ff3636;
        border-color: #ff3636; }
    .perfil.content .btn-danger[disabled] {
      background-color: #ff3636;
      border-color: #ff3636; }
      .perfil.content .btn-danger[disabled].active, .perfil.content .btn-danger[disabled].focus, .perfil.content .btn-danger[disabled]:active, .perfil.content .btn-danger[disabled]:focus, .perfil.content .btn-danger[disabled]:hover {
        background-color: #ff3636;
        border-color: #ff3636; }
    .perfil.content fieldset[disabled] .btn-danger {
      background-color: #ff3636;
      border-color: #ff3636; }
      .perfil.content fieldset[disabled] .btn-danger.active, .perfil.content fieldset[disabled] .btn-danger.focus, .perfil.content fieldset[disabled] .btn-danger:active, .perfil.content fieldset[disabled] .btn-danger:focus, .perfil.content fieldset[disabled] .btn-danger:hover {
        background-color: #ff3636;
        border-color: #ff3636; }
    .perfil.content .btn-danger.btn-simple {
      color: #ff3636;
      border-color: #ff3636; }
      .perfil.content .btn-danger.btn-simple:active, .perfil.content .btn-danger.btn-simple:focus, .perfil.content .btn-danger.btn-simple:hover {
        background-color: transparent;
        color: #f55;
        border-color: #f55;
        box-shadow: none; }
    .perfil.content .btn-danger.btn-link {
      color: #ff3636; }
      .perfil.content .btn-danger.btn-link:active, .perfil.content .btn-danger.btn-link:focus, .perfil.content .btn-danger.btn-link:hover {
        background-color: transparent;
        color: #f55;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn-neutral {
      background-color: #fff;
      color: #337ab7; }
      .perfil.content .btn-neutral.active:hover, .perfil.content .btn-neutral:active:hover, .perfil.content .btn-neutral:focus, .perfil.content .btn-neutral:hover {
        background-color: #fff;
        color: #fff;
        box-shadow: none; }
      .perfil.content .btn-neutral:not(:disabled):not(.disabled).active, .perfil.content .btn-neutral:not(:disabled):not(.disabled):active {
        background-color: #fff;
        color: #fff;
        box-shadow: none; }
        .perfil.content .btn-neutral:not(:disabled):not(.disabled).active:focus, .perfil.content .btn-neutral:not(:disabled):not(.disabled):active:focus {
          background-color: #fff;
          color: #fff;
          box-shadow: none; }
    .perfil.content .show > .btn-neutral.dropdown-toggle {
      background-color: #fff;
      color: #fff;
      box-shadow: none; }
      .perfil.content .show > .btn-neutral.dropdown-toggle:focus, .perfil.content .show > .btn-neutral.dropdown-toggle:hover {
        background-color: #fff;
        color: #fff;
        box-shadow: none; }
    .perfil.content .btn-neutral:not([data-action]):hover {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17); }
    .perfil.content .btn-neutral.disabled {
      background-color: #fff;
      border-color: #fff; }
      .perfil.content .btn-neutral.disabled.active, .perfil.content .btn-neutral.disabled.focus, .perfil.content .btn-neutral.disabled:active, .perfil.content .btn-neutral.disabled:focus, .perfil.content .btn-neutral.disabled:hover {
        background-color: #fff;
        border-color: #fff; }
    .perfil.content .btn-neutral:disabled {
      background-color: #fff;
      border-color: #fff; }
      .perfil.content .btn-neutral:disabled.active, .perfil.content .btn-neutral:disabled.focus, .perfil.content .btn-neutral:disabled:active, .perfil.content .btn-neutral:disabled:focus, .perfil.content .btn-neutral:disabled:hover {
        background-color: #fff;
        border-color: #fff; }
    .perfil.content .btn-neutral[disabled] {
      background-color: #fff;
      border-color: #fff; }
      .perfil.content .btn-neutral[disabled].active, .perfil.content .btn-neutral[disabled].focus, .perfil.content .btn-neutral[disabled]:active, .perfil.content .btn-neutral[disabled]:focus, .perfil.content .btn-neutral[disabled]:hover {
        background-color: #fff;
        border-color: #fff; }
    .perfil.content fieldset[disabled] .btn-neutral {
      background-color: #fff;
      border-color: #fff; }
      .perfil.content fieldset[disabled] .btn-neutral.active, .perfil.content fieldset[disabled] .btn-neutral.focus, .perfil.content fieldset[disabled] .btn-neutral:active, .perfil.content fieldset[disabled] .btn-neutral:focus, .perfil.content fieldset[disabled] .btn-neutral:hover {
        background-color: #fff;
        border-color: #fff; }
    .perfil.content .btn-neutral.btn-danger {
      color: #ff3636; }
      .perfil.content .btn-neutral.btn-danger:active {
        color: #f55; }
        .perfil.content .btn-neutral.btn-danger:active:focus {
          color: #f55; }
      .perfil.content .btn-neutral.btn-danger:focus, .perfil.content .btn-neutral.btn-danger:hover {
        color: #f55; }
    .perfil.content .btn-neutral.btn-info {
      color: #2ca8ff; }
      .perfil.content .btn-neutral.btn-info:active {
        color: #4bb5ff; }
        .perfil.content .btn-neutral.btn-info:active:focus {
          color: #4bb5ff; }
      .perfil.content .btn-neutral.btn-info:focus, .perfil.content .btn-neutral.btn-info:hover {
        color: #4bb5ff; }
    .perfil.content .btn-neutral.btn-warning {
      color: #ffb236; }
      .perfil.content .btn-neutral.btn-warning:active {
        color: #ffbe55; }
        .perfil.content .btn-neutral.btn-warning:active:focus {
          color: #ffbe55; }
      .perfil.content .btn-neutral.btn-warning:focus, .perfil.content .btn-neutral.btn-warning:hover {
        color: #ffbe55; }
    .perfil.content .btn-neutral.btn-success {
      color: #18ce0f; }
      .perfil.content .btn-neutral.btn-success:active {
        color: #1beb11; }
        .perfil.content .btn-neutral.btn-success:active:focus {
          color: #1beb11; }
      .perfil.content .btn-neutral.btn-success:focus, .perfil.content .btn-neutral.btn-success:hover {
        color: #1beb11; }
    .perfil.content .btn-neutral.btn-default {
      color: #888; }
      .perfil.content .btn-neutral.btn-default:active {
        color: #979797; }
        .perfil.content .btn-neutral.btn-default:active:focus {
          color: #979797; }
      .perfil.content .btn-neutral.btn-default:focus, .perfil.content .btn-neutral.btn-default:hover {
        color: #979797; }
    .perfil.content .btn-neutral.active {
      background-color: #fff;
      color: #3e8fd6;
      box-shadow: none; }
      .perfil.content .btn-neutral.active:focus, .perfil.content .btn-neutral.active:hover {
        background-color: #fff;
        color: #3e8fd6;
        box-shadow: none; }
    .perfil.content .btn-neutral:active {
      background-color: #fff;
      color: #3e8fd6;
      box-shadow: none; }
      .perfil.content .btn-neutral:active:focus, .perfil.content .btn-neutral:active:hover {
        background-color: #fff;
        color: #3e8fd6;
        box-shadow: none; }
    .perfil.content .show > .btn-neutral.dropdown-toggle {
      background-color: #fff;
      color: #3e8fd6;
      box-shadow: none; }
      .perfil.content .show > .btn-neutral.dropdown-toggle:focus, .perfil.content .show > .btn-neutral.dropdown-toggle:hover {
        background-color: #fff;
        color: #3e8fd6;
        box-shadow: none; }
    .perfil.content .btn-neutral:focus, .perfil.content .btn-neutral:hover {
      color: #3e8fd6; }
    .perfil.content .btn-neutral:focus:not(.nav-link), .perfil.content .btn-neutral:hover:not(.nav-link) {
      box-shadow: none; }
    .perfil.content .btn-neutral.btn-simple {
      color: #fff;
      border-color: #fff; }
      .perfil.content .btn-neutral.btn-simple:active, .perfil.content .btn-neutral.btn-simple:focus, .perfil.content .btn-neutral.btn-simple:hover {
        background-color: transparent;
        color: #fff;
        border-color: #fff;
        box-shadow: none; }
    .perfil.content .btn-neutral.btn-link {
      color: #fff; }
      .perfil.content .btn-neutral.btn-link:active, .perfil.content .btn-neutral.btn-link:focus, .perfil.content .btn-neutral.btn-link:hover {
        background-color: transparent;
        color: #fff;
        text-decoration: none;
        box-shadow: none; }
    .perfil.content .btn.disabled, .perfil.content .btn:disabled, .perfil.content .btn[disabled] {
      opacity: .5;
      filter: alpha(opacity=50);
      pointer-events: none; }
    .perfil.content .btn-simple {
      border: 1px solid;
      border-color: #888;
      padding: 10px 22px; }
    .perfil.content .btn-link {
      background-color: transparent; }
      .perfil.content .btn-link.disabled {
        background-color: transparent; }
        .perfil.content .btn-link.disabled.active, .perfil.content .btn-link.disabled.focus, .perfil.content .btn-link.disabled:active, .perfil.content .btn-link.disabled:focus, .perfil.content .btn-link.disabled:hover {
          background-color: transparent; }
      .perfil.content .btn-link:disabled {
        background-color: transparent; }
        .perfil.content .btn-link:disabled.active, .perfil.content .btn-link:disabled.focus, .perfil.content .btn-link:disabled:active, .perfil.content .btn-link:disabled:focus, .perfil.content .btn-link:disabled:hover {
          background-color: transparent; }
      .perfil.content .btn-link[disabled] {
        background-color: transparent; }
        .perfil.content .btn-link[disabled].active, .perfil.content .btn-link[disabled].focus, .perfil.content .btn-link[disabled]:active, .perfil.content .btn-link[disabled]:focus, .perfil.content .btn-link[disabled]:hover {
          background-color: transparent; }
    .perfil.content .btn-simple {
      background-color: transparent; }
      .perfil.content .btn-simple.disabled {
        background-color: transparent; }
        .perfil.content .btn-simple.disabled.active, .perfil.content .btn-simple.disabled.focus, .perfil.content .btn-simple.disabled:active, .perfil.content .btn-simple.disabled:focus, .perfil.content .btn-simple.disabled:hover {
          background-color: transparent; }
      .perfil.content .btn-simple:disabled {
        background-color: transparent; }
        .perfil.content .btn-simple:disabled.active, .perfil.content .btn-simple:disabled.focus, .perfil.content .btn-simple:disabled:active, .perfil.content .btn-simple:disabled:focus, .perfil.content .btn-simple:disabled:hover {
          background-color: transparent; }
      .perfil.content .btn-simple[disabled] {
        background-color: transparent; }
        .perfil.content .btn-simple[disabled].active, .perfil.content .btn-simple[disabled].focus, .perfil.content .btn-simple[disabled]:active, .perfil.content .btn-simple[disabled]:focus, .perfil.content .btn-simple[disabled]:hover {
          background-color: transparent; }
    .perfil.content fieldset[disabled] .btn-link {
      background-color: transparent; }
      .perfil.content fieldset[disabled] .btn-link.active, .perfil.content fieldset[disabled] .btn-link.focus, .perfil.content fieldset[disabled] .btn-link:active, .perfil.content fieldset[disabled] .btn-link:focus, .perfil.content fieldset[disabled] .btn-link:hover {
        background-color: transparent; }
    .perfil.content fieldset[disabled] .btn-simple {
      background-color: transparent; }
      .perfil.content fieldset[disabled] .btn-simple.active, .perfil.content fieldset[disabled] .btn-simple.focus, .perfil.content fieldset[disabled] .btn-simple:active, .perfil.content fieldset[disabled] .btn-simple:focus, .perfil.content fieldset[disabled] .btn-simple:hover {
        background-color: transparent; }
    .perfil.content .btn-link {
      border: 0;
      padding: .5rem .7rem; }
    .perfil.content .btn-lg {
      font-size: 1em;
      border-radius: .25rem;
      padding: 15px 48px; }
      .perfil.content .btn-lg.btn-simple {
        padding: 14px 47px; }
    .perfil.content .btn-sm {
      font-size: 14px;
      border-radius: .1875rem;
      padding: 5px 15px; }
      .perfil.content .btn-sm.btn-simple {
        padding: 4px 14px; }
    .perfil.content .btn-wd {
      min-width: 140px; }
    .perfil.content .btn-group.select {
      width: 100%; }
      .perfil.content .btn-group.select .btn {
        text-align: left; }
      .perfil.content .btn-group.select .caret {
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 8px; }
    .perfil.content .btn-round {
      border-width: 1px;
      border-radius: 30px;
      padding-right: 23px;
      padding-left: 23px; }
      .perfil.content .btn-round.btn-simple {
        padding: 10px 22px; }
    .perfil.content .no-caret.dropdown-toggle:after {
      display: none; }
    .perfil.content .form-control {
      background-color: transparent;
      border: 1px solid #e3e3e3;
      border-radius: 30px;
      color: #2c2c2c;
      line-height: normal;
      font-size: .8571em;
      transition: color .3s ease-in-out, border-color .3s ease-in-out, background-color .3s ease-in-out;
      box-shadow: none; }
      .perfil.content .form-control:-moz-placeholder, .perfil.content .form-control::-moz-placeholder, .perfil.content .form-control::-webkit-input-placeholder, .perfil.content .form-control:-ms-input-placeholder {
        color: #888;
        opacity: 1;
        filter: alpha(opacity=100); }
      .perfil.content .form-control:focus {
        border: 1px solid #337ab7;
        box-shadow: none;
        outline: 0 !important;
        color: #2c2c2c; }
        .perfil.content .form-control:focus + .input-group-append .input-group-text, .perfil.content .form-control:focus + .input-group-prepend .input-group-text {
          border: 1px solid #337ab7;
          border-left: none;
          background-color: transparent; }
        .perfil.content .form-control:focus ~ .input-group-append .input-group-text, .perfil.content .form-control:focus ~ .input-group-prepend .input-group-text {
          border: 1px solid #337ab7;
          border-left: none;
          background-color: transparent; }
    .perfil.content .has-error .form-control, .perfil.content .has-success .form-control {
      box-shadow: none; }
      .perfil.content .has-error .form-control:focus, .perfil.content .has-success .form-control:focus {
        box-shadow: none; }
    .perfil.content .has-danger .form-control.form-control-danger, .perfil.content .has-danger .form-control.form-control-success {
      background-image: none; }
    .perfil.content .has-success .form-control.form-control-danger, .perfil.content .has-success .form-control.form-control-success {
      background-image: none; }
    .perfil.content .form-control + .form-control-feedback {
      border-radius: .25rem;
      font-size: 14px;
      margin-top: -7px;
      position: absolute;
      right: 10px;
      top: 50%;
      vertical-align: middle; }
    .perfil.content .open .form-control {
      border-radius: .25rem .25rem 0 0;
      border-bottom-color: transparent; }
    .perfil.content .form-control + .input-group-append .input-group-text, .perfil.content .form-control + .input-group-prepend .input-group-text {
      background-color: #fff; }
    .perfil.content .has-success .form-control, .perfil.content .has-success .input-group-append .input-group-text, .perfil.content .has-success .input-group-prepend .input-group-text {
      border-color: #e3e3e3; }
    .perfil.content .has-success .form-control:focus {
      border-color: #1be611; }
    .perfil.content .has-success.input-group-focus .input-group-append .input-group-text, .perfil.content .has-success.input-group-focus .input-group-prepend .input-group-text {
      border-color: #1be611; }
    .perfil.content .has-danger .form-control, .perfil.content .has-danger .input-group-append .input-group-text {
      border-color: #ffcfcf;
      color: #ff3636;
      background-color: rgba(222, 222, 222, 0.1); }
    .perfil.content .has-danger.input-group-focus .input-group-append .input-group-text, .perfil.content .has-danger.input-group-focus .input-group-prepend .input-group-text {
      border-color: #ffcfcf;
      color: #ff3636;
      background-color: rgba(222, 222, 222, 0.1); }
    .perfil.content .has-danger .input-group-prepend .input-group-text {
      border-color: #ffcfcf;
      color: #ff3636;
      background-color: rgba(222, 222, 222, 0.1); }
    .perfil.content .has-danger .form-control:focus, .perfil.content .has-danger .input-group-append .input-group-text:focus {
      background-color: #fff; }
    .perfil.content .has-danger.input-group-focus .input-group-append .input-group-text:focus, .perfil.content .has-danger.input-group-focus .input-group-prepend .input-group-text:focus {
      background-color: #fff; }
    .perfil.content .has-danger .input-group-prepend .input-group-text:focus {
      background-color: #fff; }
    .perfil.content .has-danger:after {
      font-family: Nucleo Outline;
      content: "\ea22";
      display: inline-block;
      position: absolute;
      right: 20px;
      bottom: 10px;
      color: #18ce0f;
      font-size: 11px; }
    .perfil.content .has-success:after {
      font-family: Nucleo Outline;
      content: "\ea22";
      display: inline-block;
      position: absolute;
      right: 20px;
      bottom: 10px;
      color: #18ce0f;
      font-size: 11px; }
    .perfil.content .has-danger.form-control-lg:after, .perfil.content .has-success.form-control-lg:after {
      font-size: 13px;
      top: 24px; }
    .perfil.content .has-danger.has-label:after, .perfil.content .has-success.has-label:after {
      top: 35px; }
    .perfil.content .has-danger.form-check .form-check-label label, .perfil.content .has-danger.form-check:after {
      display: none !important; }
    .perfil.content .has-danger .form-control + label {
      display: none !important; }
    .perfil.content .has-success.form-check .form-check-label label, .perfil.content .has-success.form-check:after {
      display: none !important; }
    .perfil.content .has-success .form-control + label {
      display: none !important; }
    .perfil.content .has-danger.form-check .form-check-label, .perfil.content .has-success.form-check .form-check-label {
      color: #18ce0f; }
    .perfil.content .has-danger:after {
      content: "\ea53";
      color: #ff3636; }
    .perfil.content .has-danger.form-check .form-check-label {
      color: #ff3636; }
    .perfil.content .form-group.no-border.form-control-lg .input-group-append .input-group-text, .perfil.content .input-group.no-border.form-control-lg .input-group-append .input-group-text {
      padding: 15px 0 15px 19px; }
    .perfil.content .form-group.no-border.form-control-lg .form-control, .perfil.content .input-group.no-border.form-control-lg .form-control {
      padding: 15px 19px; }
    .perfil.content .form-group.no-border.form-control-lg .form-control + .input-group-append .input-group-text, .perfil.content .form-group.no-border.form-control-lg .form-control + .input-group-prepend .input-group-text {
      padding: 15px 19px 15px 0; }
    .perfil.content .input-group.no-border.form-control-lg .form-control + .input-group-append .input-group-text, .perfil.content .input-group.no-border.form-control-lg .form-control + .input-group-prepend .input-group-text {
      padding: 15px 19px 15px 0; }
    .perfil.content .form-group.form-control-lg .form-control, .perfil.content .input-group.form-control-lg .form-control {
      padding: 14px 18px; }
    .perfil.content .form-group.form-control-lg .form-control + .input-group-append .input-group-text, .perfil.content .form-group.form-control-lg .form-control + .input-group-prepend .input-group-text {
      padding: 14px 18px 14px 0; }
    .perfil.content .input-group.form-control-lg .form-control + .input-group-append .input-group-text, .perfil.content .input-group.form-control-lg .form-control + .input-group-prepend .input-group-text {
      padding: 14px 18px 14px 0; }
    .perfil.content .form-group.form-control-lg .input-group-append .input-group-text, .perfil.content .form-group.form-control-lg .input-group-prepend .input-group-text {
      padding: 14px 0 15px 18px; }
    .perfil.content .input-group.form-control-lg .input-group-append .input-group-text, .perfil.content .input-group.form-control-lg .input-group-prepend .input-group-text {
      padding: 14px 0 15px 18px; }
    .perfil.content .form-group.form-control-lg .input-group-append .input-group-text + .form-control, .perfil.content .form-group.form-control-lg .input-group-prepend .input-group-text + .form-control {
      padding: 15px 18px 15px 16px; }
    .perfil.content .input-group.form-control-lg .input-group-append .input-group-text + .form-control, .perfil.content .input-group.form-control-lg .input-group-prepend .input-group-text + .form-control {
      padding: 15px 18px 15px 16px; }
    .perfil.content .form-group.no-border .form-control, .perfil.content .input-group.no-border .form-control {
      padding: 11px 19px; }
    .perfil.content .form-group.no-border .form-control + .input-group-append .input-group-text, .perfil.content .form-group.no-border .form-control + .input-group-prepend .input-group-text {
      padding: 11px 19px 11px 0; }
    .perfil.content .input-group.no-border .form-control + .input-group-append .input-group-text, .perfil.content .input-group.no-border .form-control + .input-group-prepend .input-group-text {
      padding: 11px 19px 11px 0; }
    .perfil.content .form-group.no-border .input-group-append .input-group-text, .perfil.content .form-group.no-border .input-group-prepend .input-group-text {
      padding: 11px 0 11px 19px; }
    .perfil.content .input-group.no-border .input-group-append .input-group-text, .perfil.content .input-group.no-border .input-group-prepend .input-group-text {
      padding: 11px 0 11px 19px; }
    .perfil.content .form-group .form-control, .perfil.content .input-group .form-control {
      padding: 10px 18px; }
    .perfil.content .form-group .form-control + .input-group-append .input-group-text, .perfil.content .form-group .form-control + .input-group-prepend .input-group-text {
      padding: 10px 18px 10px 0; }
    .perfil.content .input-group .form-control + .input-group-append .input-group-text, .perfil.content .input-group .form-control + .input-group-prepend .input-group-text {
      padding: 10px 18px 10px 0; }
    .perfil.content .form-group .input-group-append .input-group-text, .perfil.content .form-group .input-group-prepend .input-group-text {
      padding: 10px 0 10px 18px; }
    .perfil.content .input-group .input-group-append .input-group-text, .perfil.content .input-group .input-group-prepend .input-group-text {
      padding: 10px 0 10px 18px; }
    .perfil.content .form-group .input-group-append .input-group-text + .form-control, .perfil.content .form-group .input-group-append .input-group-text ~ .form-control {
      padding: 10px 19px 11px 16px; }
    .perfil.content .form-group .input-group-prepend .input-group-text + .form-control, .perfil.content .form-group .input-group-prepend .input-group-text ~ .form-control {
      padding: 10px 19px 11px 16px; }
    .perfil.content .input-group .input-group-append .input-group-text + .form-control, .perfil.content .input-group .input-group-append .input-group-text ~ .form-control {
      padding: 10px 19px 11px 16px; }
    .perfil.content .input-group .input-group-prepend .input-group-text + .form-control, .perfil.content .input-group .input-group-prepend .input-group-text ~ .form-control {
      padding: 10px 19px 11px 16px; }
    .perfil.content .form-group.no-border .form-control {
      background-color: rgba(222, 222, 222, 0.3);
      border: medium none; }
      .perfil.content .form-group.no-border .form-control + .input-group-append .input-group-text, .perfil.content .form-group.no-border .form-control + .input-group-prepend .input-group-text {
        background-color: rgba(222, 222, 222, 0.3);
        border: medium none; }
    .perfil.content .input-group.no-border .form-control {
      background-color: rgba(222, 222, 222, 0.3);
      border: medium none; }
      .perfil.content .input-group.no-border .form-control + .input-group-append .input-group-text, .perfil.content .input-group.no-border .form-control + .input-group-prepend .input-group-text {
        background-color: rgba(222, 222, 222, 0.3);
        border: medium none; }
    .perfil.content .form-group.no-border .form-control + .input-group-append .input-group-text:active, .perfil.content .form-group.no-border .form-control + .input-group-append .input-group-text:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .form-group.no-border .form-control + .input-group-prepend .input-group-text:active, .perfil.content .form-group.no-border .form-control + .input-group-prepend .input-group-text:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .form-group.no-border .form-control:active, .perfil.content .form-group.no-border .form-control:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .input-group.no-border .form-control + .input-group-append .input-group-text:active, .perfil.content .input-group.no-border .form-control + .input-group-append .input-group-text:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .input-group.no-border .form-control + .input-group-prepend .input-group-text:active, .perfil.content .input-group.no-border .form-control + .input-group-prepend .input-group-text:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .input-group.no-border .form-control:active, .perfil.content .input-group.no-border .form-control:focus {
      border: medium none;
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .form-group.no-border .form-control:focus + .input-group-append .input-group-text, .perfil.content .form-group.no-border .form-control:focus + .input-group-prepend .input-group-text {
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .input-group.no-border .form-control:focus + .input-group-append .input-group-text, .perfil.content .input-group.no-border .form-control:focus + .input-group-prepend .input-group-text {
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .form-group.no-border .input-group-append .input-group-text, .perfil.content .form-group.no-border .input-group-prepend .input-group-text {
      background-color: rgba(222, 222, 222, 0.3);
      border: none; }
    .perfil.content .input-group.no-border .input-group-append .input-group-text, .perfil.content .input-group.no-border .input-group-prepend .input-group-text {
      background-color: rgba(222, 222, 222, 0.3);
      border: none; }
    .perfil.content .has-error .control-label, .perfil.content .has-error .form-control-feedback {
      color: #ff3636; }
    .perfil.content .has-success .control-label, .perfil.content .has-success .form-control-feedback {
      color: #18ce0f; }
    .perfil.content .input-group-append .input-group-text, .perfil.content .input-group-prepend .input-group-text {
      background-color: transparent;
      border: 1px solid #e3e3e3;
      border-radius: 30px;
      color: #888;
      transition: color .3s ease-in-out, border-color .3s ease-in-out, background-color .3s ease-in-out; }
    .perfil.content .input-group-append .input-group-text i, .perfil.content .input-group-prepend .input-group-text i {
      opacity: .5; }
    .perfil.content .has-danger.input-group-focus .input-group-append .input-group-text, .perfil.content .has-danger.input-group-focus .input-group-prepend .input-group-text {
      background-color: #fff; }
    .perfil.content .has-success .input-group-append .input-group-text, .perfil.content .has-success .input-group-prepend .input-group-text {
      background-color: #fff; }
    .perfil.content .has-danger .form-control:focus + .input-group-append .input-group-text, .perfil.content .has-danger .form-control:focus + .input-group-prepend .input-group-text {
      color: #ff3636; }
    .perfil.content .has-success .form-control:focus + .input-group-append .input-group-text, .perfil.content .has-success .form-control:focus + .input-group-prepend .input-group-text {
      color: #18ce0f; }
    .perfil.content .input-group-append .input-group-text + .form-control, .perfil.content .input-group-append .input-group-text ~ .form-control {
      padding: -.5rem .7rem;
      padding-left: 18px; }
    .perfil.content .input-group-prepend .input-group-text + .form-control, .perfil.content .input-group-prepend .input-group-text ~ .form-control {
      padding: -.5rem .7rem;
      padding-left: 18px; }
    .perfil.content .input-group-append .input-group-text i, .perfil.content .input-group-prepend .input-group-text i {
      width: 17px; }
    .perfil.content .input-group-append, .perfil.content .input-group-prepend {
      margin: 0; }
    .perfil.content .input-group-append .input-group-text {
      border-left: none; }
    .perfil.content .input-group-prepend .input-group-text {
      border-right: none; }
    .perfil.content .input-group-focus .input-group-append .input-group-text, .perfil.content .input-group-focus .input-group-prepend .input-group-text {
      background-color: #fff;
      border-color: #337ab7; }
    .perfil.content .input-group-focus.no-border .input-group-append .input-group-text, .perfil.content .input-group-focus.no-border .input-group-prepend .input-group-text {
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .form-group, .perfil.content .input-group {
      margin-bottom: 20px;
      position: relative; }
    .perfil.content .form-group .form-control-static {
      margin-top: 9px; }
    .perfil.content .input-group .form-control-static {
      margin-top: 9px; }
    .perfil.content .input-group[disabled] .input-group-append .input-group-text, .perfil.content .input-group[disabled] .input-group-prepend .input-group-text {
      background-color: #e3e3e3; }
    .perfil.content .input-group-btn:not(:first-child):not(:last-child), .perfil.content .input-group .form-control:not(:first-child):not(:last-child) {
      border-radius: 30px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0 none; }
    .perfil.content .input-group-btn:first-child > .dropdown-toggle, .perfil.content .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
      border-right: 0 none; }
    .perfil.content .input-group .form-control:first-child {
      border-right: 0 none; }
    .perfil.content .input-group-btn:first-child > .btn:not(:first-child), .perfil.content .input-group-btn:last-child > .dropdown-toggle {
      border-left: 0 none; }
    .perfil.content .input-group .form-control:last-child {
      border-left: 0 none; }
    .perfil.content .form-control[disabled], .perfil.content .form-control[readonly] {
      background-color: #e3e3e3;
      color: #888;
      cursor: not-allowed; }
    .perfil.content fieldset[disabled] .form-control {
      background-color: #e3e3e3;
      color: #888;
      cursor: not-allowed; }
    .perfil.content .input-group-btn .btn {
      border-width: 1px;
      padding: 11px .7rem; }
    .perfil.content .input-group-btn .btn-default:not(.btn-fill) {
      border-color: #ddd; }
    .perfil.content .input-group-btn:last-child > .btn {
      margin-left: 0; }
    .perfil.content textarea.form-control {
      max-width: 100%;
      max-height: 80px;
      padding: 10px 10px 0 0;
      resize: none;
      border: none;
      border-bottom: 1px solid #e3e3e3;
      border-radius: 0;
      line-height: 2; }
      .perfil.content textarea.form-control:active, .perfil.content textarea.form-control:focus {
        border-left: none;
        border-top: none;
        border-right: none; }
    .perfil.content .has-danger.form-group .form-control, .perfil.content .has-danger.form-group.no-border .form-control {
      padding-right: 40px; }
    .perfil.content .has-success.form-group .form-control, .perfil.content .has-success.form-group.no-border .form-control {
      padding-right: 40px; }
    .perfil.content .form.form-newsletter .form-group {
      float: left;
      width: 78%;
      margin-right: 2%;
      margin-top: 9px; }
    .perfil.content .input-group .input-group-btn {
      padding: 0 12px; }
    .perfil.content .form-group input[type=file] {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100; }
    .perfil.content .form-text {
      font-size: .8571em; }
    .perfil.content .form-control-lg {
      padding: 0;
      font-size: inherit;
      line-height: 0;
      border-radius: 0; }
    .perfil.content .form-horizontal .col-form-label, .perfil.content .form-horizontal .label-on-right {
      padding: 10px 5px 0 15px;
      text-align: right;
      max-width: 180px; }
    .perfil.content .form-horizontal .checkbox-radios {
      margin-bottom: 15px; }
      .perfil.content .form-horizontal .checkbox-radios .form-check:first-child {
        margin-top: 8px; }
    .perfil.content .form-horizontal .label-on-right {
      text-align: left;
      padding: 10px 15px 0 5px; }
    .perfil.content .form-horizontal .form-check-inline {
      margin-top: 6px; }
    .perfil.content button, .perfil.content input, .perfil.content optgroup, .perfil.content select, .perfil.content textarea {
      font-family: Montserrat, Helvetica Neue, Arial, sans-serif; }
    .perfil.content h1, .perfil.content h2, .perfil.content h3, .perfil.content h4, .perfil.content h5, .perfil.content h6 {
      font-weight: 400; }
    .perfil.content a:focus, .perfil.content a:hover {
      color: #337ab7; }
    .perfil.content .h1, .perfil.content h1 {
      font-size: 3.5em;
      line-height: 1.15;
      margin-bottom: 30px; }
    .perfil.content .h1 small, .perfil.content h1 small {
      font-weight: 700;
      text-transform: uppercase;
      opacity: .8; }
    .perfil.content .h2, .perfil.content h2 {
      font-size: 2.5em; }
    .perfil.content .h2, .perfil.content .h3, .perfil.content h2, .perfil.content h3 {
      margin-bottom: 30px; }
    .perfil.content .h3, .perfil.content h3 {
      font-size: 2em;
      line-height: 1.4em; }
    .perfil.content .h4, .perfil.content h4 {
      font-size: 1.714em;
      line-height: 1.45em;
      margin-top: 30px;
      margin-bottom: 15px; }
    .perfil.content .h4 + .category, .perfil.content .h4.title + .category {
      margin-top: -10px; }
    .perfil.content h4 + .category, .perfil.content h4.title + .category {
      margin-top: -10px; }
    .perfil.content .h5, .perfil.content h5 {
      font-size: 1.57em;
      line-height: 1.4em;
      margin-bottom: 15px; }
    .perfil.content .h6, .perfil.content h6 {
      font-size: 1em;
      font-weight: 700;
      text-transform: uppercase; }
    .perfil.content p.description {
      font-size: 1.14em; }
    .perfil.content .title {
      font-weight: 700; }
      .perfil.content .title.title-up {
        text-transform: uppercase; }
        .perfil.content .title.title-up a {
          color: #2c2c2c;
          text-decoration: none; }
      .perfil.content .title + .category {
        margin-top: -10px; }
    .perfil.content .card-description, .perfil.content .card .footer .stats, .perfil.content .description, .perfil.content .footer-big p {
      color: #9a9a9a;
      font-weight: 300; }
    .perfil.content .card-category, .perfil.content .category {
      text-transform: capitalize;
      font-weight: 400;
      color: #9a9a9a;
      font-size: .7142em; }
    .perfil.content .card-category {
      font-size: 1em; }
    .perfil.content .text-primary {
      color: #337ab7 !important; }
    .perfil.content a.text-primary:focus, .perfil.content a.text-primary:hover {
      color: #337ab7 !important; }
    .perfil.content .text-info {
      color: #2ca8ff !important; }
    .perfil.content a.text-info:focus, .perfil.content a.text-info:hover {
      color: #2ca8ff !important; }
    .perfil.content .text-success {
      color: #18ce0f !important; }
    .perfil.content a.text-success:focus, .perfil.content a.text-success:hover {
      color: #18ce0f !important; }
    .perfil.content .text-warning {
      color: #ffb236 !important; }
    .perfil.content a.text-warning:focus, .perfil.content a.text-warning:hover {
      color: #ffb236 !important; }
    .perfil.content .text-danger {
      color: #ff3636 !important; }
    .perfil.content a.text-danger:focus, .perfil.content a.text-danger:hover {
      color: #ff3636 !important; }
    .perfil.content .text-gray {
      color: #e3e3e3 !important; }
    .perfil.content a.text-gray:focus, .perfil.content a.text-gray:hover {
      color: #e3e3e3 !important; }
    .perfil.content .blockquote {
      border-left: none;
      border: 1px solid #888;
      padding: 20px;
      font-size: 1.1em;
      line-height: 1.8; }
      .perfil.content .blockquote small {
        color: #888;
        font-size: .8571em;
        text-transform: uppercase; }
      .perfil.content .blockquote.blockquote-primary {
        border-color: #337ab7;
        color: #337ab7; }
        .perfil.content .blockquote.blockquote-primary small {
          color: #337ab7; }
      .perfil.content .blockquote.blockquote-danger {
        border-color: #ff3636;
        color: #ff3636; }
        .perfil.content .blockquote.blockquote-danger small {
          color: #ff3636; }
      .perfil.content .blockquote.blockquote-white {
        border-color: rgba(255, 255, 255, 0.8);
        color: #fff; }
        .perfil.content .blockquote.blockquote-white small {
          color: rgba(255, 255, 255, 0.8); }
    .perfil.content body {
      color: #2c2c2c;
      font-size: 14px;
      font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased; }
    .perfil.content .main {
      position: relative;
      background: #fff; }
    .perfil.content .animation-transition-general, .perfil.content .nav-pills .nav-link, .perfil.content .nav-tabs .nav-link, .perfil.content .navbar, .perfil.content .navbar-collapse .navbar-nav .nav-link {
      transition: all .3s ease 0s; }
    .perfil.content .off-canvas-sidebar .logo a.logo-mini, .perfil.content .off-canvas-sidebar .logo a.logo-normal {
      transition: all .3s ease 0s; }
    .perfil.content .off-canvas-sidebar .navbar-minimize, .perfil.content .off-canvas-sidebar .nav p {
      transition: all .3s ease 0s; }
    .perfil.content .sidebar .logo a.logo-mini, .perfil.content .sidebar .logo a.logo-normal {
      transition: all .3s ease 0s; }
    .perfil.content .sidebar .nav a {
      transition: all .3s ease 0s; }
      .perfil.content .sidebar .nav a i {
        transition: all .3s ease 0s; }
    .perfil.content .sidebar .navbar-minimize, .perfil.content .sidebar .nav p {
      transition: all .3s ease 0s; }
    .perfil.content .tag {
      transition: all .3s ease 0s; }
      .perfil.content .tag [data-role=remove] {
        transition: all .3s ease 0s; }
    .perfil.content .bootstrap-switch-label:before, .perfil.content .caret, .perfil.content .dropdown-toggle:after {
      transition: all .15s ease 0s; }
    .perfil.content .card-collapse .card a[data-toggle=collapse].expanded i, .perfil.content .card-collapse .card a[data-toggle=collapse][aria-expanded=true] i {
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
      transform: rotate(180deg); }
    .perfil.content .dropdown-toggle[aria-expanded=true]:after, .perfil.content a[data-toggle=collapse][aria-expanded=true] .caret {
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
      transform: rotate(180deg); }
    .perfil.content .button-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #fff; }
      .perfil.content .button-bar + .button-bar {
        margin-top: 7px; }
      .perfil.content .button-bar:nth-child(2) {
        width: 17px; }
    .perfil.content .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid \9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent; }
    .perfil.content .pull-left {
      float: left; }
    .perfil.content .pull-right {
      float: right; }
    .perfil.content .form-check {
      margin-top: .5rem; }
      .perfil.content .form-check .form-check-label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding-left: 35px;
        line-height: 26px;
        margin-bottom: 0;
        transition: color .3s linear; }
    .perfil.content .radio .form-check-sign {
      padding-left: 28px; }
    .perfil.content .form-check .form-check-sign:after, .perfil.content .form-check .form-check-sign:before {
      content: " ";
      display: inline-block;
      position: absolute;
      width: 26px;
      height: 26px;
      left: 0;
      cursor: pointer;
      border-radius: 3px;
      top: 0;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      transition: opacity .3s linear; }
    .perfil.content .form-check .form-check-sign:after {
      font-family: Nucleo Outline;
      content: "\ea22";
      top: 0;
      text-align: center;
      font-size: 14px;
      opacity: 0;
      color: #555;
      border: 0;
      background-color: inherit; }
    .perfil.content .form-check.disabled .form-check-label {
      color: #9a9a9a;
      opacity: .5;
      cursor: not-allowed; }
    .perfil.content .form-check input[type=checkbox] {
      opacity: 0;
      position: absolute;
      visibility: hidden; }
    .perfil.content .radio input[type=radio] {
      opacity: 0;
      position: absolute;
      visibility: hidden; }
    .perfil.content .form-check input[type=checkbox]:checked + .form-check-sign:after {
      opacity: 1; }
    .perfil.content .checkbox input[type=checkbox]:disabled + .form-check-sign:after, .perfil.content .form-control input[type=checkbox]:disabled + .form-check-sign:before {
      cursor: not-allowed; }
    .perfil.content .form-check input[type=checkbox]:disabled + .form-check-sign, .perfil.content .form-check input[type=radio]:disabled + .form-check-sign {
      pointer-events: none; }
    .perfil.content .form-check-radio .form-check-sign:after, .perfil.content .form-check-radio .form-check-sign:before {
      content: " ";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #e3e3e3;
      display: inline-block;
      position: absolute;
      left: 3px;
      top: 3px;
      padding: 1px;
      transition: opacity .3s linear; }
    .perfil.content .form-check-radio input[type=radio] {
      opacity: 0; }
      .perfil.content .form-check-radio input[type=radio] + .form-check-sign:after {
        opacity: 0; }
      .perfil.content .form-check-radio input[type=radio]:checked + .form-check-sign:after {
        width: 4px;
        height: 4px;
        background-color: #555;
        border-color: #555;
        top: 11px;
        left: 11px;
        opacity: 1; }
      .perfil.content .form-check-radio input[type=radio]:disabled + .form-check-sign {
        color: #9a9a9a; }
        .perfil.content .form-check-radio input[type=radio]:disabled + .form-check-sign:after, .perfil.content .form-check-radio input[type=radio]:disabled + .form-check-sign:before {
          color: #9a9a9a; }
    .perfil.content .navbar {
      padding-top: .625rem;
      padding-bottom: .625rem;
      min-height: 53px;
      margin-bottom: 20px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }
      .perfil.content .navbar a {
        vertical-align: middle; }
        .perfil.content .navbar a:not(.btn):not(.dropdown-item) {
          color: #fff; }
      .perfil.content .navbar.bg-white .input-group .form-control, .perfil.content .navbar.bg-white .input-group.no-border .form-control {
        color: #888; }
      .perfil.content .navbar a.dropdown-item {
        color: #888; }
      .perfil.content .navbar.bg-white .input-group .form-control::-moz-placeholder, .perfil.content .navbar.bg-white .input-group.no-border .form-control::-moz-placeholder, .perfil.content .navbar.bg-white .input-group .form-control:-ms-input-placeholder, .perfil.content .navbar.bg-white .input-group.no-border .form-control:-ms-input-placeholder, .perfil.content .navbar.bg-white .input-group .form-control::-webkit-input-placeholder, .perfil.content .navbar.bg-white .input-group.no-border .form-control::-webkit-input-placeholder {
        color: #888; }
      .perfil.content .navbar.bg-white .input-group-append .input-group-text i, .perfil.content .navbar.bg-white .input-group-prepend .input-group-text i {
        color: #888;
        opacity: .5; }
      .perfil.content .navbar .form-group, .perfil.content .navbar .input-group {
        margin: 0;
        margin-left: -3px;
        margin-right: 5px; }
      .perfil.content .navbar .form-group .form-group-addon, .perfil.content .navbar .form-group .input-group-append .input-group-text, .perfil.content .navbar .form-group .input-group-prepend .input-group-text {
        color: #fff; }
      .perfil.content .navbar .input-group .form-group-addon, .perfil.content .navbar .input-group .input-group-append .input-group-text, .perfil.content .navbar .input-group .input-group-prepend .input-group-text {
        color: #fff; }
      .perfil.content .navbar .form-group .form-group-addon i, .perfil.content .navbar .form-group .input-group-append .input-group-text i, .perfil.content .navbar .form-group .input-group-prepend .input-group-text i {
        opacity: 1; }
      .perfil.content .navbar .input-group .form-group-addon i, .perfil.content .navbar .input-group .input-group-append .input-group-text i, .perfil.content .navbar .input-group .input-group-prepend .input-group-text i {
        opacity: 1; }
      .perfil.content .navbar .form-group.no-border .form-control, .perfil.content .navbar .input-group.no-border .form-control, .perfil.content .navbar .form-group.no-border .form-control::-moz-placeholder, .perfil.content .navbar .input-group.no-border .form-control::-moz-placeholder, .perfil.content .navbar .form-group.no-border .form-control:-ms-input-placeholder, .perfil.content .navbar .input-group.no-border .form-control:-ms-input-placeholder, .perfil.content .navbar .form-group.no-border .form-control::-webkit-input-placeholder, .perfil.content .navbar .input-group.no-border .form-control::-webkit-input-placeholder {
        color: #fff; }
      .perfil.content .navbar p {
        display: inline-block;
        margin: 0;
        line-height: 1.8em;
        font-size: 1em;
        font-weight: 400; }
      .perfil.content .navbar.navbar-absolute {
        position: absolute;
        width: 100%;
        padding-top: 10px;
        z-index: 1029; }
    .perfil.content .documentation .navbar.fixed-top {
      left: 0;
      width: auto; }
    .perfil.content .navbar .navbar-wrapper {
      display: inline-flex;
      align-items: center; }
      .perfil.content .navbar .navbar-wrapper .navbar-minimize {
        padding-right: 10px; }
        .perfil.content .navbar .navbar-wrapper .navbar-minimize .btn {
          margin: 0; }
      .perfil.content .navbar .navbar-wrapper .navbar-toggle .navbar-toggler {
        padding-left: 0; }
      .perfil.content .navbar .navbar-wrapper .navbar-toggle:hover .navbar-toggler-bar.bar2 {
        width: 22px; }
    .perfil.content .navbar .navbar-nav.navbar-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 49px;
      top: -4px; }
    .perfil.content .navbar .navbar-nav .nav-link {
      text-transform: uppercase;
      font-size: .7142em;
      padding: .5rem .7rem;
      line-height: 1.625rem;
      margin-right: 3px; }
      .perfil.content .navbar .navbar-nav .nav-link.btn {
        padding: 11px 22px; }
        .perfil.content .navbar .navbar-nav .nav-link.btn.btn-lg {
          padding: 15px 48px; }
        .perfil.content .navbar .navbar-nav .nav-link.btn.btn-sm {
          padding: 5px 15px; }
      .perfil.content .navbar .navbar-nav .nav-link i.fa + p, .perfil.content .navbar .navbar-nav .nav-link i.now-ui-icons + p {
        margin-left: 3px; }
      .perfil.content .navbar .navbar-nav .nav-link i.fa {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px; }
      .perfil.content .navbar .navbar-nav .nav-link i.now-ui-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
        top: 4px;
        font-size: 16px; }
      .perfil.content .navbar .navbar-nav .nav-link.profile-photo .profile-photo-small {
        width: 27px;
        height: 27px; }
      .perfil.content .navbar .navbar-nav .nav-link.disabled {
        opacity: .5;
        color: #fff; }
    .perfil.content .navbar .navbar-nav .nav-item.active .nav-link:not(.btn) {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: .1875rem;
      color: #fff; }
    .perfil.content .navbar .navbar-nav .nav-item .nav-link:not(.btn):active, .perfil.content .navbar .navbar-nav .nav-item .nav-link:not(.btn):focus, .perfil.content .navbar .navbar-nav .nav-item .nav-link:not(.btn):hover {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: .1875rem;
      color: #fff; }
    .perfil.content .navbar .logo-container {
      width: 27px;
      height: 27px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 50%;
      border: 1px solid transparent; }
    .perfil.content .navbar .navbar-brand {
      text-transform: uppercase;
      font-size: .8571em;
      padding-top: .5rem;
      padding-bottom: .5rem;
      line-height: 1.625rem; }
    .perfil.content .navbar .navbar-toggler {
      width: 37px;
      height: 27px;
      vertical-align: middle;
      outline: 0;
      cursor: pointer; }
      .perfil.content .navbar .navbar-toggler .navbar-toggler-bar.navbar-kebab {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: 0 auto; }
    .perfil.content .navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
      width: 17px; }
    .perfil.content .navbar.navbar-transparent {
      background-color: transparent !important;
      box-shadow: none;
      color: #fff; }
    .perfil.content .navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
      color: #888; }
      .perfil.content .navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn).disabled {
        opacity: .5;
        color: #888; }
    .perfil.content .navbar.bg-white:not(.navbar-transparent) .button-bar {
      background: #888; }
    .perfil.content .navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn) {
      background-color: rgba(222, 222, 222, 0.8);
      color: #888; }
    .perfil.content .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active, .perfil.content .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus, .perfil.content .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover {
      background-color: rgba(222, 222, 222, 0.8);
      color: #888; }
    .perfil.content .navbar.bg-white:not(.navbar-transparent) .logo-container {
      border: 1px solid #888; }
    .perfil.content .bg-default {
      background-color: #888 !important; }
    .perfil.content .bg-primary {
      background-color: #337ab7 !important; }
    .perfil.content .bg-info {
      background-color: #2ca8ff !important; }
    .perfil.content .bg-success {
      background-color: #18ce0f !important; }
    .perfil.content .bg-danger {
      background-color: #ff3636 !important; }
    .perfil.content .bg-warning {
      background-color: #ffb236 !important; }
    .perfil.content .bg-white {
      background-color: #fff !important; }
    .perfil.content .page-header {
      min-height: 100vh;
      max-height: 999px;
      padding: 0;
      color: #fff;
      position: relative; }
      .perfil.content .page-header .page-header-image {
        position: absolute;
        background-size: cover;
        background-position: 50%;
        width: 100%;
        height: 100%;
        z-index: -1; }
      .perfil.content .page-header .content-center {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        padding: 0 15px;
        width: 100%;
        max-width: 880px; }
      .perfil.content .page-header footer {
        position: absolute;
        bottom: 0;
        width: 100%; }
      .perfil.content .page-header .container {
        height: 100%;
        z-index: 1; }
      .perfil.content .page-header .category, .perfil.content .page-header .description {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .page-header.page-header-small {
        min-height: 60vh;
        max-height: 440px; }
      .perfil.content .page-header.page-header-mini {
        min-height: 40vh;
        max-height: 340px; }
      .perfil.content .page-header .title {
        margin-bottom: 15px; }
        .perfil.content .page-header .title + h4 {
          margin-top: 10px; }
      .perfil.content .page-header:after {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: ""; }
      .perfil.content .page-header:before {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.3); }
      .perfil.content .page-header[filter-color=orange] {
        background: rgba(44, 44, 44, 0.2);
        background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6)); }
    .perfil.content .dropdown-menu {
      border: 0;
      box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
      border-radius: .125rem;
      transition: all .15s linear;
      font-size: 14px; }
      .perfil.content .dropdown-menu.dropdown-menu-right:before {
        left: auto;
        right: 10px; }
      .perfil.content .dropdown-menu i {
        margin-right: 5px;
        position: relative;
        top: 1px; }
      .perfil.content .dropdown-menu .now-ui-icons {
        margin-right: 10px;
        position: relative;
        top: 4px;
        font-size: 18px;
        margin-top: -5px;
        opacity: .5; }
      .perfil.content .dropdown-menu .dropdown-item.active, .perfil.content .dropdown-menu .dropdown-item:active {
        color: inherit; }
    .perfil.content .dropup .dropdown-menu:before {
      display: none; }
    .perfil.content .dropup .dropdown-menu:after {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: auto;
      bottom: -5px;
      right: auto;
      left: 10px;
      color: #fff;
      border-top: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent; }
    .perfil.content .dropup .dropdown-menu.dropdown-menu-right:after {
      right: 10px;
      left: auto; }
    .perfil.content .dropdown-menu:before {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: -5px;
      left: 10px;
      right: auto;
      color: #fff;
      border-bottom: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent; }
    .perfil.content .dropdown-menu.dropdown-menu-right {
      right: 0 !important;
      left: auto !important; }
    .perfil.content .bootstrap-select .dropdown-menu.inner li a, .perfil.content .dropdown-menu .dropdown-item {
      font-size: .8571em;
      padding-top: .6rem;
      padding-bottom: .6rem;
      margin-top: 5px;
      transition: all .15s linear; }
    .perfil.content .bootstrap-select .dropdown-menu.inner li a:focus, .perfil.content .bootstrap-select .dropdown-menu.inner li a:hover {
      background-color: rgba(222, 222, 222, 0.3); }
    .perfil.content .dropdown-menu .dropdown-item:focus, .perfil.content .dropdown-menu .dropdown-item:hover {
      background-color: rgba(222, 222, 222, 0.3); }
    .perfil.content .bootstrap-select .dropdown-menu.inner li a.disabled, .perfil.content .bootstrap-select .dropdown-menu.inner li a:disabled {
      color: rgba(181, 181, 181, 0.6); }
    .perfil.content .dropdown-menu .dropdown-item.disabled, .perfil.content .dropdown-menu .dropdown-item:disabled {
      color: rgba(181, 181, 181, 0.6); }
    .perfil.content .bootstrap-select .dropdown-menu.inner li a.disabled:focus, .perfil.content .bootstrap-select .dropdown-menu.inner li a.disabled:hover {
      background-color: transparent; }
    .perfil.content .bootstrap-select .dropdown-menu.inner li a:disabled:focus, .perfil.content .bootstrap-select .dropdown-menu.inner li a:disabled:hover {
      background-color: transparent; }
    .perfil.content .dropdown-menu .dropdown-item.disabled:focus, .perfil.content .dropdown-menu .dropdown-item.disabled:hover {
      background-color: transparent; }
    .perfil.content .dropdown-menu .dropdown-item:disabled:focus, .perfil.content .dropdown-menu .dropdown-item:disabled:hover {
      background-color: transparent; }
    .perfil.content .dropdown-menu .dropdown-divider {
      background-color: rgba(222, 222, 222, 0.5); }
    .perfil.content .dropdown-menu .dropdown-header:not([href]):not([tabindex]) {
      color: rgba(181, 181, 181, 0.6);
      font-size: .7142em;
      text-transform: uppercase;
      font-weight: 700; }
    .perfil.content .dropdown-menu.dropdown-primary {
      background-color: #f95823; }
      .perfil.content .dropdown-menu.dropdown-primary:before {
        color: #f95823; }
      .perfil.content .dropdown-menu.dropdown-primary .dropdown-header:not([href]):not([tabindex]) {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .dropdown-menu.dropdown-primary .dropdown-item {
        color: #fff; }
      .perfil.content .dropdown-menu.dropdown-primary .dropdown-divider {
        background-color: rgba(255, 255, 255, 0.2); }
      .perfil.content .dropdown-menu.dropdown-primary .dropdown-item:focus, .perfil.content .dropdown-menu.dropdown-primary .dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .perfil.content .dropdown-menu.dropdown-info {
      background-color: #1da2ff; }
      .perfil.content .dropdown-menu.dropdown-info:before {
        color: #1da2ff; }
      .perfil.content .dropdown-menu.dropdown-info .dropdown-header:not([href]):not([tabindex]) {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .dropdown-menu.dropdown-info .dropdown-item {
        color: #fff; }
      .perfil.content .dropdown-menu.dropdown-info .dropdown-divider {
        background-color: rgba(255, 255, 255, 0.2); }
      .perfil.content .dropdown-menu.dropdown-info .dropdown-item:focus, .perfil.content .dropdown-menu.dropdown-info .dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .perfil.content .dropdown-menu.dropdown-danger {
      background-color: #ff2727; }
      .perfil.content .dropdown-menu.dropdown-danger:before {
        color: #ff2727; }
      .perfil.content .dropdown-menu.dropdown-danger .dropdown-header:not([href]):not([tabindex]) {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .dropdown-menu.dropdown-danger .dropdown-item {
        color: #fff; }
      .perfil.content .dropdown-menu.dropdown-danger .dropdown-divider {
        background-color: rgba(255, 255, 255, 0.2); }
      .perfil.content .dropdown-menu.dropdown-danger .dropdown-item:focus, .perfil.content .dropdown-menu.dropdown-danger .dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .perfil.content .dropdown-menu.dropdown-success {
      background-color: #16c00e; }
      .perfil.content .dropdown-menu.dropdown-success:before {
        color: #16c00e; }
      .perfil.content .dropdown-menu.dropdown-success .dropdown-header:not([href]):not([tabindex]) {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .dropdown-menu.dropdown-success .dropdown-item {
        color: #fff; }
      .perfil.content .dropdown-menu.dropdown-success .dropdown-divider {
        background-color: rgba(255, 255, 255, 0.2); }
      .perfil.content .dropdown-menu.dropdown-success .dropdown-item:focus, .perfil.content .dropdown-menu.dropdown-success .dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .perfil.content .dropdown-menu.dropdown-warning {
      background-color: #ffac27; }
      .perfil.content .dropdown-menu.dropdown-warning:before {
        color: #ffac27; }
      .perfil.content .dropdown-menu.dropdown-warning .dropdown-header:not([href]):not([tabindex]) {
        color: rgba(255, 255, 255, 0.8); }
      .perfil.content .dropdown-menu.dropdown-warning .dropdown-item {
        color: #fff; }
      .perfil.content .dropdown-menu.dropdown-warning .dropdown-divider {
        background-color: rgba(255, 255, 255, 0.2); }
      .perfil.content .dropdown-menu.dropdown-warning .dropdown-item:focus, .perfil.content .dropdown-menu.dropdown-warning .dropdown-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .perfil.content .bootstrap-select .dropdown-menu:not(.inner) {
      transform: translate3d(0, -20px, 0) !important;
      visibility: hidden;
      display: block;
      opacity: 0;
      filter: alpha(opacity=0);
      top: 100% !important; }
    .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.bottom, .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.top {
      transform: translate3d(0, -20px, 0) !important;
      visibility: hidden;
      display: block;
      opacity: 0;
      filter: alpha(opacity=0);
      top: 100% !important; }
    .perfil.content .dropdown .dropdown-menu, .perfil.content .dropup:not(.bootstrap-select) .dropdown-menu {
      transform: translate3d(0, -20px, 0) !important;
      visibility: hidden;
      display: block;
      opacity: 0;
      filter: alpha(opacity=0);
      top: 100% !important; }
    .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.bottom, .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.top {
      transform: translate3d(0, -20px, 0) !important; }
    .perfil.content .bootstrap-select.dropup .dropdown-menu:not(.inner) {
      transform: translate3d(0, 25px, 0) !important; }
    .perfil.content .dropup:not(.bootstrap-select) .dropdown-menu {
      transform: translate3d(0, 20px, 0) !important;
      top: auto !important;
      bottom: 100%; }
    .perfil.content .bootstrap-select.show .dropdown-menu:not(.inner) {
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible;
      transform: translate3d(0, 1px, 0) !important; }
    .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.bottom.open, .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.top.open {
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible;
      transform: translate3d(0, 1px, 0) !important; }
    .perfil.content .dropdown.show .dropdown-menu, .perfil.content .dropup.show:not(.bootstrap-select) .dropdown-menu, .perfil.content .navbar .dropdown.show .dropdown-menu {
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible;
      transform: translate3d(0, 1px, 0) !important; }
    .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.bottom.open, .perfil.content .dropdown-menu.bootstrap-datetimepicker-widget.top.open {
      transform: translateZ(0) !important; }
    .perfil.content .dropup.show:not(.bootstrap-select) .dropdown-menu {
      transform: translate3d(0, -2px, 0) !important; }
    .perfil.content .button-dropdown {
      padding-right: .7rem;
      cursor: pointer; }
      .perfil.content .button-dropdown .dropdown-toggle {
        padding-top: .5rem;
        padding-bottom: .5rem;
        display: block; }
        .perfil.content .button-dropdown .dropdown-toggle:after {
          display: none; }
    .perfil.content img {
      max-width: 100%;
      border-radius: .1875rem; }
    .perfil.content .img-raised {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3); }

@font-face {
  .perfil.content {
    font-family: Nucleo Outline;
    src: url(../fonts/nucleo-outline.eot);
    src: url(../fonts/nucleo-outline.eot) format("embedded-opentype"), url(../fonts/nucleo-outline.woff2) format("woff2"), url(../fonts/nucleo-outline.woff) format("woff"), url(../fonts/nucleo-outline.ttf) format("truetype");
    font-weight: 400;
    font-style: normal; } }
    .perfil.content .now-ui-icons {
      display: inline-block;
      font: normal normal normal 14px/1 Nucleo Outline;
      font-size: inherit;
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .perfil.content .now-ui-icons.circle {
        padding: .33333333em;
        vertical-align: -16%;
        background-color: #eee;
        border-radius: 50%; }
    .perfil.content .nc-icon-ul {
      padding-left: 0;
      margin-left: 2.14285714em;
      list-style-type: none; }
      .perfil.content .nc-icon-ul > li {
        position: relative; }
        .perfil.content .nc-icon-ul > li > .now-ui-icons {
          position: absolute;
          left: -1.57142857em;
          top: .14285714em;
          text-align: center; }
          .perfil.content .nc-icon-ul > li > .now-ui-icons.circle {
            top: -.19047619em;
            left: -1.9047619em; }
    .perfil.content .now-ui-icons.spin {
      animation: f 2s infinite linear; }
    .perfil.content .now-ui-icons.ui-1_check:before {
      content: "\ea22"; }
    .perfil.content .now-ui-icons.ui-1_email-85:before {
      content: "\ea2a"; }
    .perfil.content .now-ui-icons.arrows-1_cloud-download-93:before {
      content: "\ea21"; }
    .perfil.content .now-ui-icons.arrows-1_cloud-upload-94:before {
      content: "\ea24"; }
    .perfil.content .now-ui-icons.arrows-1_minimal-down:before {
      content: "\ea39"; }
    .perfil.content .now-ui-icons.arrows-1_minimal-left:before {
      content: "\ea3a"; }
    .perfil.content .now-ui-icons.arrows-1_minimal-right:before {
      content: "\ea3b"; }
    .perfil.content .now-ui-icons.arrows-1_minimal-up:before {
      content: "\ea3c"; }
    .perfil.content .now-ui-icons.arrows-1_refresh-69:before {
      content: "\ea44"; }
    .perfil.content .now-ui-icons.arrows-1_share-66:before {
      content: "\ea4c"; }
    .perfil.content .now-ui-icons.business_badge:before {
      content: "\ea09"; }
    .perfil.content .now-ui-icons.business_bank:before {
      content: "\ea0a"; }
    .perfil.content .now-ui-icons.business_briefcase-24:before {
      content: "\ea13"; }
    .perfil.content .now-ui-icons.business_bulb-63:before {
      content: "\ea15"; }
    .perfil.content .now-ui-icons.business_chart-bar-32:before {
      content: "\ea1e"; }
    .perfil.content .now-ui-icons.business_chart-pie-36:before {
      content: "\ea1f"; }
    .perfil.content .now-ui-icons.business_globe:before {
      content: "\ea2f"; }
    .perfil.content .now-ui-icons.business_money-coins:before {
      content: "\ea40"; }
    .perfil.content .now-ui-icons.clothes_tie-bow:before {
      content: "\ea5b"; }
    .perfil.content .now-ui-icons.design_vector:before {
      content: "\ea61"; }
    .perfil.content .now-ui-icons.design_app:before {
      content: "\ea08"; }
    .perfil.content .now-ui-icons.design_bullet-list-67:before {
      content: "\ea14"; }
    .perfil.content .now-ui-icons.design_image:before {
      content: "\ea33"; }
    .perfil.content .now-ui-icons.design_palette:before {
      content: "\ea41"; }
    .perfil.content .now-ui-icons.design_scissors:before {
      content: "\ea4a"; }
    .perfil.content .now-ui-icons.design-2_html5:before {
      content: "\ea32"; }
    .perfil.content .now-ui-icons.design-2_ruler-pencil:before {
      content: "\ea48"; }
    .perfil.content .now-ui-icons.emoticons_satisfied:before {
      content: "\ea49"; }
    .perfil.content .now-ui-icons.files_box:before {
      content: "\ea12"; }
    .perfil.content .now-ui-icons.files_paper:before {
      content: "\ea43"; }
    .perfil.content .now-ui-icons.files_single-copy-04:before {
      content: "\ea52"; }
    .perfil.content .now-ui-icons.health_ambulance:before {
      content: "\ea07"; }
    .perfil.content .now-ui-icons.loader_gear:before {
      content: "\ea4e"; }
    .perfil.content .now-ui-icons.loader_refresh:before {
      content: "\ea44"; }
    .perfil.content .now-ui-icons.location_bookmark:before {
      content: "\ea10"; }
    .perfil.content .now-ui-icons.location_compass-05:before {
      content: "\ea25"; }
    .perfil.content .now-ui-icons.location_map-big:before {
      content: "\ea3d"; }
    .perfil.content .now-ui-icons.location_pin:before {
      content: "\ea47"; }
    .perfil.content .now-ui-icons.location_world:before {
      content: "\ea63"; }
    .perfil.content .now-ui-icons.media-1_album:before {
      content: "\ea02"; }
    .perfil.content .now-ui-icons.media-1_button-pause:before {
      content: "\ea16"; }
    .perfil.content .now-ui-icons.media-1_button-play:before {
      content: "\ea18"; }
    .perfil.content .now-ui-icons.media-1_button-power:before {
      content: "\ea19"; }
    .perfil.content .now-ui-icons.media-1_camera-compact:before {
      content: "\ea1c"; }
    .perfil.content .now-ui-icons.media-2_note-03:before {
      content: "\ea3f"; }
    .perfil.content .now-ui-icons.media-2_sound-wave:before {
      content: "\ea57"; }
    .perfil.content .now-ui-icons.objects_diamond:before {
      content: "\ea29"; }
    .perfil.content .now-ui-icons.objects_globe:before {
      content: "\ea2f"; }
    .perfil.content .now-ui-icons.objects_key-25:before {
      content: "\ea38"; }
    .perfil.content .now-ui-icons.objects_planet:before {
      content: "\ea46"; }
    .perfil.content .now-ui-icons.objects_spaceship:before {
      content: "\ea55"; }
    .perfil.content .now-ui-icons.objects_support-17:before {
      content: "\ea56"; }
    .perfil.content .now-ui-icons.objects_umbrella-13:before {
      content: "\ea5f"; }
    .perfil.content .now-ui-icons.education_agenda-bookmark:before {
      content: "\ea01"; }
    .perfil.content .now-ui-icons.education_atom:before {
      content: "\ea0c"; }
    .perfil.content .now-ui-icons.education_glasses:before {
      content: "\ea2d"; }
    .perfil.content .now-ui-icons.education_hat:before {
      content: "\ea30"; }
    .perfil.content .now-ui-icons.education_paper:before {
      content: "\ea42"; }
    .perfil.content .now-ui-icons.shopping_bag-16:before {
      content: "\ea0d"; }
    .perfil.content .now-ui-icons.shopping_basket:before {
      content: "\ea0b"; }
    .perfil.content .now-ui-icons.shopping_box:before {
      content: "\ea11"; }
    .perfil.content .now-ui-icons.shopping_cart-simple:before {
      content: "\ea1d"; }
    .perfil.content .now-ui-icons.shopping_credit-card:before {
      content: "\ea28"; }
    .perfil.content .now-ui-icons.shopping_delivery-fast:before {
      content: "\ea27"; }
    .perfil.content .now-ui-icons.shopping_shop:before {
      content: "\ea50"; }
    .perfil.content .now-ui-icons.shopping_tag-content:before {
      content: "\ea59"; }
    .perfil.content .now-ui-icons.sport_trophy:before {
      content: "\ea5d"; }
    .perfil.content .now-ui-icons.sport_user-run:before {
      content: "\ea60"; }
    .perfil.content .now-ui-icons.tech_controller-modern:before {
      content: "\ea26"; }
    .perfil.content .now-ui-icons.tech_headphones:before {
      content: "\ea31"; }
    .perfil.content .now-ui-icons.tech_laptop:before {
      content: "\ea36"; }
    .perfil.content .now-ui-icons.tech_mobile:before {
      content: "\ea3e"; }
    .perfil.content .now-ui-icons.tech_tablet:before {
      content: "\ea58"; }
    .perfil.content .now-ui-icons.tech_tv:before {
      content: "\ea5e"; }
    .perfil.content .now-ui-icons.tech_watch-time:before {
      content: "\ea62"; }
    .perfil.content .now-ui-icons.text_align-center:before {
      content: "\ea05"; }
    .perfil.content .now-ui-icons.text_align-left:before {
      content: "\ea06"; }
    .perfil.content .now-ui-icons.text_bold:before {
      content: "\ea0e"; }
    .perfil.content .now-ui-icons.text_caps-small:before {
      content: "\ea1b"; }
    .perfil.content .now-ui-icons.gestures_tap-01:before {
      content: "\ea5a"; }
    .perfil.content .now-ui-icons.transportation_air-baloon:before {
      content: "\ea03"; }
    .perfil.content .now-ui-icons.transportation_bus-front-12:before {
      content: "\ea17"; }
    .perfil.content .now-ui-icons.travel_info:before {
      content: "\ea04"; }
    .perfil.content .now-ui-icons.travel_istanbul:before {
      content: "\ea34"; }
    .perfil.content .now-ui-icons.ui-1_bell-53:before {
      content: "\ea0f"; }
    .perfil.content .now-ui-icons.ui-1_calendar-60:before {
      content: "\ea1a"; }
    .perfil.content .now-ui-icons.ui-1_lock-circle-open:before {
      content: "\ea35"; }
    .perfil.content .now-ui-icons.ui-1_send:before {
      content: "\ea4d"; }
    .perfil.content .now-ui-icons.ui-1_settings-gear-63:before {
      content: "\ea4e"; }
    .perfil.content .now-ui-icons.ui-1_simple-add:before {
      content: "\ea4f"; }
    .perfil.content .now-ui-icons.ui-1_simple-delete:before {
      content: "\ea54"; }
    .perfil.content .now-ui-icons.ui-1_simple-remove:before {
      content: "\ea53"; }
    .perfil.content .now-ui-icons.ui-1_zoom-bold:before {
      content: "\ea64"; }
    .perfil.content .now-ui-icons.ui-2_chat-round:before {
      content: "\ea20"; }
    .perfil.content .now-ui-icons.ui-2_favourite-28:before {
      content: "\ea2b"; }
    .perfil.content .now-ui-icons.ui-2_like:before {
      content: "\ea37"; }
    .perfil.content .now-ui-icons.ui-2_settings-90:before {
      content: "\ea4b"; }
    .perfil.content .now-ui-icons.ui-2_time-alarm:before {
      content: "\ea5c"; }
    .perfil.content .now-ui-icons.users_circle-08:before {
      content: "\ea23"; }
    .perfil.content .now-ui-icons.users_single-02:before {
      content: "\ea51"; }

@keyframes f {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }
    .perfil.content .all-icons .font-icon-detail {
      text-align: center;
      padding: 45px 0 30px;
      border: 1px solid #e5e5e5;
      border-radius: .1875rem;
      margin: 15px 0;
      min-height: 168px; }
    .perfil.content .all-icons [class*=now-ui-icons] {
      font-size: 32px; }
    .perfil.content .all-icons .font-icon-detail p {
      margin: 25px auto 0;
      width: 100%;
      text-align: center;
      display: block;
      color: #b8b8b8;
      padding: 0 10px;
      font-size: .7142em; }
    .perfil.content .table .img-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto; }
    .perfil.content .table .img-row {
      max-width: 60px;
      width: 60px; }
    .perfil.content .table .form-check {
      margin: 0; }
      .perfil.content .table .form-check label .form-check-sign:after, .perfil.content .table .form-check label .form-check-sign:before {
        top: -17px;
        left: 4px; }
    .perfil.content .table .btn {
      margin: 0; }
    .perfil.content .table .small, .perfil.content .table small {
      font-weight: 300; }
    .perfil.content .card-tasks .card-body .table {
      margin-bottom: 0; }
      .perfil.content .card-tasks .card-body .table > tbody > tr > td, .perfil.content .card-tasks .card-body .table > tbody > tr > th {
        padding-top: 0;
        padding-bottom: 0; }
      .perfil.content .card-tasks .card-body .table > tfoot > tr > td, .perfil.content .card-tasks .card-body .table > tfoot > tr > th {
        padding-top: 0;
        padding-bottom: 0; }
      .perfil.content .card-tasks .card-body .table > thead > tr > td, .perfil.content .card-tasks .card-body .table > thead > tr > th {
        padding-top: 0;
        padding-bottom: 0; }
    .perfil.content .table > thead > tr > th {
      border-bottom-width: 1px;
      font-size: 1.45em;
      font-weight: 300;
      border: 0; }
    .perfil.content .table .checkbox, .perfil.content .table .radio {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      width: 15px; }
    .perfil.content .table .checkbox .icons, .perfil.content .table .radio .icons {
      position: relative; }
    .perfil.content .table .checkbox label:after, .perfil.content .table .checkbox label:before {
      top: -17px;
      left: -3px; }
    .perfil.content .table .radio label:after, .perfil.content .table .radio label:before {
      top: -17px;
      left: -3px; }
    .perfil.content .table > tbody > tr > td, .perfil.content .table > tbody > tr > th {
      padding: 12px 7px;
      vertical-align: middle; }
    .perfil.content .table > tfoot > tr > td, .perfil.content .table > tfoot > tr > th {
      padding: 12px 7px;
      vertical-align: middle; }
    .perfil.content .table > thead > tr > td, .perfil.content .table > thead > tr > th {
      padding: 12px 7px;
      vertical-align: middle; }
    .perfil.content .table .th-description {
      max-width: 150px; }
    .perfil.content .table .td-price {
      font-size: 26px;
      font-weight: 300;
      margin-top: 5px;
      position: relative;
      top: 4px;
      text-align: right; }
    .perfil.content .table .td-total {
      font-weight: 700;
      font-size: 1.57em;
      padding-top: 20px;
      text-align: right; }
    .perfil.content .table .td-actions .btn {
      margin: 0; }
    .perfil.content .table > tbody > tr {
      position: relative; }
    .perfil.content .table-shopping > thead > tr > th {
      font-size: 1em;
      text-transform: uppercase; }
    .perfil.content .table-shopping > tbody > tr > td {
      font-size: 1em; }
      .perfil.content .table-shopping > tbody > tr > td b {
        display: block;
        margin-bottom: 5px; }
    .perfil.content .table-shopping .td-name {
      font-weight: 400;
      font-size: 1.5em; }
      .perfil.content .table-shopping .td-name small {
        color: #9a9a9a;
        font-size: .75em;
        font-weight: 300; }
    .perfil.content .table-shopping .td-number {
      font-weight: 300;
      font-size: 1.714em; }
    .perfil.content .table-shopping .td-name {
      min-width: 200px; }
    .perfil.content .table-shopping .td-number {
      text-align: right;
      min-width: 170px; }
      .perfil.content .table-shopping .td-number small {
        margin-right: 3px; }
    .perfil.content .table-shopping .img-container {
      width: 120px;
      max-height: 160px;
      overflow: hidden;
      display: block; }
      .perfil.content .table-shopping .img-container img {
        width: 100%; }
    .perfil.content .table-responsive {
      overflow: scroll;
      padding-bottom: 10px; }
    .perfil.content #tables .table-responsive {
      margin-bottom: 30px; }
    .perfil.content .wrapper {
      position: relative;
      top: 0;
      height: 100vh; }
      .perfil.content .wrapper.wrapper-full-page {
        min-height: 100vh;
        height: auto; }
    .perfil.content .off-canvas-sidebar, .perfil.content .sidebar {
      position: fixed;
      top: 0;
      height: 100%;
      bottom: 0;
      width: 260px;
      left: 0;
      z-index: 1030; }
    .perfil.content .off-canvas-sidebar .sidebar-wrapper, .perfil.content .sidebar .sidebar-wrapper {
      position: relative;
      height: calc(100vh - 75px);
      overflow: auto;
      width: 260px;
      z-index: 4;
      padding-bottom: 100px; }
    .perfil.content .off-canvas-sidebar .sidebar-wrapper .dropdown .dropdown-backdrop, .perfil.content .sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
      display: none !important; }
    .perfil.content .off-canvas-sidebar .sidebar-wrapper .navbar-form, .perfil.content .sidebar .sidebar-wrapper .navbar-form {
      border: none; }
    .perfil.content .off-canvas-sidebar .navbar-minimize, .perfil.content .sidebar .navbar-minimize {
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1; }
    .perfil.content .off-canvas-sidebar .nav, .perfil.content .sidebar .nav {
      margin-top: 20px;
      display: block; }
    .perfil.content .off-canvas-sidebar .nav .caret, .perfil.content .sidebar .nav .caret {
      top: 14px;
      position: absolute;
      right: 10px; }
    .perfil.content .off-canvas-sidebar .nav li > a + div .nav li > a, .perfil.content .sidebar .nav li > a + div .nav li > a {
      margin-top: 7px; }
    .perfil.content .off-canvas-sidebar .nav li > a, .perfil.content .sidebar .nav li > a {
      margin: 10px 15px 0;
      border-radius: 30px;
      color: #fff;
      display: block;
      text-decoration: none;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      font-size: .7142em;
      padding: 10px 8px;
      line-height: 1.625rem; }
    .perfil.content .off-canvas-sidebar .nav li:first-child > a, .perfil.content .sidebar .nav li:first-child > a {
      margin: 0 15px; }
    .perfil.content .off-canvas-sidebar .nav li:focus:not(.active) > a, .perfil.content .off-canvas-sidebar .nav li:hover:not(.active) > a {
      background-color: rgba(255, 255, 255, 0.1); }
    .perfil.content .sidebar .nav li:focus:not(.active) > a, .perfil.content .sidebar .nav li:hover:not(.active) > a {
      background-color: rgba(255, 255, 255, 0.1); }
    .perfil.content .off-canvas-sidebar .nav li:focus:not(.active) > a i, .perfil.content .off-canvas-sidebar .nav li:hover:not(.active) > a i {
      color: #fff; }
    .perfil.content .sidebar .nav li:focus:not(.active) > a i, .perfil.content .sidebar .nav li:hover:not(.active) > a i {
      color: #fff; }
    .perfil.content .off-canvas-sidebar .nav li.active > a, .perfil.content .sidebar .nav li.active > a {
      background-color: #fff;
      box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1); }
    .perfil.content .off-canvas-sidebar .nav p, .perfil.content .sidebar .nav p {
      margin: 0;
      line-height: 30px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap; }
    .perfil.content .off-canvas-sidebar .nav i, .perfil.content .sidebar .nav i {
      font-size: 20px;
      float: left;
      margin-right: 12px;
      line-height: 30px;
      width: 34px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      position: relative; }
    .perfil.content .off-canvas-sidebar .sidebar-background, .perfil.content .sidebar .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: 50%; }
    .perfil.content .off-canvas-sidebar .sidebar-background:after, .perfil.content .sidebar .sidebar-background:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background: #fff;
      opacity: 1; }
    .perfil.content .off-canvas-sidebar .logo, .perfil.content .sidebar .logo {
      position: relative;
      padding: .5rem .7rem;
      z-index: 4; }
    .perfil.content .off-canvas-sidebar .logo a.logo-mini, .perfil.content .sidebar .logo a.logo-mini {
      opacity: 1;
      float: left;
      width: 34px;
      text-align: center;
      margin-left: 10px;
      margin-right: 12px; }
    .perfil.content .off-canvas-sidebar .logo a.logo-normal, .perfil.content .sidebar .logo a.logo-normal {
      display: block;
      opacity: 1;
      transform: translateZ(0); }
    .perfil.content .off-canvas-sidebar .logo:after, .perfil.content .sidebar .logo:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background-color: rgba(255, 255, 255, 0.5); }
    .perfil.content .off-canvas-sidebar .logo p, .perfil.content .sidebar .logo p {
      float: left;
      font-size: 20px;
      margin: 10px;
      color: #fff;
      line-height: 20px;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif; }
    .perfil.content .off-canvas-sidebar .logo .simple-text, .perfil.content .sidebar .logo .simple-text {
      text-transform: uppercase;
      padding: .5rem 0;
      display: block;
      white-space: nowrap;
      font-size: 1em;
      color: #fff;
      text-decoration: none;
      font-weight: 400;
      line-height: 30px;
      overflow: hidden; }
    .perfil.content .off-canvas-sidebar .logo-tim, .perfil.content .sidebar .logo-tim {
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden; }
    .perfil.content .off-canvas-sidebar .logo-tim img, .perfil.content .sidebar .logo-tim img {
      width: 60px;
      height: 60px; }
    .perfil.content .off-canvas-sidebar:after, .perfil.content .off-canvas-sidebar:before {
      display: block;
      content: "";
      opacity: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .perfil.content .sidebar:after, .perfil.content .sidebar:before {
      display: block;
      content: "";
      opacity: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .perfil.content .off-canvas-sidebar:after, .perfil.content .sidebar:after {
      background: #888;
      background: linear-gradient(#888, #000 80%);
      z-index: 3; }
    .perfil.content .off-canvas-sidebar[data-color=blue]:after, .perfil.content .sidebar[data-color=blue]:after {
      background: #2ca8ff; }
    .perfil.content .off-canvas-sidebar[data-color=blue] .nav li.active > a, .perfil.content .sidebar[data-color=blue] .nav li.active > a {
      color: #2ca8ff; }
      .perfil.content .off-canvas-sidebar[data-color=blue] .nav li.active > a i, .perfil.content .sidebar[data-color=blue] .nav li.active > a i {
        color: #2ca8ff; }
    .perfil.content .off-canvas-sidebar[data-color=green]:after, .perfil.content .sidebar[data-color=green]:after {
      background: #18ce0f; }
    .perfil.content .off-canvas-sidebar[data-color=green] .nav li.active > a, .perfil.content .sidebar[data-color=green] .nav li.active > a {
      color: #18ce0f; }
      .perfil.content .off-canvas-sidebar[data-color=green] .nav li.active > a i, .perfil.content .sidebar[data-color=green] .nav li.active > a i {
        color: #18ce0f; }
    .perfil.content .off-canvas-sidebar[data-color=orange]:after, .perfil.content .sidebar[data-color=orange]:after {
      background: #337ab7; }
    .perfil.content .off-canvas-sidebar[data-color=orange] .nav li.active > a, .perfil.content .sidebar[data-color=orange] .nav li.active > a {
      color: #337ab7; }
      .perfil.content .off-canvas-sidebar[data-color=orange] .nav li.active > a i, .perfil.content .sidebar[data-color=orange] .nav li.active > a i {
        color: #337ab7; }
    .perfil.content .off-canvas-sidebar[data-color=red]:after, .perfil.content .sidebar[data-color=red]:after {
      background: #ff3636; }
    .perfil.content .off-canvas-sidebar[data-color=red] .nav li.active > a, .perfil.content .sidebar[data-color=red] .nav li.active > a {
      color: #ff3636; }
      .perfil.content .off-canvas-sidebar[data-color=red] .nav li.active > a i, .perfil.content .sidebar[data-color=red] .nav li.active > a i {
        color: #ff3636; }
    .perfil.content .off-canvas-sidebar[data-color=yellow]:after, .perfil.content .sidebar[data-color=yellow]:after {
      background: #ffb236; }
    .perfil.content .off-canvas-sidebar[data-color=yellow] .nav li.active > a, .perfil.content .sidebar[data-color=yellow] .nav li.active > a {
      color: #ffb236; }
      .perfil.content .off-canvas-sidebar[data-color=yellow] .nav li.active > a i, .perfil.content .sidebar[data-color=yellow] .nav li.active > a i {
        color: #ffb236; }
    .perfil.content .visible-on-sidebar-regular {
      display: inline-block !important; }
    .perfil.content .visible-on-sidebar-mini {
      display: none !important; }
    .perfil.content .off-canvas-sidebar .nav > li > a {
      color: #fff; }
      .perfil.content .off-canvas-sidebar .nav > li > a:hover {
        color: #fff; }
      .perfil.content .off-canvas-sidebar .nav > li > a:focus {
        background: rgba(199, 199, 199, 0.2); }
    .perfil.content .main-panel {
      position: relative;
      float: right;
      width: calc(100% - 260px);
      background-color: #e3e3e3;
      background-color: #ebecf1;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); }
      .perfil.content .main-panel > .content {
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px; }
      .perfil.content .main-panel > .navbar {
        margin-bottom: 0; }
      .perfil.content .main-panel .header {
        margin-bottom: 50px; }
    .perfil.content .perfect-scrollbar-on .main-panel, .perfil.content .perfect-scrollbar-on .sidebar {
      height: 100%;
      max-height: 100%; }
    @media (min-width: 991px) {
      .perfil.content .sidebar {
        display: block;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2), 0 2px 30px 0 rgba(0, 0, 0, 0.35); } }
    .perfil.content .panel-header {
      height: 260px;
      padding-top: 80px;
      padding-bottom: 45px;
      background: #141e30;
      background: linear-gradient(90deg, #0c2646 0, #204065 60%, #2a5788);
      position: relative;
      overflow: hidden; }
      .perfil.content .panel-header .header .title {
        color: #fff; }
      .perfil.content .panel-header .header .category {
        max-width: 600px;
        color: rgba(255, 255, 255, 0.5);
        margin: 0 auto;
        font-size: 13px; }
        .perfil.content .panel-header .header .category a {
          color: #fff; }
    .perfil.content .panel-header-sm {
      height: 135px; }
    .perfil.content .panel-header-lg {
      height: 380px; }
    .perfil.content .footer {
      padding: 24px 0; }
      .perfil.content .footer.footer-default {
        background-color: #f2f2f2; }
      .perfil.content .footer nav {
        display: inline-block;
        float: left;
        padding-left: 7px; }
      .perfil.content .footer ul {
        margin-bottom: 0;
        padding: 0;
        list-style: none; }
        .perfil.content .footer ul li {
          display: inline-block; }
          .perfil.content .footer ul li a {
            color: inherit;
            padding: .5rem;
            font-size: .8571em;
            text-transform: uppercase;
            text-decoration: none; }
            .perfil.content .footer ul li a:hover {
              text-decoration: none; }
      .perfil.content .footer .copyright {
        font-size: .8571em;
        line-height: 1.8; }
      .perfil.content .footer:after {
        display: table;
        clear: both;
        content: " "; }
    .perfil.content .fixed-plugin {
      position: fixed;
      right: 0;
      width: 64px;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1031;
      border-radius: 8px 0 0 8px;
      text-align: center;
      top: 120px; }
      .perfil.content .fixed-plugin .badge, .perfil.content .fixed-plugin li > a {
        transition: all .34s;
        -webkit-transition: all .34s;
        -moz-transition: all .34s; }
      .perfil.content .fixed-plugin .fa-cog {
        color: #fff;
        padding: 10px;
        border-radius: 0 0 6px 6px;
        width: auto; }
      .perfil.content .fixed-plugin .dropdown-menu {
        right: 80px;
        left: auto !important;
        top: -52px !important;
        width: 290px;
        border-radius: .1875rem;
        padding: 0 10px; }
      .perfil.content .fixed-plugin .dropdown .dropdown-menu .now-ui-icons {
        top: 5px; }
      .perfil.content .fixed-plugin .dropdown-menu:after, .perfil.content .fixed-plugin .dropdown-menu:before {
        right: 10px;
        margin-left: auto;
        left: auto; }
      .perfil.content .fixed-plugin .fa-circle-thin {
        color: #fff; }
      .perfil.content .fixed-plugin .active .fa-circle-thin {
        color: #0bf; }
      .perfil.content .fixed-plugin .dropdown-menu > .active > a {
        color: #777;
        text-align: center; }
        .perfil.content .fixed-plugin .dropdown-menu > .active > a:focus, .perfil.content .fixed-plugin .dropdown-menu > .active > a:hover {
          color: #777;
          text-align: center; }
      .perfil.content .fixed-plugin img {
        border-radius: 0;
        width: 100%;
        height: 100px;
        margin: 0 auto; }
      .perfil.content .fixed-plugin .dropdown-menu li > a:focus, .perfil.content .fixed-plugin .dropdown-menu li > a:hover {
        box-shadow: none; }
      .perfil.content .fixed-plugin .badge {
        border: 3px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 23px;
        margin-right: 5px;
        position: relative;
        width: 23px; }
        .perfil.content .fixed-plugin .badge.active, .perfil.content .fixed-plugin .badge:hover {
          border-color: #0bf; }
      .perfil.content .fixed-plugin .badge-blue {
        background-color: #2ca8ff; }
      .perfil.content .fixed-plugin .badge-green {
        background-color: #18ce0f; }
      .perfil.content .fixed-plugin .badge-orange {
        background-color: #337ab7; }
      .perfil.content .fixed-plugin .badge-yellow {
        background-color: #ffb236; }
      .perfil.content .fixed-plugin .badge-red {
        background-color: #ff3636; }
      .perfil.content .fixed-plugin h5 {
        font-size: 14px;
        margin: 10px; }
      .perfil.content .fixed-plugin .dropdown-menu li {
        display: block;
        padding: 18px 2px;
        width: 25%;
        float: left; }
      .perfil.content .fixed-plugin li.adjustments-line, .perfil.content .fixed-plugin li.button-container, .perfil.content .fixed-plugin li.header-title {
        width: 100%;
        height: 50px;
        min-height: inherit; }
      .perfil.content .fixed-plugin li.button-container {
        height: auto; }
        .perfil.content .fixed-plugin li.button-container div {
          margin-bottom: 5px; }
      .perfil.content .fixed-plugin #sharrreTitle {
        text-align: center;
        padding: 10px 0;
        height: 50px; }
      .perfil.content .fixed-plugin li.header-title {
        height: 30px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase; }
      .perfil.content .fixed-plugin .adjustments-line p {
        float: left;
        display: inline-block;
        margin-bottom: 0;
        font-size: 1em;
        color: #3c4858; }
      .perfil.content .fixed-plugin .adjustments-line a {
        color: transparent; }
        .perfil.content .fixed-plugin .adjustments-line a .badge-colors {
          position: relative;
          top: -2px; }
        .perfil.content .fixed-plugin .adjustments-line a a:focus, .perfil.content .fixed-plugin .adjustments-line a a:hover {
          color: transparent; }
      .perfil.content .fixed-plugin .adjustments-line .togglebutton {
        text-align: center; }
        .perfil.content .fixed-plugin .adjustments-line .togglebutton .label-switch {
          position: relative;
          left: -10px;
          font-size: .7142em;
          color: #888; }
          .perfil.content .fixed-plugin .adjustments-line .togglebutton .label-switch.label-right {
            left: 10px; }
        .perfil.content .fixed-plugin .adjustments-line .togglebutton .toggle {
          margin-right: 0; }
      .perfil.content .fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
        padding-right: 0;
        padding-left: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        margin: 0; }
      .perfil.content .fixed-plugin .dropdown-menu > li > a.img-holder {
        font-size: 16px;
        text-align: center;
        border-radius: 10px;
        background-color: #fff;
        border: 3px solid #fff;
        padding-left: 0;
        padding-right: 0;
        opacity: 1;
        cursor: pointer;
        display: block;
        max-height: 100px;
        overflow: hidden;
        padding: 0; }
        .perfil.content .fixed-plugin .dropdown-menu > li > a.img-holder img {
          margin-top: auto; }
      .perfil.content .fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
        background-color: transparent; }
      .perfil.content .fixed-plugin .dropdown-menu > li a.switch-trigger:hover {
        background-color: transparent; }
      .perfil.content .fixed-plugin .dropdown-menu > li:focus > a.img-holder, .perfil.content .fixed-plugin .dropdown-menu > li:hover > a.img-holder {
        border-color: rgba(0, 187, 255, 0.53); }
      .perfil.content .fixed-plugin .dropdown-menu > .active > a.img-holder {
        border-color: #0bf;
        background-color: #fff; }
      .perfil.content .fixed-plugin .btn-social {
        width: 50%;
        display: block;
        width: 48%;
        float: left;
        font-weight: 600; }
        .perfil.content .fixed-plugin .btn-social i {
          margin-right: 5px; }
        .perfil.content .fixed-plugin .btn-social:first-child {
          margin-right: 2%; }
      .perfil.content .fixed-plugin .dropdown .dropdown-menu {
        transform: translateY(-15%);
        top: 27px;
        opacity: 0;
        transform-origin: 0 0; }
        .perfil.content .fixed-plugin .dropdown .dropdown-menu:before {
          border-bottom: .4em solid transparent;
          border-left: 0.4em solid rgba(0, 0, 0, 0.2);
          border-top: .4em solid transparent;
          right: -16px;
          top: 46px; }
        .perfil.content .fixed-plugin .dropdown .dropdown-menu:after {
          border-bottom: .4em solid transparent;
          border-left: .4em solid #fff;
          border-top: .4em solid transparent;
          right: -16px;
          content: "";
          display: inline-block;
          position: absolute;
          top: 74px;
          width: 16px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%); }
        .perfil.content .fixed-plugin .dropdown .dropdown-menu:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 74px;
          width: 16px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%); }
      .perfil.content .fixed-plugin .dropdown.show .dropdown-menu {
        opacity: 1;
        transform: translateY(-13%);
        transform-origin: 0 0; }
      .perfil.content .fixed-plugin .bootstrap-switch {
        margin: 0; }
    .perfil.content .container-card {
      float: right; }
      .perfil.content .container-card .card {
        border: 0;
        border-radius: .1875rem;
        display: inline-block;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1); }
        .perfil.content .container-card .card .card-body {
          padding: 15px 15px 10px; }
          .perfil.content .container-card .card .card-body .botones {
            border-radius: 5px; }
          .perfil.content .container-card .card .card-body.table-full-width {
            padding-left: 0;
            padding-right: 0; }
        .perfil.content .container-card .card .card-header {
          padding: 15px 15px 0;
          border: 0; }
          .perfil.content .container-card .card .card-header:not([data-background-color]) {
            background-color: transparent; }
          .perfil.content .container-card .card .card-header .card-title {
            margin-top: 10px; }
        .perfil.content .container-card .card .map {
          border-radius: .1875rem; }
          .perfil.content .container-card .card .map.map-big {
            height: 400px; }
        .perfil.content .container-card .card[data-background-color=orange] {
          background-color: #337ab7; }
          .perfil.content .container-card .card[data-background-color=orange] .card-header {
            background-color: #337ab7; }
          .perfil.content .container-card .card[data-background-color=orange] .card-footer .stats {
            color: #fff; }
        .perfil.content .container-card .card[data-background-color=red] {
          background-color: #ff3636; }
        .perfil.content .container-card .card[data-background-color=yellow] {
          background-color: #ffb236; }
        .perfil.content .container-card .card[data-background-color=blue] {
          background-color: #2ca8ff; }
        .perfil.content .container-card .card[data-background-color=green] {
          background-color: #18ce0f; }
        .perfil.content .container-card .card .image {
          overflow: hidden;
          height: 200px;
          position: relative; }
        .perfil.content .container-card .card .avatar {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
          margin-bottom: 15px; }
        .perfil.content .container-card .card label {
          font-size: .8571em;
          margin-bottom: 5px;
          color: #9a9a9a; }
        .perfil.content .container-card .card .card-footer {
          background-color: transparent;
          border: 0; }
          .perfil.content .container-card .card .card-footer .stats i {
            margin-right: 5px;
            position: relative;
            top: 2px; }
          .perfil.content .container-card .card .card-footer .btn {
            margin: 0; }
    .perfil.content .card-plain {
      background: transparent;
      box-shadow: none; }
      .perfil.content .card-plain .card-footer, .perfil.content .card-plain .card-header {
        margin-left: 0;
        margin-right: 0;
        background-color: transparent; }
      .perfil.content .card-plain:not(.card-subcategories).card-body {
        padding-left: 0;
        padding-right: 0; }
    .perfil.content .card-chart .card-header .card-title {
      margin-top: 10px;
      margin-bottom: 0; }
    .perfil.content .card-chart .card-header .card-category {
      margin-bottom: 5px; }
    .perfil.content .card-chart .table {
      margin-bottom: 0; }
      .perfil.content .card-chart .table td {
        border-top: none;
        border-bottom: 1px solid #e9ecef; }
    .perfil.content .card-chart .card-progress {
      margin-top: 30px; }
    .perfil.content .card-chart .chart-area {
      height: 190px;
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px; }
    .perfil.content .card-chart .card-footer {
      margin-top: 15px; }
      .perfil.content .card-chart .card-footer .stats {
        color: #9a9a9a; }
    .perfil.content .card-chart .dropdown {
      position: absolute;
      right: 20px;
      top: 20px; }
      .perfil.content .card-chart .dropdown .btn {
        margin: 0; }
    .perfil.content .card-user .image {
      height: 120px; }
    .perfil.content .card-user .author {
      text-align: center;
      text-transform: none;
      margin-top: -77px; }
      .perfil.content .card-user .author a + p.description {
        margin-top: -7px; }
    .perfil.content .card-user .avatar {
      width: 124px;
      height: 124px;
      border: 1px solid #fff;
      position: relative; }
    .perfil.content .card-user .card-body {
      min-height: 240px; }
    .perfil.content .card-user hr {
      margin: 5px 15px; }
    .perfil.content .card-user .button-container {
      margin-bottom: 6px;
      text-align: center; }
    .perfil.content .map {
      height: 500px; }
    @media screen and (max-width: 991px) {
      .perfil.content .profile-photo .profile-photo-small {
        margin-left: -2px; }
      .perfil.content #minimizeSidebar, .perfil.content .button-dropdown {
        display: none; }
      .perfil.content .navbar .container-fluid {
        padding-right: 15px;
        padding-left: 15px; }
      .perfil.content .navbar .navbar-collapse .input-group {
        margin: 0;
        margin-top: 5px; }
      .perfil.content .navbar .navbar-nav .nav-item:first-child {
        margin-top: 10px; }
      .perfil.content .navbar .navbar-nav .nav-item:not(:last-child) {
        margin-bottom: 10px; }
      .perfil.content .navbar .dropdown.show .dropdown-menu {
        display: block; }
      .perfil.content .navbar .dropdown .dropdown-menu {
        display: none;
        background-color: transparent;
        border: 0;
        transition: none;
        box-shadow: none;
        width: auto;
        margin: 0 1rem;
        margin-top: 0; }
      .perfil.content .navbar .dropdown.show .dropdown-menu {
        background-color: transparent;
        border: 0;
        transition: none;
        box-shadow: none;
        width: auto;
        margin: 0 1rem;
        margin-top: 0; }
      .perfil.content .navbar .dropdown .dropdown-menu:before, .perfil.content .navbar .dropdown.show .dropdown-menu:before {
        display: none; }
      .perfil.content .navbar .dropdown-menu .dropdown-item:focus, .perfil.content .navbar .dropdown-menu .dropdown-item:hover {
        color: #fff; }
      .perfil.content .navbar.bg-white .dropdown-menu .dropdown-item:focus, .perfil.content .navbar.bg-white .dropdown-menu .dropdown-item:hover {
        color: #888; }
      .perfil.content .navbar .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #fff; }
        .perfil.content .navbar .navbar-toggler-bar + .navbar-toggler-bar {
          margin-top: 7px; }
          .perfil.content .navbar .navbar-toggler-bar + .navbar-toggler-bar.navbar-kebab {
            margin-top: 3px; }
        .perfil.content .navbar .navbar-toggler-bar.bar2 {
          width: 17px;
          transition: width .2s linear; }
      .perfil.content .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
        background-color: #888; }
      .perfil.content .navbar .toggled .navbar-toggler-bar {
        width: 24px; }
        .perfil.content .navbar .toggled .navbar-toggler-bar + .navbar-toggler-bar {
          margin-top: 5px; }
      .perfil.content .wrapper {
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); }
      .perfil.content .nav-open .main-panel {
        right: 0;
        transform: translate3d(260px, 0, 0); }
      .perfil.content .nav-open .sidebar {
        transform: translateZ(0); }
      .perfil.content .nav-open body {
        position: relative;
        overflow-x: hidden; }
      .perfil.content .nav-open .menu-on-right .main-panel {
        transform: translate3d(-260px, 0, 0); }
      .perfil.content .nav-open .menu-on-right .navbar-collapse, .perfil.content .nav-open .menu-on-right .sidebar {
        transform: translateZ(0); }
      .perfil.content .nav-open .menu-on-right .navbar-translate {
        transform: translate3d(-300px, 0, 0); }
      .perfil.content .nav-open .menu-on-right #bodyClick {
        right: 260px;
        left: auto; }
      .perfil.content .menu-on-right .sidebar {
        left: auto;
        right: 0;
        transform: translate3d(260px, 0, 0); }
      .perfil.content .bar1, .perfil.content .bar2, .perfil.content .bar3 {
        outline: 1px solid transparent; }
      .perfil.content .bar1 {
        top: 0;
        animation: h .5s 0s;
        animation-fill-mode: forwards; }
      .perfil.content .bar2 {
        opacity: 1; }
      .perfil.content .bar3 {
        bottom: 0;
        animation: j .5s 0s;
        animation-fill-mode: forwards; }
      .perfil.content .toggled .bar1 {
        top: 6px;
        animation: g .5s 0s;
        animation-fill-mode: forwards; }
      .perfil.content .toggled .bar2 {
        opacity: 0; }
      .perfil.content .toggled .bar3 {
        bottom: 6px;
        animation: i .5s 0s;
        animation-fill-mode: forwards; }
      @keyframes g {
        0% {
          top: 0;
          transform: rotate(0deg); }
        45% {
          top: 6px;
          transform: rotate(145deg); }
        75% {
          transform: rotate(130deg); }
        to {
          transform: rotate(135deg); } }
      @keyframes h {
        0% {
          top: 6px;
          transform: rotate(135deg); }
        45% {
          transform: rotate(-10deg); }
        75% {
          transform: rotate(5deg); }
        to {
          top: 0;
          transform: rotate(0); } }
      @keyframes i {
        0% {
          bottom: 0;
          transform: rotate(0deg); }
        45% {
          bottom: 6px;
          transform: rotate(-145deg); }
        75% {
          transform: rotate(-130deg); }
        to {
          transform: rotate(-135deg); } }
      @keyframes j {
        0% {
          bottom: 6px;
          transform: rotate(-135deg); }
        45% {
          transform: rotate(10deg); }
        75% {
          transform: rotate(-5deg); }
        to {
          bottom: 0;
          transform: rotate(0); } }
      .perfil.content #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        right: 0;
        left: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); }
      .perfil.content .footer .copyright {
        text-align: right; }
      .perfil.content .section-nucleo-icons .icons-container {
        margin-top: 65px; }
      .perfil.content .navbar-nav .nav-link i.fa, .perfil.content .navbar-nav .nav-link i.now-ui-icons {
        opacity: .5; }
      .perfil.content .bootstrap-navbar, .perfil.content .sidebar {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 260px;
        right: auto;
        left: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transform: translate3d(-260px, 0, 0); }
      .perfil.content .main-panel {
        width: 100%; } }
    @media screen and (min-width: 992px) {
      .perfil.content .navbar-collapse {
        background: none !important; }
      .perfil.content .navbar .navbar-toggle {
        display: none; }
      .perfil.content .navbar-nav .nav-link.profile-photo {
        padding: 0;
        margin: 7px .7rem; }
      .perfil.content .section-nucleo-icons .icons-container {
        margin: 0 0 0 auto; }
      .perfil.content .dropdown-menu .dropdown-item {
        color: inherit; }
      .perfil.content .footer .copyright {
        float: right;
        padding-right: 15px; } }
    @media screen and (max-width: 768px) {
      .perfil.content .nav-tabs {
        display: inline-block;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        text-align: center; }
        .perfil.content .nav-tabs .nav-item > .nav-link {
          margin-bottom: 5px; }
      .perfil.content .card-stats [class*=col-] .statistics:after {
        display: none; }
      .perfil.content .main-panel .content {
        padding-left: 15px;
        padding-right: 15px;
        padding-right: 15px; }
      .perfil.content .footer nav {
        display: block;
        margin-bottom: 5px;
        float: none; }
      .perfil.content .landing-page .section-story-overview .image-container:nth-child(2) {
        margin-left: 0;
        margin-bottom: 30px; } }
    @media screen and (max-width: 576px) {
      .perfil.content .navbar[class*=navbar-toggleable-] .container {
        margin-left: 0;
        margin-right: 0; }
      .perfil.content .card-contributions .card-stats {
        flex-direction: column; }
        .perfil.content .card-contributions .card-stats .bootstrap-switch {
          margin-bottom: 15px; }
      .perfil.content .footer .copyright {
        text-align: center; }
      .perfil.content .section-nucleo-icons .icons-container i {
        font-size: 30px; }
        .perfil.content .section-nucleo-icons .icons-container i:nth-child(6) {
          font-size: 48px; }
      .perfil.content .page-header .container h6.category-absolute {
        width: 90%; } }
  .perfil .modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */ }
  .perfil .modal-content {
    background-color: #fefefe;
    height: 300px;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */ }
  .perfil .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold; }
  .perfil .close:hover,
  .perfil .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer; }
  .perfil .titulos {
    color: black;
    font-weight: 500 !important;
    text-align: left;
    margin-left: 3rem; }

.resultado row {
  height: 250px !important;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: visible;
  display: block; }

.resultado .repeater-container {
  height: 100%;
  width: 100%;
  margin-right: 10px;
  margin-left: -10px; }
  .resultado .repeater-container::-webkit-scrollbar {
    width: 10px; }
  .resultado .repeater-container::-webkit-scrollbar-thumb {
    background: #2a5788;
    border-radius: 20px; }
  .resultado .repeater-container::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px; }

.resultado .row {
  align-items: stretch; }
  .resultado .row .col-xs-12 .col-xs-11 .title {
    font-weight: 400; }
    .resultado .row .col-xs-12 .col-xs-11 .title .title-indicador {
      font-style: oblique;
      font-weight: 300;
      font-size: 22px; }
    .resultado .row .col-xs-12 .col-xs-11 .title .fas.fa-circle {
      vertical-align: 2px !important;
      font-size: 7px !important; }
  .resultado .row .col-xs-12 .col-xs-11 .estiloTextoIndicador {
    display: inline-block;
    font: normal 100 175% normal; }
  .resultado .row .col-xs-12 .col-xs-1.container-btn {
    text-align: right; }
    .resultado .row .col-xs-12 .col-xs-1.container-btn .btn {
      color: black;
      background: transparent;
      border-color: transparent;
      margin-top: 16px; }
    .resultado .row .col-xs-12 .col-xs-1.container-btn .btn:hover {
      color: black;
      background: transparent;
      border-color: transparent; }
    .resultado .row .col-xs-12 .col-xs-1.container-btn .btn:active {
      color: black;
      background: transparent;
      border-color: transparent;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important; }
    .resultado .row .col-xs-12 .col-xs-1.container-btn .btn:focus {
      outline: none; }

.resultado .marcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 10px; }
  .resultado .marcontainer infobox {
    padding-left: 0;
    padding-right: 0; }
    .resultado .marcontainer infobox .claseTextoColumna {
      color: #009688 !important;
      margin-right: 15px; }
    .resultado .marcontainer infobox .flip-card-back {
      border-right-color: #009688 !important; }
    .resultado .marcontainer infobox .info-box {
      border-left-color: #009688 !important; }
    .resultado .marcontainer infobox .progress-bar-blue {
      background: #009688 !important; }
    .resultado .marcontainer infobox .arrow-front, .resultado .marcontainer infobox .arrow-back {
      color: #009688 !important; }
  .resultado .marcontainer .texto-sin-resultado {
    margin-top: 5%;
    margin-bottom: 3%;
    width: 100%; }
    .resultado .marcontainer .texto-sin-resultado h4 {
      font-weight: 300; }
  .resultado .marcontainer .separator-result {
    padding-bottom: 20px !important;
    border-bottom: 1px solid #e6e6e6; }
    .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento {
      text-align: left;
      margin-left: 24px;
      margin-right: 4%;
      margin-top: -20px; }
      .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso {
        padding: 7px;
        width: 41px;
        font-size: 2rem;
        background: whitesmoke;
        color: #fff;
        text-align: left;
        margin-right: 1rem;
        border-radius: 3px;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19); }
        .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .chart-line-up, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .chart-line-down, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .chart-line-up, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .chart-line-down, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .chart-line-up, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .chart-line-down {
          height: 28px;
          width: 26px; }
        .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .imprecise-icon, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .imprecise-icon, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .imprecise-icon {
          height: 28px;
          width: 26px; }
        .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico {
          position: absolute;
          background: #333;
          color: white;
          border-radius: 9px;
          font-size: 14px;
          padding: 7px;
          top: 39px;
          left: 22px;
          z-index: 9;
          opacity: 0;
          transition: opacity 0.5s;
          pointer-events: none; }
          .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico .tooltip-arrow, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico .tooltip-arrow, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico .tooltip-arrow {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            top: -16px;
            left: 14px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #333;
            border-left: 10px solid transparent; }
          .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico .tooltip-inner, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico .tooltip-inner, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico .tooltip-inner {
            background-color: #333; }
        .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-sube:hover .tooltip-grafico, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-baja:hover .tooltip-grafico, .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso:hover .tooltip-grafico {
          opacity: 1; }
      .resultado .marcontainer .separator-result .principal-box .grafico-rendimiento .container-grafico-impreciso {
        padding: 9px 9px 9px 7px; }
    .resultado .marcontainer .separator-result .principal-box .wrapper-content .info-content {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 20px; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .info-content .claseTextoColumnaTotal {
        top: 60% !important;
        color: #00796b;
        font-size: 12px;
        font-weight: 100;
        position: relative;
        text-align: right; }
    .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content {
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 3rem;
      text-align: center; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content .number {
        max-width: 8rem;
        float: left;
        font-size: 4rem; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content i {
        margin-top: 2.5rem; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content .graficos {
        margin-top: 5rem;
        margin-right: 5px; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content .container-progress .progress {
        margin-bottom: 1rem !important; }
        .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content .container-progress .progress .bg-worse {
          background-color: gray; }
      .resultado .marcontainer .separator-result .principal-box .wrapper-content .number-content .container-progress .porcentaje {
        margin-bottom: 1rem;
        font-size: 17px !important; }

.resultado .estiloTextoIndicador {
  display: inline-block;
  font: normal 100 175% normal; }

.resultado .filter {
  height: auto;
  background: #ffffff !important;
  margin-top: 1rem !important;
  margin-bottom: 0; }

.resultado .container-filters-resultado {
  background: white;
  border: 0 solid #e3e3e3 !important;
  height: auto;
  overflow: auto;
  width: auto; }
  .resultado .container-filters-resultado.filter {
    border-radius: 0 !important; }
    .resultado .container-filters-resultado.filter .container-chip {
      margin-bottom: 1rem;
      float: left;
      width: auto; }
      .resultado .container-filters-resultado.filter .container-chip .col-xs-12 {
        padding-left: 0;
        text-align: center; }
        .resultado .container-filters-resultado.filter .container-chip .col-xs-12 h6 {
          font-weight: 100;
          margin-bottom: 0px !important;
          padding: 0 16px;
          height: 33px;
          font-size: 13px;
          line-height: 36px;
          border-radius: 25px;
          background-color: #f1f1f1; }
      .resultado .container-filters-resultado.filter .container-chip .chip {
        background-color: #f1f1f1;
        border-radius: 25px;
        display: inline-block;
        font-size: 13px;
        height: 33px;
        line-height: 34px;
        margin-right: 1rem;
        margin-top: 10px;
        padding: 0 16px; }
        .resultado .container-filters-resultado.filter .container-chip .chip span:focus {
          display: none !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip i:focus {
          display: none !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip.clicOn i {
          transition-duration: 0.8s;
          transition-property: transform;
          transform: rotate(180deg) !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip.clicOff i {
          transition-duration: 0.8s;
          transition-property: transform;
          transform: rotate(0deg); }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }
      .resultado .container-filters-resultado.filter .container-chip .chip-fecha {
        background-color: #f1f1f1;
        border-radius: 25px;
        font-size: 13px;
        height: 33px;
        line-height: 34px;
        margin-right: 1rem;
        margin-top: 10px;
        padding: 0 16px; }
        .resultado .container-filters-resultado.filter .container-chip .chip-fecha span:focus {
          display: none !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip-fecha i:focus {
          display: none !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip-fecha.clicOn i {
          transition-duration: 0.8s;
          transition-property: transform;
          transform: rotate(180deg) !important; }
        .resultado .container-filters-resultado.filter .container-chip .chip-fecha.clicOff i {
          transition-duration: 0.8s;
          transition-property: transform;
          transform: rotate(0deg); }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }
      .resultado .container-filters-resultado.filter .container-chip .closebtn {
        padding-left: 10px;
        color: #888;
        font-weight: bold;
        float: right;
        font-size: 20px;
        cursor: pointer; }
      .resultado .container-filters-resultado.filter .container-chip .closebtn:hover {
        color: #000; }

.resultado .container-buttons {
  text-align: center; }
  .resultado .container-buttons .btn {
    background: #4cae4c;
    color: #f5f5f5;
    margin-top: 1rem; }
    .resultado .container-buttons .btn.button-disabled {
      opacity: 1;
      cursor: not-allowed; }
    .resultado .container-buttons .btn.button-green {
      background: #00796b; }

.resultado .slider {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */
  background-color: #0db9f0;
  height: 300px;
  top: 0;
  right: 0;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.resultado .sliderClose {
  top: -10;
  max-height: 0; }

.resultado #info-boxs.container-fluid {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem; }
  .resultado #info-boxs.container-fluid .flip-card {
    background-color: transparent;
    perspective: 1000px;
    /* Remove this if you don't want the 3D effect */ }
  .resultado #info-boxs.container-fluid .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform linear 0.8s;
    transform-style: preserve-3d; }
  .resultado #info-boxs.container-fluid .flip-card-rotate {
    transform: rotateY(180deg); }
    .resultado #info-boxs.container-fluid .flip-card-rotate .flip-card-front, .resultado #info-boxs.container-fluid .flip-card-rotate .grafico-rendimiento {
      opacity: 0;
      pointer-events: none;
      transition: opacity linear 0s 0.4s; }
    .resultado #info-boxs.container-fluid .flip-card-rotate .flip-card-back {
      opacity: 1;
      pointer-events: all;
      transition: opacity linear 0s 0.4s; }
  .resultado #info-boxs.container-fluid .flip-card-front, .resultado #info-boxs.container-fluid .grafico-rendimiento {
    transition: opacity linear 0s 0.4s;
    opacity: 1;
    pointer-events: all; }
  .resultado #info-boxs.container-fluid .flip-card-back {
    transition: opacity linear 0s 0.4s;
    opacity: 0;
    pointer-events: none; }
  .resultado #info-boxs.container-fluid .flip-card-front, .resultado #info-boxs.container-fluid .flip-card-back {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    max-height: 18rem; }
    .resultado #info-boxs.container-fluid .flip-card-front .toolbar-btn, .resultado #info-boxs.container-fluid .flip-card-back .toolbar-btn {
      color: #337ab7;
      font-size: 15px;
      margin-right: 40px;
      outline: none;
      position: absolute;
      top: 9px;
      right: 0px; }
    .resultado #info-boxs.container-fluid .flip-card-front .arrow-front, .resultado #info-boxs.container-fluid .flip-card-back .arrow-front {
      color: #2a5788;
      font-size: 18px;
      margin-right: 12px;
      outline: none;
      position: absolute;
      top: 7px;
      right: 0px; }
    .resultado #info-boxs.container-fluid .flip-card-front .arrow-back, .resultado #info-boxs.container-fluid .flip-card-back .arrow-back {
      color: #2a5788;
      font-size: 18px;
      margin-right: 12px;
      outline: none;
      position: absolute;
      right: 0px; }
  .resultado #info-boxs.container-fluid .flip-card-front {
    color: black; }
  .resultado #info-boxs.container-fluid .flip-card-back {
    background-color: white;
    color: #1714ff;
    font-size: 20px;
    font-weight: 300;
    transform: rotateY(180deg);
    top: 0px;
    border: 1px solid transparent;
    border-radius: 12px;
    box-shadow: 0px 0px 12px -2px #949494;
    border-right-color: #2a5788 !important;
    border-right-width: 8px !important; }
    .resultado #info-boxs.container-fluid .flip-card-back .info-content {
      margin-top: 25px;
      margin-bottom: 10px;
      margin-right: 20px;
      text-align: right; }
      .resultado #info-boxs.container-fluid .flip-card-back .info-content .claseTextoColumna {
        top: 60% !important;
        color: #2a5788;
        font-size: 12px;
        font-weight: 100;
        position: relative;
        text-align: right; }
      .resultado #info-boxs.container-fluid .flip-card-back .info-content .scroller {
        overflow-y: auto;
        max-height: 9rem;
        margin-right: -2px; }
      .resultado #info-boxs.container-fluid .flip-card-back .info-content .scroller::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .resultado #info-boxs.container-fluid .flip-card-back .info-content .scroller::-webkit-scrollbar {
        width: 4px;
        background-color: #F5F5F5; }
      .resultado #info-boxs.container-fluid .flip-card-back .info-content .scroller::-webkit-scrollbar-thumb {
        background-color: #cecae6;
        border: 1px solid #a7b3ff; }
  .resultado #info-boxs.container-fluid .info-box {
    border: 1px solid transparent;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 1rem;
    height: auto;
    overflow: visible;
    display: flow-root;
    transition: all;
    position: relative;
    border-left-color: #2a5788;
    border-left-width: 8px !important;
    border-radius: 12px;
    box-shadow: 0px 0px 12px -2px #949494; }
    .resultado #info-boxs.container-fluid .info-box:hover {
      -webkit-box-shadow: 0px 0px 9px 1px #949494;
      -moz-box-shadow: 0px 0px 9px 1px #949494;
      box-shadow: 0px 0px 9px 1px #949494; }
    .resultado #info-boxs.container-fluid .info-box .ca-style {
      height: 100% !important;
      width: 100% !important; }
    .resultado #info-boxs.container-fluid .info-box .vertext {
      font-size: 3.5rem;
      margin: 0 0 0 10rem; }
    .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento {
      text-align: left;
      margin-left: 24px;
      margin-right: 4%;
      margin-top: -20px; }
      .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso {
        padding: 7px;
        width: 41px;
        font-size: 2rem;
        background: whitesmoke;
        color: #fff;
        text-align: left;
        margin-right: 1rem;
        border-radius: 3px;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19); }
        .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .chart-line-up, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .chart-line-down, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .chart-line-up, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .chart-line-down, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .chart-line-up, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .chart-line-down {
          height: 28px;
          width: 26px; }
        .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .imprecise-icon, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .imprecise-icon, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .imprecise-icon {
          height: 28px;
          width: 26px; }
        .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico {
          position: absolute;
          background: #333;
          color: white;
          border-radius: 9px;
          font-size: 14px;
          padding: 7px;
          top: 39px;
          left: 22px;
          z-index: 9;
          opacity: 0;
          transition: opacity 0.5s;
          pointer-events: none; }
          .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico .tooltip-arrow, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico .tooltip-arrow, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico .tooltip-arrow {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            top: -16px;
            left: 14px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #333;
            border-left: 10px solid transparent; }
          .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube .tooltip-grafico .tooltip-inner, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja .tooltip-grafico .tooltip-inner, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso .tooltip-grafico .tooltip-inner {
            background-color: #333; }
        .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-sube:hover .tooltip-grafico, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-baja:hover .tooltip-grafico, .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso:hover .tooltip-grafico {
          opacity: 1; }
      .resultado #info-boxs.container-fluid .info-box .grafico-rendimiento .container-grafico-impreciso {
        padding: 9px 9px 9px 7px; }
    .resultado #info-boxs.container-fluid .info-box .wrapper-content .info-content {
      margin-right: 15px;
      margin-bottom: 15px;
      margin-top: 10px;
      text-align: right; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .info-content .claseTextoColumna {
        top: 60% !important;
        color: #2a5788;
        font-size: 12px;
        font-weight: 100; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .info-content .claseTextoColumna2 {
        top: 60% !important;
        color: #2a5788;
        font-size: 12px;
        font-weight: 100;
        position: relative;
        text-align: right;
        max-height: 20px;
        overflow: hidden;
        word-break: break-word; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .info-content:hover .claseTextoColumna2 {
        background: rgba(255, 255, 255, 0.95);
        overflow: visible !important; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .info-content .dropdown-menu {
        font-size: 13px;
        left: initial;
        right: 0; }
    .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content {
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 3rem;
      text-align: center; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .number {
        max-width: 8rem;
        float: left;
        font-size: 4rem; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content i {
        margin-top: 2.5rem; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .graficos {
        margin-top: 5rem;
        margin-right: 5px; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .container-progress .progress {
        margin-bottom: 1rem !important; }
        .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .container-progress .progress .bg-worse {
          background-color: gray; }
        .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .container-progress .progress .progress-bar-blue {
          background: #2a5788; }
      .resultado #info-boxs.container-fluid .info-box .wrapper-content .number-content .container-progress .porcentaje {
        margin-bottom: 1rem;
        font-size: 16px !important; }

.resultado .progress-bar-important {
  background-color: #009688; }

.resultado #info-box-total.container-fluid .info-box {
  border: 1px solid transparent !important;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 3rem;
  height: auto;
  overflow: visible;
  display: flow-root;
  transition: all;
  position: relative;
  border-left-color: #009688 !important;
  border-left-width: 8px !important;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -2px #949494; }
  .resultado #info-box-total.container-fluid .info-box:hover {
    -webkit-box-shadow: 0px 0px 9px 1px #949494;
    -moz-box-shadow: 0px 0px 9px 1px #949494;
    box-shadow: 0px 0px 9px 1px #949494; }
  .resultado #info-box-total.container-fluid .info-box .ca-style {
    height: 100% !important;
    width: 100% !important; }
  .resultado #info-box-total.container-fluid .info-box .vertext {
    font-size: 3.5rem;
    margin: 0 0 0 10rem; }
  .resultado #info-box-total.container-fluid .info-box .wrapper-content .info-content {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 20px;
    text-align: right; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .info-content .claseTextoColumna {
      top: 60% !important;
      color: #009688;
      font-size: 12px;
      font-weight: 100;
      position: relative;
      text-align: right; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .info-content .dropdown-menu {
      font-size: 13px;
      left: initial;
      right: 0; }
  .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content {
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 3rem;
    text-align: center; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .number {
      max-width: 8rem;
      float: left;
      font-size: 4rem; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content i {
      margin-top: 2.5rem; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .graficos {
      margin-top: 5rem;
      margin-right: 5px; }
    .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .container-progress {
      position: relative;
      height: auto; }
      .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .container-progress .progress {
        margin-bottom: 1rem !important; }
        .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .container-progress .progress .bg-worse {
          background-color: gray; }
      .resultado #info-box-total.container-fluid .info-box .wrapper-content .number-content .container-progress .porcentaje {
        margin-bottom: 1rem;
        font-size: 17px !important; }

.sidebar.close-sidebar {
  width: 84px; }
  .sidebar.close-sidebar .nav-side-menu {
    width: 84px; }
    .sidebar.close-sidebar .nav-side-menu .profile .buttons a img {
      margin-left: 5px; }
    .sidebar.close-sidebar .nav-side-menu .profile .buttons .log-out {
      color: white;
      display: block;
      position: absolute;
      right: 19px;
      top: 141px; }
  .sidebar.close-sidebar .menu-list.menu-content .nombre-proyecto {
    display: none; }
  .sidebar.close-sidebar .menu-list.menu-content.collapse.out li {
    display: none; }
  .sidebar.close-sidebar .container-buttons .button-mostrar-resultados {
    display: none; }

.sidebar .nav-side-menu {
  overflow: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 200;
  background-color: #2e353d;
  position: fixed;
  top: 0px;
  overflow-x: hidden;
  transition: 0.5s;
  width: 290px;
  color: white;
  z-index: 3; }
  .sidebar .nav-side-menu .container-perfil {
    background: #0087bd;
    height: auto;
    width: 290px;
    float: left;
    display: inline-block; }
    .sidebar .nav-side-menu .container-perfil .collapse-button {
      width: 60px;
      height: 84px;
      background: #0a43ab;
      line-height: 84px;
      text-align: center;
      float: right;
      cursor: pointer; }
      .sidebar .nav-side-menu .container-perfil .collapse-button i {
        vertical-align: middle; }
      .sidebar .nav-side-menu .container-perfil .collapse-button:hover {
        color: white;
        background: #0087bd; }
    .sidebar .nav-side-menu .container-perfil .container-opciones-perfil {
      margin-top: 7px;
      padding-left: 12px;
      float: left;
      width: 100%;
      animation-delay: 500ms; }
      .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto {
        margin: 19px 0 0 0;
        padding: 0 0 20px 0; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto a:hover {
          color: #4ab9f2; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto a.change-project {
          color: white;
          display: block;
          text-align: right; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto a.change-project .icon-change-project {
            font-size: 22px;
            margin: 11px 12px 0 0;
            position: absolute;
            left: 200px;
            bottom: 7px; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto a.change-project .icon-change-project.icon-change-project-sidebar-close {
              position: absolute; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto a i:focus {
          outline: none; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto .container-icon .image-icon {
          height: 40px;
          width: 35px;
          margin: 6px 0 0 0; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto .container-icon .image-icon.image-icon-sidebar-close {
            position: absolute; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto .container-nombre-proyecto {
          color: white;
          text-align: left;
          font-size: 23px;
          margin-top: 10px; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto .container-nombre-proyecto .nombre-proyecto {
            font-family: monospace;
            margin-left: 11px;
            cursor: default;
            display: inline-block;
            max-width: 99%;
            height: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto.containers-sidebar-close:after {
          display: none; }
      .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-proyecto:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: rgba(255, 255, 255, 0.5); }
      .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario {
        margin: 19px 0 0 0;
        padding: 0 0 20px 0; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .switch {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 17px; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .switch input {
            opacity: 0;
            width: 0;
            height: 0; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 10px;
          right: -10px;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .slider:before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            top: 2px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario input:checked + .slider {
          background-color: #009688; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px); }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario input:focus + .slider {
          box-shadow: 0 0 1px #00a597; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .slider.round {
          border-radius: 34px; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .slider.round:before {
            border-radius: 50%; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-multiple {
          color: white;
          text-align: left;
          font-weight: 100;
          margin: 1rem; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-multiple.multiple-sidebar-close .multiple {
            display: none; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-multiple.multiple-sidebar-close .switch {
            margin-top: 60px;
            margin-left: -18px; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-multiple .multiple {
            margin-top: 6px;
            font-family: sans-serif;
            font-size: 13px;
            text-transform: uppercase; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario {
          color: white;
          text-align: left;
          font-weight: 100;
          font-size: 17px;
          margin: 1rem; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .nombre-usuario {
            font-family: sans-serif;
            font-size: 14px;
            text-transform: uppercase; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .glyph-arrow {
            margin-left: 11px;
            font-size: 14px;
            position: absolute;
            top: 4px;
            right: 0px; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .glyph-arrow.accordion {
              cursor: pointer;
              border: none;
              outline: none;
              transition: 0.4s; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .glyph-arrow .active, .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .glyph-arrow .accordion:hover {
              background-color: #ccc; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .panel {
            background-color: #0087bd;
            margin-bottom: 0 !important;
            font-family: sans-serif;
            font-size: 13px;
            text-transform: uppercase;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .panel .opciones.opciones-open {
              margin-top: 13px; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .panel .opciones li {
              padding: 1px 1px 1px 7px;
              margin: 0px 0 12px 0px; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .panel .opciones li:hover {
              background: #1597cc;
              border-radius: 50px; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .container-nombre-usuario .panel .opciones li:focus {
              outline: none; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario a:hover {
          color: #4ab9f2; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario a.log-out {
          color: white;
          margin-top: 8px;
          text-align: right; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario a.log-out .icon-log-out {
            margin: 0px 12px 0 0; }
            .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario a.log-out .icon-log-out.log-out-sidebar-close {
              position: absolute; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario a i:focus {
          outline: none; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .avatar {
          margin-top: 5px; }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .avatar.avatar-sidebar-close {
            margin-top: 5rem; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .avatar img {
          border-radius: 50%;
          overflow: hidden;
          width: 35px;
          height: 35px;
          vertical-align: middle;
          object-fit: cover;
          box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3); }
          .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .avatar img.avatar-img-sidebar-close {
            position: absolute; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario .avatar img:focus {
          outline: none; }
        .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario.containers-sidebar-close:after {
          display: none; }
      .sidebar .nav-side-menu .container-perfil .container-opciones-perfil .container-usuario:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: rgba(255, 255, 255, 0.5); }
  .sidebar .nav-side-menu .menu-list #menu-content.menu-content {
    background: #0087bd;
    padding-top: 12px; }
    .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li {
      background: #0087bd;
      border-bottom: 0px solid #ccc;
      border-left: 0px solid #2e353d;
      border-radius: 50px;
      line-height: 28px !important;
      font-weight: 400 !important; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.regreso-dashboard {
        margin-top: 2px;
        margin-left: 13px;
        margin-right: 13px;
        padding-left: 20px; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed {
        margin: 7px 13px;
        padding: 14px 20px;
        position: relative;
        transition: all .3s ease 0s; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed.negocio-option {
          padding-left: 20px !important; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed.segmentacion-option {
          padding-left: 20px !important; }
          .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed.segmentacion-option li {
            margin-left: 5rem; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed .ps-arrow-down {
          position: absolute;
          top: 20px;
          right: 25px; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed .ps-arrow-up {
          position: absolute;
          top: 25px;
          right: 25px; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li.collapsed:hover {
        background: #1597cc; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li span {
        margin-left: 14px; }
    .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out li:focus {
      outline: none; }
    .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .col-xs-2.sin-espacios li:focus {
      outline: none; }
    .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu {
      padding: 2px 9px 9px 9px;
      font-size: 14px; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu li.first-li {
        padding-left: 20px !important; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu li:focus {
        outline: none; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-canales li {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 7px;
        padding: 10px 20px !important;
        font-size: 11px; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-canales li span {
          text-transform: uppercase; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-canales li:hover {
        background: #1597cc;
        text-decoration: none;
        transition: all 0.3s; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-indicadores li {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 7px;
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 28px !important;
        padding-left: 1rem !important;
        background: #0087bd;
        font-size: 11px; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-indicadores li span {
          text-transform: uppercase; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-indicadores li:hover {
        background: #1597cc;
        text-decoration: none;
        transition: all 0.3s; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-indicadores .arrowMenu {
        margin-left: 5px; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-filtros li {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 7px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 3rem !important;
        background: #0087bd;
        font-size: 11px; }
        .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-filtros li span {
          text-transform: uppercase; }
      .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu .container-submenu-filtros li:hover {
        background: #1597cc;
        text-decoration: none;
        transition: all 0.3s; }
    .sidebar .nav-side-menu .menu-list #menu-content.menu-content.collapse.out .sub-menu:hover {
      max-height: auto; }
  .sidebar .nav-side-menu .menu-list #menu-content.menu-content-sidebar-close {
    box-shadow: inset 0 2px 20px 0 transparent !important;
    height: 87.6vh; }
  .sidebar .nav-side-menu ul, .sidebar .nav-side-menu li {
    border-bottom: 0px solid #ccc;
    list-style: none;
    padding: 1px;
    margin: 0px;
    line-height: 35px;
    cursor: pointer; }
    .sidebar .nav-side-menu ul .collapsed, .sidebar .nav-side-menu li .collapsed {
      font-size: 13px;
      padding: 9px; }
      .sidebar .nav-side-menu ul .collapsed.button-active, .sidebar .nav-side-menu li .collapsed.button-active {
        background: white !important; }
        .sidebar .nav-side-menu ul .collapsed.button-active .fas.fa-cube, .sidebar .nav-side-menu li .collapsed.button-active .fas.fa-cube {
          color: #0087bd; }
        .sidebar .nav-side-menu ul .collapsed.button-active span, .sidebar .nav-side-menu li .collapsed.button-active span {
          color: #0087bd; }
      .sidebar .nav-side-menu ul .collapsed.button-disabled, .sidebar .nav-side-menu li .collapsed.button-disabled {
        opacity: 1;
        cursor: not-allowed; }
      .sidebar .nav-side-menu ul .collapsed.negocio-option, .sidebar .nav-side-menu ul .collapsed.segmentacion-option, .sidebar .nav-side-menu li .collapsed.negocio-option, .sidebar .nav-side-menu li .collapsed.segmentacion-option {
        text-transform: uppercase; }
        .sidebar .nav-side-menu ul .collapsed.negocio-option.button-active, .sidebar .nav-side-menu ul .collapsed.segmentacion-option.button-active, .sidebar .nav-side-menu li .collapsed.negocio-option.button-active, .sidebar .nav-side-menu li .collapsed.segmentacion-option.button-active {
          background: #1597cc !important; }
          .sidebar .nav-side-menu ul .collapsed.negocio-option.button-active span, .sidebar .nav-side-menu ul .collapsed.segmentacion-option.button-active span, .sidebar .nav-side-menu li .collapsed.negocio-option.button-active span, .sidebar .nav-side-menu li .collapsed.segmentacion-option.button-active span {
            color: white !important; }
    .sidebar .nav-side-menu ul.collapsed:hover, .sidebar .nav-side-menu li.collapsed:hover {
      color: white !important; }

@media (min-width: 767px) {
  .sidebar .nav-side-menu .menu-list .menu-content {
    background-color: #e5e5e5;
    display: block;
    overflow-y: scroll;
    height: 83.6vh;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .sidebar .nav-side-menu .menu-list .menu-content::-webkit-scrollbar {
      width: 3px; }
    .sidebar .nav-side-menu .menu-list .menu-content::-webkit-scrollbar-track {
      background: none; }
    .sidebar .nav-side-menu .menu-list .menu-content::-webkit-scrollbar-thumb {
      background: none; }
    .sidebar .nav-side-menu .menu-list .menu-content::-webkit-scrollbar-thumb:hover {
      background: #555; } }

.tabla .row .col-xs-12 .col-xs-11 .col-xs-7.title {
  font-weight: 400; }

.tabla .row .col-xs-12 .col-xs-11 .form-inline {
  margin-top: 1.5rem;
  text-align: right; }

.tabla .row .col-xs-12 .col-xs-1.container-btn {
  text-align: right; }
  .tabla .row .col-xs-12 .col-xs-1.container-btn .btn {
    color: black;
    background: transparent;
    border-color: transparent;
    margin-top: 16px; }
  .tabla .row .col-xs-12 .col-xs-1.container-btn .btn:hover {
    color: black;
    background: transparent;
    border-color: transparent; }
  .tabla .row .col-xs-12 .col-xs-1.container-btn .btn:active {
    color: black;
    background: transparent;
    border-color: transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important; }
  .tabla .row .col-xs-12 .col-xs-1.container-btn .btn:focus {
    outline: none; }

.tabla .row .cuerpoTabla.row {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.tabla .row .cuerpoTabla.mostrar {
  max-height: 999px; }

.tabla .row .cuerpoTabla .table-responsive {
  background: white;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border: none;
  text-align: center; }
  .tabla .row .cuerpoTabla .table-responsive .contenedor {
    position: relative;
    margin-bottom: 1rem; }
    .tabla .row .cuerpoTabla .table-responsive .contenedor .button-export {
      background: #2a5788;
      color: white;
      margin-bottom: 1rem; }
      .tabla .row .cuerpoTabla .table-responsive .contenedor .button-export i {
        padding-left: 3px;
        padding-right: 3px;
        margin-top: -5px; }
    .tabla .row .cuerpoTabla .table-responsive .contenedor .button-export-load {
      background: #398aa8;
      color: white;
      margin-bottom: 1rem;
      pointer-events: none; }
      .tabla .row .cuerpoTabla .table-responsive .contenedor .button-export-load i {
        color: white;
        horiz-align: center;
        vertical-align: middle;
        -webkit-animation: glyphicon-spin-r 2s infinite linear;
        animation: glyphicon-spin-r 2s infinite linear;
        padding-left: 3px;
        padding-right: 3px;
        margin-top: -5px; }
  .tabla .row .cuerpoTabla .table-responsive .button-blue {
    background: #2a5788;
    color: white; }
  .tabla .row .cuerpoTabla .table-responsive .listado th {
    background: #fcfcfc;
    border: none !important;
    font-size: 13px;
    color: #7c7c7c;
    line-height: 90px !important;
    padding: 0 10px 0 0 !important; }
    .tabla .row .cuerpoTabla .table-responsive .listado th h5 {
      font-weight: 400; }
  .tabla .row .cuerpoTabla .table-responsive .listado td {
    background: #fff;
    border-top: none !important;
    font-size: 13px;
    color: #8d9e98;
    font-weight: 300;
    line-height: 80px !important;
    padding: 0 10px 0 0 !important;
    border-bottom: solid 1px #f0f1f1;
    transition: all 0.1s ease-in-out; }
  .tabla .row .cuerpoTabla .table-responsive .listado a {
    color: #111;
    font-size: 13px;
    font-weight: 300;
    transition: all 0.1s ease-in-out; }
  .tabla .row .cuerpoTabla .table-responsive .listado tr:hover td {
    background: #3cba9f;
    color: #fff; }
    .tabla .row .cuerpoTabla .table-responsive .listado tr:hover td a {
      color: #fff; }
  .tabla .row .cuerpoTabla .table-responsive .table-historial {
    border-radius: 4px;
    border: 1px solid #e3e3e3 !important; }
    .tabla .row .cuerpoTabla .table-responsive .table-historial thead {
      background-color: #5d8be6;
      color: white;
      -webkit-border-radius: 0 6px 0 0;
      -moz-border-radius: 0 6px 0 0;
      border-radius: 0 6px 0 0; }
      .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr {
        -webkit-border-radius: 6px 6px 0 0;
        -moz-border-radius: 6px 6px 0 0;
        border-radius: 6px 6px 0 0;
        font-size: 16px; }
        .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th {
          padding: 1.5rem 1rem;
          border-left: 2px solid white;
          text-align: center; }
          .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th:first-child {
            padding: 0 10px 0 10px !important;
            -webkit-border-radius: 6px 0 0 0;
            -moz-border-radius: 6px 0 0 0;
            border-radius: 6px 0 0 0;
            border-left: 0; }
          .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th:last-child {
            -webkit-border-radius: 0 6px 0 0;
            -moz-border-radius: 0 6px 0 0;
            border-radius: 0 6px 0 0;
            padding: 0 10px 0 0 !important; }
          .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-button {
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
            text-align: center; }
            .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-button .boton-mover {
              background: transparent; }
            .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-button .boton-mover:focus {
              outline: none; }
            .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-button .boton-mover:active {
              box-shadow: none !important; }
          .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-titulo-tabla {
            display: inline-block;
            margin-left: 1rem;
            margin-right: 1rem;
            text-align: center; }
            .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .container-header-tabla .container-titulo-tabla .titulo-tabla {
              text-align: center;
              text-transform: uppercase; }
          .tabla .row .cuerpoTabla .table-responsive .table-historial thead tr th .titulo-tabla {
            text-align: center;
            text-transform: uppercase; }
    .tabla .row .cuerpoTabla .table-responsive .table-historial tbody td {
      text-align: center;
      vertical-align: middle; }

.tabla .row .cuerpoTabla .container-btn {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px; }

.tabla .row .cuerpoTabla .width-fix {
  width: 40px; }

.tabla .row .cuerpoTabla .panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: none !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.tabla .row .cuerpoTabla .well {
  padding-top: 0 !important;
  margin-top: 20px !important; }

.tabla .row .cuerpoTabla .ilupa {
  display: block;
  margin-top: 1rem; }

.panel {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05) !important; }

.tarjetas .panel .tarjetas {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5) !important; }

.tarjetas .modalOpcions {
  left: -71px !important;
  right: 18px !important;
  top: 50px !important; }
  .tarjetas .modalOpcions li {
    background: white; }

.tarjetas .iconoOpcions {
  position: absolute;
  top: 16px;
  right: 0;
  padding-left: 9px;
  padding-right: 9px;
  background: white;
  border-radius: 2rem !important; }

.tarjetas #info-boxs {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .tarjetas #info-boxs.row {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .tarjetas #info-boxs.row .col-sm-4 .info-box {
      -webkit-box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
      box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
      border: 0px solid #e3e3e3 !important;
      height: auto;
      position: relative;
      color: white; }
      .tarjetas #info-boxs.row .col-sm-4 .info-box:hover {
        -webkit-box-shadow: 0px 0px 9px 1px #949494;
        -moz-box-shadow: 0px 0px 9px 1px #949494;
        box-shadow: 0px 0px 9px 1px #949494; }
      .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta {
        color: #4ab9f2;
        border-color: white;
        background: white; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta a {
          background: white;
          color: #4ab9f2; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta i {
          color: #4ab9f2 !important; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content {
          padding: 0;
          margin: 1rem; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .card-category {
            margin-bottom: 5px;
            text-transform: capitalize;
            font-weight: 400;
            color: #9a9a9a;
            padding-top: 2rem;
            padding-left: 2rem; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content h3 {
            color: #444242;
            font-weight: 300;
            padding-left: 2rem; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title.dropdown {
            position: relative;
            display: inline-block;
            float: right;
            top: -10rem; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header {
            margin-bottom: 3rem;
            padding-top: 15px;
            padding-right: 8rem;
            border: 0; }
            .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header .btn.btn-primary {
              border-color: #979797; }
              .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header .btn.btn-primary .fas.fa-cog:before {
                color: #979797; }
            .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header .btn.btn-primary:focus {
              background: transparent !important; }
            .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header .btn.btn-primary:hover {
              background: transparent !important; }
            .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .container-title .card-header .btn.btn-primary:active {
              outline: none !important; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .wrapper-content .dropdown-menu li a {
            color: black; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta p {
          color: #777777;
          font-size: 17px;
          margin-top: 4rem;
          text-align: center; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .graficos {
          margin-right: 5px; }
        .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .card-footer {
          margin-top: 15px;
          padding: 0 15px 15px 15px; }
          .tarjetas #info-boxs.row .col-sm-4 .info-box.card-tarjeta .card-footer .stats {
            color: #9a9a9a; }

.modal-tarjetas {
  height: auto;
  padding: 1.5rem 2.5rem; }
  .modal-tarjetas .titulo-cadena {
    color: black;
    margin-bottom: 2rem;
    font-weight: 400;
    text-transform: uppercase; }
  .modal-tarjetas .container-menu .well.container-cadena {
    background: white; }
    .modal-tarjetas .container-menu .well.container-cadena.disabled-div {
      pointer-events: none;
      opacity: 0.4; }
  .modal-tarjetas .container-menu .well .titulo-submenu-cadena {
    margin-top: 0.5rem !important;
    margin-bottom: 3rem;
    font-size: 20px; }
  .modal-tarjetas .container-menu .well .cadena-lista {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    font-weight: normal !important;
    width: 92%;
    height: auto !important; }
    .modal-tarjetas .container-menu .well .cadena-lista hr {
      height: 4px; }
    .modal-tarjetas .container-menu .well .cadena-lista .custom-control {
      width: 220px !important; }
      .modal-tarjetas .container-menu .well .cadena-lista .custom-control label {
        font-weight: 400 !important; }
    .modal-tarjetas .container-menu .well .cadena-lista .container-button {
      text-align: right; }
      .modal-tarjetas .container-menu .well .cadena-lista .container-button button.btn.disabled-button {
        pointer-events: none;
        opacity: 0.4; }
  .modal-tarjetas .listado thead th {
    background: #2a5788;
    border: none !important;
    font-size: 13px;
    color: #ffffff;
    line-height: 40px !important;
    padding: 0 10px 0 0 !important; }
    .modal-tarjetas .listado thead th h5 {
      font-weight: 400; }
  .modal-tarjetas .listado thead td {
    background: #2a5788;
    border-top: none !important;
    font-size: 13px;
    color: #ffffff;
    font-weight: 300;
    line-height: 30px !important;
    padding: 0 10px 0 0 !important;
    border-bottom: solid 1px #f0f1f1;
    transition: all 0.1s ease-in-out; }
  .modal-tarjetas .listado thead a {
    color: #111;
    font-size: 13px;
    font-weight: 300;
    transition: all 0.1s ease-in-out; }
  .modal-tarjetas .listado tbody th {
    background: #fcfcfc;
    border: none !important;
    font-size: 13px;
    color: #7c7c7c;
    line-height: 40px !important;
    padding: 0 10px 0 0 !important; }
    .modal-tarjetas .listado tbody th h5 {
      font-weight: 400; }
  .modal-tarjetas .listado tbody td {
    background: #fff;
    border-top: none !important;
    font-size: 13px;
    color: #8d9e98;
    font-weight: 300;
    line-height: 30px !important;
    padding: 0 10px 0 0 !important;
    border-bottom: solid 1px #f0f1f1;
    transition: all 0.1s ease-in-out; }
  .modal-tarjetas .listado tbody a {
    color: #111;
    font-size: 13px;
    font-weight: 300;
    transition: all 0.1s ease-in-out; }
  .modal-tarjetas .listado tbody tr:hover td {
    background: #3cba9f;
    color: #fff; }
    .modal-tarjetas .listado tbody tr:hover td a {
      color: #fff; }
  .modal-tarjetas .table-historial {
    border-radius: 4px;
    margin-top: 1rem;
    border: 1px solid #e3e3e3 !important; }
    .modal-tarjetas .table-historial thead {
      background-color: #5d8be6;
      color: white;
      -webkit-border-radius: 0 6px 0 0;
      -moz-border-radius: 0 6px 0 0;
      border-radius: 0 6px 0 0; }
      .modal-tarjetas .table-historial thead tr {
        -webkit-border-radius: 6px 6px 0 0;
        -moz-border-radius: 6px 6px 0 0;
        border-radius: 6px 6px 0 0;
        font-size: 16px; }
        .modal-tarjetas .table-historial thead tr th {
          padding: 1.5rem 1rem;
          border-left: 2px solid white;
          text-align: center; }
          .modal-tarjetas .table-historial thead tr th:first-child {
            padding: 0 10px 0 10px !important;
            -webkit-border-radius: 6px 0 0 0;
            -moz-border-radius: 6px 0 0 0;
            border-radius: 6px 0 0 0;
            border-left: 0; }
          .modal-tarjetas .table-historial thead tr th:last-child {
            -webkit-border-radius: 0 6px 0 0;
            -moz-border-radius: 0 6px 0 0;
            border-radius: 0 6px 0 0;
            padding: 0 10px 0 0 !important; }
          .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-button {
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
            text-align: center; }
            .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-button .boton-mover {
              background: transparent; }
            .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-button .boton-mover:focus {
              outline: none; }
            .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-button .boton-mover:active {
              box-shadow: none !important; }
          .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-titulo-tabla {
            display: inline-block;
            margin-left: 1rem;
            margin-right: 1rem;
            text-align: center; }
            .modal-tarjetas .table-historial thead tr th .container-header-tabla .container-titulo-tabla .titulo-tabla {
              text-align: center;
              text-transform: uppercase; }
          .modal-tarjetas .table-historial thead tr th .titulo-tabla {
            text-align: center;
            text-transform: uppercase; }
    .modal-tarjetas .table-historial tbody td {
      text-align: center;
      vertical-align: middle; }

.tendencias .cargandoIcon {
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: -2rem !important; }

.tendencias .row .col-xs-12 .col-xs-11 .col-xs-5.title {
  font-weight: 400; }

.tendencias .row .col-xs-12 .col-xs-1.container-btn {
  text-align: right; }
  .tendencias .row .col-xs-12 .col-xs-1.container-btn .btn {
    color: black;
    background: transparent;
    border-color: transparent;
    margin-top: 16px; }
  .tendencias .row .col-xs-12 .col-xs-1.container-btn .btn:hover {
    color: black;
    background: transparent;
    border-color: transparent; }
  .tendencias .row .col-xs-12 .col-xs-1.container-btn .btn:active {
    color: black;
    background: transparent;
    border-color: transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important; }
  .tendencias .row .col-xs-12 .col-xs-1.container-btn .btn:focus {
    outline: none; }

.tendencias .row .chart-wrapper.mostrar {
  max-height: 999px; }

.tendencias .row .chart-wrapper .container-buttons {
  margin: 2rem; }
  .tendencias .row .chart-wrapper .container-buttons .row .button-tendencias {
    float: left;
    margin-left: 2rem;
    opacity: .6;
    pointer-events: initial;
    outline: none; }
    .tendencias .row .chart-wrapper .container-buttons .row .button-tendencias strong {
      font-size: 16px; }
    .tendencias .row .chart-wrapper .container-buttons .row .button-tendencias.button-tendencias-active {
      opacity: 1;
      pointer-events: none; }

.modal-content {
  background-color: #4c6fb5; }

.proyecto {
  height: auto;
  padding: 1.5rem 2.5rem;
  background-color: white !important; }
  .proyecto .container-menu .container-proyecto {
    padding-bottom: 20px;
    margin: 1rem; }
    .proyecto .container-menu .container-proyecto .titulo-proyecto {
      font-size: 18px;
      font-weight: 400;
      margin-top: 3.5rem;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 2rem; }
    .proyecto .container-menu .container-proyecto .sub-titulo-proyecto {
      font-weight: 100;
      text-align: left;
      margin-top: 6px; }
  .proyecto .container-menu .select-style {
    margin-bottom: 7rem; }
  .proyecto .container-menu .container-button {
    margin-top: -3rem;
    text-align: right; }
    .proyecto .container-menu .container-button button.btn.disabled-button {
      pointer-events: none;
      opacity: 0.4; }

.evidencias .cargandoIcon {
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: -2rem !important; }

.evidencias .row .header-container {
  margin-bottom: 22px; }
  .evidencias .row .header-container .table-responsive {
    background: white;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border: none;
    text-align: center; }
    .evidencias .row .header-container .table-responsive .button-export {
      background: #2a5788;
      color: white;
      margin-bottom: 1rem; }
      .evidencias .row .header-container .table-responsive .button-export i {
        padding-left: 3px;
        padding-right: 3px;
        margin-top: -5px; }
    .evidencias .row .header-container .table-responsive .button-blue {
      background: #2a5788;
      color: white; }
  .evidencias .row .header-container .col-xs-11 .col-xs-5.title {
    font-weight: 400; }
  .evidencias .row .header-container .col-xs-1.container-btn {
    text-align: right; }
    .evidencias .row .header-container .col-xs-1.container-btn .btn {
      color: black;
      background: transparent;
      border-color: transparent;
      margin-top: 16px; }
    .evidencias .row .header-container .col-xs-1.container-btn .btn:hover {
      color: black;
      background: transparent;
      border-color: transparent; }
    .evidencias .row .header-container .col-xs-1.container-btn .btn:active {
      color: black;
      background: transparent;
      border-color: transparent;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important; }
    .evidencias .row .header-container .col-xs-1.container-btn .btn:focus {
      outline: none; }

.evidencias .row .container-carousel .contenedor {
  background: white;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border: none;
  text-align: center; }
  .evidencias .row .container-carousel .contenedor .button-export {
    background: #2a5788;
    color: white;
    margin-bottom: 1rem; }
    .evidencias .row .container-carousel .contenedor .button-export i {
      padding-left: 3px;
      padding-right: 3px;
      margin-top: -5px; }
  .evidencias .row .container-carousel .contenedor .button-export-load {
    background: #398aa8;
    color: white;
    margin-bottom: 1rem;
    pointer-events: none; }
    .evidencias .row .container-carousel .contenedor .button-export-load i {
      color: white;
      horiz-align: center;
      vertical-align: middle;
      -webkit-animation: glyphicon-spin-r 2s infinite linear;
      animation: glyphicon-spin-r 2s infinite linear;
      padding-left: 3px;
      padding-right: 3px;
      margin-top: -5px; }

.evidencias .row .container-carousel .home-product-new-hldr {
  position: relative;
  width: 1420px;
  margin: auto; }

.evidencias .row .container-carousel .home-product-new {
  background-color: #f4f4f4;
  margin: 0 20px;
  height: 550px;
  width: 1392px;
  overflow: -webkit-paged-x;
  overflow-x: hidden; }

.evidencias .row .container-carousel .home-grid.products-grid.products-grid--max-4 {
  transition: -ms-transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s, transform 0.5s ease 0s;
  position: relative;
  white-space: nowrap;
  padding: 42px 0 45px 0px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

.evidencias .row .container-carousel .item-container {
  display: inline-block;
  vertical-align: top;
  width: 464px; }
  .evidencias .row .container-carousel .item-container .item {
    border: 5px solid white;
    margin-top: 50px;
    margin-left: 119.5px; }
    .evidencias .row .container-carousel .item-container .item .image-carousel {
      height: 300px;
      max-width: 225px; }
    .evidencias .row .container-carousel .item-container .item .text-box {
      font-size: 11px;
      padding: 10px;
      text-transform: uppercase;
      width: 225px;
      overflow: scroll;
      overflow-y: hidden;
      overflow-x: auto; }
    .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar {
      height: 4px;
      background-color: #F5F5F5; }
    .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5; }
    .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar-thumb {
      background-color: #cecae6;
      border: 1px solid #a7b3ff; }
    .evidencias .row .container-carousel .item-container .item.active {
      border: 5px solid white;
      margin-top: 0;
      margin-left: 69.5px;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -moz-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s; }
      .evidencias .row .container-carousel .item-container .item.active img {
        height: 400px;
        width: 325px;
        max-width: 325px;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s; }
      .evidencias .row .container-carousel .item-container .item.active .text-box {
        font-size: 12px;
        width: 325px;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s; }

.evidencias .row .container-carousel .slider-btn-hldr-left {
  left: -32px; }

.evidencias .row .container-carousel .slider-btn-hldr-right {
  right: -32px; }

.evidencias .row .container-carousel .slider-btn-hldr {
  bottom: 0;
  display: block;
  padding: 0;
  width: 40px;
  position: absolute;
  top: 50%;
  z-index: 1; }

.evidencias .row .container-carousel .slider-btn {
  background-color: transparent;
  border: 0 none;
  color: buttontext;
  cursor: pointer;
  display: block;
  font-size: 40px; }

.evidencias .row .container-carousel .slider-btn:focus {
  outline: none; }

.evidencias .row .container-carousel #left-btn {
  display: none; }

.evidencias .row .container-carousel .products-grid .item {
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  float: left; }

@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@media (max-width: 1450px) {
  .evidencias .row .container-carousel .contenedor {
    background: white;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border: none;
    text-align: center; }
    .evidencias .row .container-carousel .contenedor .button-export {
      background: #2a5788;
      color: white;
      margin-bottom: 1rem; }
      .evidencias .row .container-carousel .contenedor .button-export i {
        padding-left: 3px;
        padding-right: 3px;
        margin-top: -5px; }
    .evidencias .row .container-carousel .contenedor .button-export-load {
      background: #398aa8;
      color: white;
      margin-bottom: 1rem;
      pointer-events: none; }
      .evidencias .row .container-carousel .contenedor .button-export-load i {
        color: white;
        horiz-align: center;
        vertical-align: middle;
        -webkit-animation: glyphicon-spin-r 2s infinite linear;
        animation: glyphicon-spin-r 2s infinite linear;
        padding-left: 3px;
        padding-right: 3px;
        margin-top: -5px; }
  .evidencias .row .container-carousel .home-product-new-hldr {
    width: 947px; }
  .evidencias .row .container-carousel .home-product-new {
    width: 900px; }
  .evidencias .row .container-carousel .home-grid.products-grid.products-grid--max-4 {
    padding: 45px 0; }
  .evidencias .row .container-carousel .item-container {
    width: 300px; }
    .evidencias .row .container-carousel .item-container .item {
      border: 5px solid white;
      margin-top: 50px;
      margin-left: 50px; }
      .evidencias .row .container-carousel .item-container .item .image-carousel {
        max-width: 200px; }
      .evidencias .row .container-carousel .item-container .item .text-box {
        font-size: 10px;
        padding: 10px;
        width: 200px; }
      .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar {
        height: 4px;
        background-color: #F5F5F5; }
      .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .evidencias .row .container-carousel .item-container .item .text-box::-webkit-scrollbar-thumb {
        background-color: #cecae6;
        border: 1px solid #a7b3ff; }
      .evidencias .row .container-carousel .item-container .item.active {
        border: 5px solid white;
        margin-top: 0;
        margin-left: 25px; }
        .evidencias .row .container-carousel .item-container .item.active img {
          width: 250px;
          max-width: 250px; }
        .evidencias .row .container-carousel .item-container .item.active .text-box {
          font-size: 11px;
          width: 250px; }
  .evidencias .row .container-carousel .slider-btn-hldr-left {
    left: -17px; }
  .evidencias .row .container-carousel .slider-btn-hldr-right {
    right: -10px; }
  .evidencias .row .container-carousel .slider-btn-hldr {
    width: 40px; }
  .evidencias .row .container-carousel .slider-btn {
    font-size: 28px; }
  .evidencias .row .container-carousel .slider-btn:focus {
    outline: none; }
  .evidencias .row .container-carousel #left-btn {
    display: none; } }

.main-evidencias {
  width: 100%;
  background: #333;
  color: white;
  position: fixed;
  height: 100%; }
  .main-evidencias .gallery-content {
    height: 100%;
    width: 100%; }
    .main-evidencias .gallery-content .gallery-content .evi-header, .main-evidencias .gallery-content .gallery-content .evi-gallery {
      opacity: 1; }
  .main-evidencias .toolbar {
    padding-right: 150px;
    padding-left: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 30px;
    height: 0px;
    overflow: visible;
    z-index: 10; }
    .main-evidencias .toolbar .evi-tooltip-container {
      position: relative;
      display: inline-block; }
      .main-evidencias .toolbar .evi-tooltip-container .evi-tooltip-inner {
        position: absolute;
        opacity: 0;
        top: 50px;
        transition: 0.5s;
        right: -15px;
        white-space: nowrap;
        background: #008bdb;
        color: white;
        padding: 0px 8px;
        border-radius: 100px;
        font-size: 16px; }
    .main-evidencias .toolbar .evi-canales {
      max-width: 0;
      opacity: 0;
      transition: 0.8s ease-out;
      position: relative; }
      .main-evidencias .toolbar .evi-canales.active {
        max-width: 100%;
        opacity: 1; }
      .main-evidencias .toolbar .evi-canales .tip {
        display: block;
        position: absolute;
        top: 10px;
        left: 5px;
        width: 1px;
        height: 1px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 10px solid #008bdb; }
      .main-evidencias .toolbar .evi-canales .inner {
        margin-left: 15px;
        overflow: hidden;
        border-radius: 10px; }
        .main-evidencias .toolbar .evi-canales .inner .option {
          background: #008bdb;
          border-bottom: 1px solid white;
          font-size: 14px;
          letter-spacing: 3px;
          padding: 6px 8px; }
          .main-evidencias .toolbar .evi-canales .inner .option:last-child {
            border-bottom: 0; }
          .main-evidencias .toolbar .evi-canales .inner .option.active {
            background: white !important;
            color: #008bdb; }
          .main-evidencias .toolbar .evi-canales .inner .option:hover {
            background: #00bbff; }
    .main-evidencias .toolbar .toolbar-btn {
      margin-left: 25px; }
      .main-evidencias .toolbar .toolbar-btn:hover + .evi-tooltip-container .evi-tooltip-inner {
        opacity: 1;
        top: 40px; }
  .main-evidencias .gallery {
    opacity: 1;
    transition: 0.5s;
    height: 100%;
    padding-right: 370px;
    margin-right: 0;
    position: relative; }
    .main-evidencias .gallery.gal-hidden {
      opacity: 0.1; }
    .main-evidencias .gallery.filtros-hidden {
      margin-right: -360px; }
    .main-evidencias .gallery evi-filtros {
      height: 100%;
      width: 350px;
      position: absolute;
      right: 70px;
      top: 0;
      background: #0087bd; }
  .main-evidencias.hidden {
    height: 0; }
  .main-evidencias evi-carousel {
    z-index: 999;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: 0.5s; }
    .main-evidencias evi-carousel.car-hidden {
      top: 100vh;
      opacity: 0; }
  .main-evidencias .shadow-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(#000, transparent); }
  .main-evidencias .shadow-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(transparent, #000);
    z-index: 98; }
  .main-evidencias .evi-header .title {
    padding-left: 100px;
    padding-top: 20px;
    width: 50%; }
  .main-evidencias .evi-header .filtros-title {
    padding-right: 150px;
    padding-top: 50px;
    display: inline-block;
    text-align: right; }
  .main-evidencias .evi-header evi-pagination {
    padding-left: 100px;
    padding-top: 3px; }
  .main-evidencias.loading .gallery-content .evi-header, .main-evidencias.loading .gallery-content .evi-gallery {
    opacity: 0.1; }
  .main-evidencias.loading .loading-spinner {
    font-size: 24px;
    position: absolute;
    margin-left: -180px;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent; }

.evi-carousel {
  height: 100%;
  z-index: 999; }
  .evi-carousel.hideHideables .hideable {
    opacity: 0; }
  .evi-carousel .hideable {
    opacity: 1;
    transition: opacity 0.5s; }
  .evi-carousel .car-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .evi-carousel .car-container .box {
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      padding: 10px; }
      .evi-carousel .car-container .box .moreInfo {
        max-height: 0px;
        overflow: hidden; }
        .evi-carousel .car-container .box .moreInfo span {
          display: block; }
  .evi-carousel .car-float {
    position: absolute;
    top: 50%;
    z-index: 99; }
    .evi-carousel .car-float.left {
      left: 100px; }
    .evi-carousel .car-float.right {
      right: 150px; }
    .evi-carousel .car-float.car-menu {
      top: 50px;
      right: 150px; }
      .evi-carousel .car-float.car-menu .car-btn {
        margin-left: 20px; }
  .evi-carousel .car-btn {
    font-size: 60px;
    transition: 0.5s; }
    .evi-carousel .car-btn.active {
      color: #aaa !important; }
    .evi-carousel .car-btn:focus {
      outline: none; }
    .evi-carousel .car-btn:hover {
      color: #ccc; }
  .evi-carousel .item {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    transition: 1s;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .evi-carousel .item img {
      height: 100%; }
    .evi-carousel .item.active {
      opacity: 1;
      z-index: 99; }
    .evi-carousel .item .card-container {
      height: 100%;
      position: relative; }
      .evi-carousel .item .card-container .card {
        height: 100%; }
        .evi-carousel .item .card-container .card .front {
          height: 100%; }
      .evi-carousel .item .card-container .data-container {
        height: 100%; }
      .evi-carousel .item .card-container .back, .evi-carousel .item .card-container .data-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; }
      .evi-carousel .item .card-container .back {
        background: #2b2b2b; }

.evi-gallery {
  padding-top: 140px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 25px;
  height: 100%; }
  .evi-gallery .gal-container {
    justify-content: center;
    margin-right: 35px;
    height: 100%;
    overflow-y: auto; }
    .evi-gallery .gal-container .item {
      perspective: 700px;
      position: relative;
      padding: 15px !important; }
      .evi-gallery .gal-container .item .nombre {
        height: 40px;
        overflow: hidden;
        display: block;
        width: 100%;
        word-break: break-all; }
        .evi-gallery .gal-container .item .nombre .inner {
          background: rgba(51, 51, 51, 0.95); }
      .evi-gallery .gal-container .item:hover {
        z-index: 2; }
        .evi-gallery .gal-container .item:hover .nombre {
          overflow: visible !important; }
      .evi-gallery .gal-container .item.active .item-container {
        margin-bottom: 15px;
        outline: 2px solid #0087bd; }
        .evi-gallery .gal-container .item.active .item-container:hover {
          transition: transform 0.5s; }
      .evi-gallery .gal-container .item .item-container {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.5s;
        position: relative;
        overflow: visible;
        transform: rotateX(0deg) translateZ(35px);
        transition: transform 0.5s;
        margin-bottom: 20px;
        margin-top: 20px; }
        .evi-gallery .gal-container .item .item-container:after {
          content: "";
          display: block;
          padding-bottom: 140%; }
        .evi-gallery .gal-container .item .item-container:hover {
          transform: rotateX(9deg) translateZ(35px);
          transition: transform 0.5s; }
        .evi-gallery .gal-container .item .item-container img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: rgba(0, 0, 0, 0.1); }
        .evi-gallery .gal-container .item .item-container .glow {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 99;
          background: linear-gradient(210deg, #fff, transparent 45%);
          background-repeat: no-repeat;
          background-position: 0px -100px;
          opacity: 0.8;
          transition: 0.5s; }
          .evi-gallery .gal-container .item .item-container .glow:hover {
            background-position: 0px 0px; }
      .evi-gallery .gal-container .item.active {
        opacity: 1; }
    .evi-gallery .gal-container::-webkit-scrollbar {
      width: 10px; }
    .evi-gallery .gal-container::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 20px; }
    .evi-gallery .gal-container::-webkit-scrollbar-track {
      background: #666;
      border-radius: 20px; }

.evi-filtros {
  padding-left: 20px;
  padding-right: 20px;
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .evi-filtros .loading {
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 139, 219, 0.9); }
  .evi-filtros .search-container .search {
    background: transparent;
    border: 2px solid white;
    border-radius: 100px;
    padding: 5px 10px;
    transition: 0.4s;
    width: 240px;
    display: inline-block; }
    .evi-filtros .search-container .search:focus {
      background: white !important;
      color: #008bdb;
      width: 240px !important; }
      .evi-filtros .search-container .search:focus + .buttons .button {
        color: #008bdb; }
    .evi-filtros .search-container .search::placeholder {
      color: white; }
    .evi-filtros .search-container .search:placeholder-shown {
      width: 130px; }
  .evi-filtros .search-container .button {
    display: inline-block;
    width: 0;
    position: relative;
    left: -30px;
    transition: all 0.4s, opacity 0.2s;
    color: white; }
    .evi-filtros .search-container .button.search-button {
      pointer-events: none;
      opacity: 1; }
    .evi-filtros .search-container .button.clear-button {
      pointer-events: none;
      opacity: 0; }
  .evi-filtros .search-container.empty .search-button {
    pointer-events: none;
    opacity: 0; }
  .evi-filtros .search-container.empty .clear-button {
    pointer-events: none;
    opacity: 1;
    pointer-events: all; }
  .evi-filtros .tituloPrincipal {
    font-size: 20px;
    font-weight: 500; }
  .evi-filtros .filtroPrincipal {
    margin-top: 7px; }
  .evi-filtros .filtros-title {
    margin-top: 6px; }
  .evi-filtros .filtro-container {
    border-left: 2px solid #1597cc;
    padding-left: 5px;
    margin-left: 6px; }
  .evi-filtros .filtros-disponibles-list {
    height: 100%;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 5px;
    margin-right: 8px; }
    .evi-filtros .filtros-disponibles-list::-webkit-scrollbar {
      width: 10px; }
    .evi-filtros .filtros-disponibles-list::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 20px; }
    .evi-filtros .filtros-disponibles-list::-webkit-scrollbar-track {
      background: #1597cc;
      border-radius: 20px; }
  .evi-filtros .filtro {
    padding: 4px 0px; }
  .evi-filtros .option {
    padding: 4px 6px;
    padding-right: 6px;
    border-radius: 12px;
    transition: 0.2s;
    display: inline-block;
    font-size: 12.7px; }
    .evi-filtros .option .btn-collapse {
      margin-left: 4px;
      margin-top: 3px;
      float: right;
      font-size: 14px; }
    .evi-filtros .option.active {
      background: white;
      color: #008dbd; }
      .evi-filtros .option.active:hover {
        background: rgba(255, 255, 255, 0.9); }
    .evi-filtros .option:hover {
      background: #1597cc; }
  .evi-filtros .sub-category {
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 15px; }
  .evi-filtros .btn-close {
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: 25px; }

.evi-pagination {
  padding-top: 15px; }
  .evi-pagination span {
    background: #008dbd;
    padding: 3px 4px;
    border-radius: 2px;
    margin: 0;
    margin-right: 2px; }
    .evi-pagination span.pag-prev {
      margin-right: -2px;
      padding: 3px 8px 3px 10px;
      border-radius: 20px 4px 4px 20px; }
    .evi-pagination span.pag-next {
      margin-left: -4px;
      padding: 3px 10px 3px 8px;
      border-radius: 4px 20px 20px 4px; }
    .evi-pagination span.active {
      background: #ddd;
      color: #008dbd; }

.tablero {
  position: relative; }
  .tablero .loading {
    color: #4ab9f2;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 30px;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: white; }
  .tablero .tablero-canal {
    background: white;
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    min-height: 325px;
    margin: 15px;
    box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1) !important;
    position: relative;
    margin-bottom: 25px; }
    .tablero .tablero-canal .top {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .tablero .tablero-canal .top .nombreCanal {
        margin-top: 10px; }
      .tablero .tablero-canal .top .filter-button {
        margin-left: auto;
        font-size: 18px;
        color: #aaa;
        padding: 5px;
        width: 35px;
        height: 35px;
        line-height: 28px;
        border-radius: 100%;
        border: 1px solid #aaa;
        text-align: center; }
      .tablero .tablero-canal .top .top-menu {
        display: flex; }
        .tablero .tablero-canal .top .top-menu .crumbMenu {
          margin-left: 12px; }
          .tablero .tablero-canal .top .top-menu .crumbMenu:hover {
            text-decoration-line: underline; }
    .tablero .tablero-canal .tablero-card {
      margin-bottom: 25px;
      padding: 0px 10px; }
      .tablero .tablero-canal .tablero-card.double .secundarias {
        margin-bottom: 7px; }
        .tablero .tablero-canal .tablero-card.double .secundarias h4, .tablero .tablero-canal .tablero-card.double .secundarias div {
          display: inline; }
      .tablero .tablero-canal .tablero-card .tablero-card-inner {
        min-height: 30rem;
        background: #0087bd;
        color: white;
        padding: 10px 20px;
        margin: 0px 10px 5px 0px;
        box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.15);
        border: 0px solid transparent;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: all .3s ease-in-out; }
        .tablero .tablero-canal .tablero-card .tablero-card-inner:hover {
          background: #00ccff;
          transform: scale(1.05);
          transition: all .2s ease-in-out; }
      .tablero .tablero-canal .tablero-card .title-menu {
        margin-top: 70%;
        display: grid;
        place-items: center;
        text-align: center; }
      .tablero .tablero-canal .tablero-card .sub-title-menu {
        margin-top: 70%;
        display: grid;
        place-items: center;
        text-align: center; }
      .tablero .tablero-canal .tablero-card .sub-title-evidencias {
        margin-top: 70%;
        display: grid;
        place-items: center; }
      .tablero .tablero-canal .tablero-card .title {
        margin: 30px 0px;
        line-height: normal;
        max-height: 85px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        font-size: 23px; }
        .tablero .tablero-canal .tablero-card .title:hover {
          max-height: 108px; }
      .tablero .tablero-canal .tablero-card .data {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center; }
        .tablero .tablero-canal .tablero-card .data .percentage-bar {
          flex-grow: 1;
          height: 20px;
          background: #0087bd;
          border: 1px solid white;
          border-radius: 4px;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
          overflow: hidden; }
          .tablero .tablero-canal .tablero-card .data .percentage-bar .percentage-bar-inner {
            height: 100%;
            background: white;
            border: 2px solid #0087bd;
            border-radius: 4px; }
        .tablero .tablero-canal .tablero-card .data .percentage-number {
          line-height: 20px;
          margin-right: 5px; }
      .tablero .tablero-canal .tablero-card .secundarias .secundaria-title {
        font-size: 14px;
        margin-bottom: 2px; }
        .tablero .tablero-canal .tablero-card .secundarias .secundaria-title .secundaria-data {
          font-size: 14px; }

.filtros-tablero {
  position: relative;
  padding: 20px; }
  .filtros-tablero .loading {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 30px;
    border-radius: 25px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #4ab9f2;
    background: white; }
  .filtros-tablero .filtro-category {
    display: inline; }
  .filtros-tablero .filtro-pill {
    background: #0087bd;
    color: white;
    display: inline-block;
    padding: 1px 8px;
    border-radius: 100px;
    margin: 2px 0px; }
  .filtros-tablero .filtros-list-categoria {
    padding: 6px 8px;
    background: #0087bd;
    color: white;
    display: inline-block;
    border-radius: 100px;
    margin: 2px 0px; }
  .filtros-tablero .aceptar {
    margin-top: 20px;
    width: 100%;
    text-align: right; }
  .filtros-tablero .title {
    margin-top: 14px; }
